import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
    root: {
        padding: '5px 20px 5px 20px',
        fontSize: '12px',
        textTransform: 'none',
        height: '40px',
    },
});
