import axios from '../utils/interceptor';
import { handleError } from '../utils/handle-error';
import { mockPromise } from '../utils/mock-promise';
import { policiesResponse } from '../mocks/policies-mock';
import { viewPolicyResponse } from '../mocks/view-policy-mock';
import { baseUrl } from '../constants/apiConstant';

export const getPoliciesListAPI = async (value: string[]): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const data1 = {
                Brokercode: value,
            };
            const response = await axios.post(`${baseUrl}/Policy/MyPolicy/Get/`, data1);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(policiesResponse);
    }
};

export const viewPolicyAPI = async (value: string): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/Policy/Get/${value}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(viewPolicyResponse);
    }
};
