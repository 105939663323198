export const claimsResponse: any = [
    {
        id: 'a1z0R000001mIshQAE',
        indemnity_Incurred__c: 0,
        vrnA__Claim_Number__c: 'C001EVOPNJ1092845',
        vrnA__Date_of_Loss__c: '2022-01-21',
        vrnA__Effective_Date__c: '2021-03-22',
        vrnA__Expiration_Date__c: '2021-12-23',
        vrnA__Paid__c: 0,
        vrnA__Description__c: 'Wire transfer fraud',
        vrnA__Policy_Number__c: '5CGFH',
        vrnA__Policy__c: 'a0W0R000005YOd4UAG',
        vrnA__Reserve__c: 0,
        vrnA__Status__c: 'Open',
        claimant_Name__c: 'Insured',
        vrnA__Date_Reported__c: '2022-01-28',
        claim_Adjuster__r: {
            email: 'paulfuqua@bellsouth.net.masked',
            id: '0036g00000poDZTAA2',
            inactive__c: false,
            name: 'Paul Fuqua',
        },
        vrnA__Account__r: {
            billingAddress: null,
            broker_Commission__c: 0,
            id: '0010R00001NDuKgQAL',
            industry__c: null,
            industry__r: null,
            name: 'Nevada CVS Inc',
            primary_Contact__c: '0036g00000poF6xAAE',
            primary_Contact__r: {
                email: 'shannon@evolvemga.com',
                inactive__c: false,
                name: 'Shannon Riggio-Kerley'
            },
            recordTypeId: null,
            retail_Broker__c: '0010R00001NDuG0QAL',
            retail_Broker__r: {name: 'Nevada Insurance Agency LLC', broker_Code__c: null},
            vrnA__MGA_Broker_Checkbox__c: false,
            website: null
        }
    }
];

export const lossRunsEmailResponse = {
    message: 'Email Sent Successfully'
};