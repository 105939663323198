export const errorMessages = (value?: string) => ({
    required:'Required',
    validEmail: 'Please enter valid email',
    submitError:'There is an error with one or more fields. Please review and update.',
    searchCriteriaError:'Search criteria too broad. Please provide Insured’s full business name to complete the search',
    specialCharacterValidation:'No special characters allowed',
    maxLimitSelect:`Only ${value} can be selected`,
    grossRevenue:'Gross Annual Revenue cannot be $0.00',
    validName:'Please use alphabet and numerical characters only.Special characters will not work.',
    maxLimit:'Maximum limit exceeded',
    validUrl:'Please enter a valid URL',
    validStreet:'Please use alpha numeric characters',
    validCity:'Please use alphabet characters',
    zipFormatError:'Invalid zip format',
    valueLength:`Length Should be ${value} or less`,
    wholeNumbersOnly:'Only whole numbers are allowed',
    validNumber:'Please provide a valid Number'
});

// eslint-disable-next-line @typescript-eslint/no-loss-of-precision
export const piiLimit = 999999999999999999;