import { ICreateQuoteRequest } from '../interface/quotes/createQuote';
import axios from '../utils/interceptor';
import { handleError } from '../utils/handle-error';
import { quotesResponse } from '../mocks/quotes-mock';
import { mockPromise } from '../utils/mock-promise';
import { ISearchInsured } from '../interface/quotes/searchInsured';
import { searchInsuredResponse } from '../mocks/create-quote-endpoint-mock';
import { brokerDetailsResponse } from '../mocks/broker-details-mock';
import { industryActivitiesResponse } from '../mocks/industry-activities-mock';
import { statesResponse } from '../mocks/states-mock';
import { viewQuoteResponse } from '../mocks/view-quote-mock';
import { IUpdateQuoteRequest } from '../interface/quotes/updateQuotes';
import { baseUrl } from '../constants/apiConstant';
import { documentList } from '../mocks/documents-mock';
import { IViewQuoteById } from '../interface/quotes/viewQuote';

(window as any).isMockData = false;

export const updateQuoteAPI = async (request: IUpdateQuoteRequest): Promise<any> => {
    try {
        const response = await axios.patch(`${baseUrl}/portal/Quote/update`, request);
        return response;
    } catch (error) {
        handleError(error);
    }
};

export const createQuoteAPI = async (request: ICreateQuoteRequest): Promise<any> => {
    try {
        const response = await axios.post(`${baseUrl}/portal/Quote/create`, request);
        return response;
    } catch (error) {
        handleError(error);
    }
};
export const getQuotesListAPI = async (value: string[]): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const data1 = {
                Brokercode: value,
            };
            const response = await axios.post(`${baseUrl}/Quote/MyQuotes/Get/`, data1);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(quotesResponse);
    }
};
export const getInsuredDetailsAPI = async (value: ISearchInsured): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/insured/get/${value.insuredName}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(searchInsuredResponse);
    }
};

export const getBrokerDetailsAPI = async (value: string): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/broker/Get/${value}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(brokerDetailsResponse);
    }
};
export const getIndustryActivitiesAPI = async (): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/industry/get`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(industryActivitiesResponse);
    }
};
export const getStatesAPI = async (): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/State/Get/US`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(statesResponse);
    }
};
export const viewQuoteAPI = async (value: IViewQuoteById): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/Quote/Get/${value.id}/${value.limit_type}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(viewQuoteResponse);
    }
};
export const getFilesAPI = async (value: string): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/QuoteFiles/get/${value}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(documentList);
    }
};
export const sendEmailAPI = async (request: any): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.post(`${baseUrl}/SendMail/get/`, request);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise({
            success: 'Email Success',
        });
    }
};
