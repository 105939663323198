import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import { IClaim } from '../../interface/claims/getClaimsResponse';

interface IClaimsTable {
    claims: IClaim[];
    tableRef: any;
    defaultSearch?: string;
}

const ClaimsTable = (props: IClaimsTable) => {
    const { tableRef, claims, defaultSearch } = props;
    const { classes } = useStyles();
    const tableColumns = [
        {
            field: 'vrnA__Account__r.name',
            title: 'INSURED BUSINESS NAME',
            width: '100%',
            cellStyle: { padding: '12px 8px', maxWidth: '315px' },
        },
        {
            field: 'vrnA__Claim_Number__c',
            title: 'CLAIMS REFERENCE',
            width: '20%',
            cellStyle: { padding: '12px 8px' },
        },
        {
            field: 'vrnA__Policy_Number__c',
            title: 'POLICY NUMBER',
            width: '20%',
            cellStyle: { padding: '12px 8px' },
        },
        {
            field: 'vrnA__Status__c',
            title: 'CLAIMS STATUS',
            width: '10%',
            searchable: false,
            cellStyle: { padding: '12px 8px' },
        },
        {
            field: 'vrnA__Date_of_Loss__c',
            title: 'CLAIM DATE',
            width: '10%',
            searchable: false,
            cellStyle: { padding: '12px 8px' },
            render: (row: IClaim) => (
                <>
                    {row.vrnA__Date_of_Loss__c && moment(row.vrnA__Date_of_Loss__c).format('MM/DD/yyyy')}
                </>
            ),
        },
        {
            field: 'paid',
            title: 'PAID',
            width: '10%',
            searchable: false,
            cellStyle: { padding: '12px 8px' },
            render: (row: IClaim) => (
                <>
                    {(row?.vrnA__Paid__c !== null || row?.vrnA__Paid__c !== undefined) &&
                        Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2,
                        }).format(Number(row?.vrnA__Paid__c))}
                </>
            )
        },
        {
            field: 'reserved',
            title: 'RESERVED',
            width: '10%',
            searchable: false,
            cellStyle: { padding: '12px 8px' },
            render: (row: IClaim) => (
                <>
                    {(row?.vrnA__Reserve__c !== null || row?.vrnA__Reserve__c !== undefined) &&
                        Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2,
                        }).format(Number(row?.vrnA__Reserve__c))}
                </>
            )
        },
        {
            field: 'totalIncurred',
            title: 'TOTAL INCURRED',
            width: '10%',
            searchable: false,
            cellStyle: { padding: '12px 8px' },
            render: (row: IClaim) => (
                <>
                    {(row?.indemnity_Incurred__c !== null || row?.indemnity_Incurred__c !== undefined) &&
                        Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            maximumFractionDigits: 2,
                        }).format(Number(row?.indemnity_Incurred__c))}
                </>
            )
        },
    ];
    return (
        <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={claims}
            detailPanel={[
                {
                  tooltip: 'More Details',
                  render: rowData => {
                    return (
                        <Grid container className={classes.agencyGrid}>
                            <Grid container direction="row" xs={12} spacing={3}>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            INCEPTION DATE
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.vrnA__Effective_Date__c
                                            && moment(rowData.vrnA__Effective_Date__c).format('MM/DD/yyyy')}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            EXPIRY DATE
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.vrnA__Expiration_Date__c &&
                                            moment(rowData.vrnA__Expiration_Date__c).format('MM/DD/yyyy')}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            DATE NOTIFIED OF LOSS
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.vrnA__Date_Reported__c
                                            && moment(rowData.vrnA__Date_Reported__c).format('MM/DD/yyyy')}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            CLAIMANT NAME
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData.claimant_Name__c}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            CLAIM ADJUSTER NAME
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData?.claim_Adjuster__r?.name}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            CLAIM ADJUSTER EMAIL
                                        </Typography>
                                        <Typography variant="body2">
                                            {rowData?.claim_Adjuster__r?.email}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                  },
                }
            ]}
            options={{
                search: true,
                searchText: defaultSearch,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                headerStyle: {
                    backgroundColor: '#FFFFFF',
                    fontWeight: 600,
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    padding: '12px 8px',
                },
                rowStyle: {
                    color: '#4C5159',
                },
                overflowY: 'auto',
                thirdSortClick: false,
                showTitle: false,
                sorting: true,
                minBodyHeight: '350px',
            }}
            style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', overflowX: 'auto' }}
        />
    );
};

export default ClaimsTable;
