import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Dispatch, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../sagas/index';
import claimsReducer, { IClaimsReducer } from './claims';
import policiesReducer, { IPoliciesReducer } from './policies';
import quoteReducer, { IQuoteReducer } from './quotes';
import renewalsReducer, { IRenewalsReducer } from './renewals';

export interface IReduxState {
    quote: IQuoteReducer;
    policy: IPoliciesReducer;
    renewals: IRenewalsReducer;
    claims: IClaimsReducer
}
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
    (process.env.REACT_APP_ENVIRONMENT !== 'production' &&
        typeof window !== 'undefined' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
const preloadState = composeEnhancers(applyMiddleware(sagaMiddleware));
const store: Store<any, AnyAction> & {
    dispatch: Dispatch;
} = createStore(
    combineReducers({
        quote: quoteReducer,
        policy: policiesReducer,
        renewals: renewalsReducer,
        claims: claimsReducer
    }),
    preloadState
);
sagaMiddleware.run(rootSaga);

export default store;
