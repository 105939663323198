import { Configuration, PopupRequest } from '@azure/msal-browser';

// Config object to be passed to MSal on creation
const authValue = {
    clientId: process.env.REACT_APP_CLIENT_ID || '',

    authority: process.env.REACT_APP_AUTHORITY || '',

    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES || ''],

    redirectUri: process.env.REACT_APP_REDIRECT_URL || '',
};

export const msalConfig: Configuration = {
    auth: authValue,
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

// Scopes for access token used at MS-Identity-Platform-endpoints
export const loginRequest: PopupRequest = {
    scopes: ['offline_access', 'openid', process.env.REACT_APP_SCOPES || ''],
};

export const graphScopes: PopupRequest = {
    scopes: ['User.Read.All'],
};
