export const territoryDetails: {
    territory: string;
    email: string;
}[] = [
    {
        territory: 'SOUTHWEST',
        email: 'lnonas@evolvemga.com',
    },
    {
        territory: 'NORTHWEST',
        email: 'pway@evolvemga.com',
    },
    {
        territory: 'CENTRALNORTH',
        email: 'ltornambe@evolvemga.com',
    },
    {
        territory: 'CENTRALSOUTH',
        email: 'mdunn@evolvemga.com',
    },
    {
        territory: 'NORTHEAST',
        email: 'bglasgow@evolvemga.com',
    },
    {
        territory: 'SOUTHEAST',
        email: 'cendris@evolvemga.com',
    },
];

export const defaultEmail = 'ASmith@evolvemga.com';
export const devToEmail = 'Remya.Deepu@ust.com';
export const devFromEmail = 'SYSAutoUser_SrvAcc@evolvemga.com';
export const lossrunsEmail = process.env.REACT_APP_ENVIRONMENT === 'Production' ? 'lossruns@evolvemga.com' :
process.env.REACT_APP_ENVIRONMENT === 'Development' ? 'SYSAutoUser_SrvAcc@evolvemga.com' : 'shilpa.george1@ust.com';

export const appetiteGuideLink ='https://evolvemga.sharepoint.com/:b:/g/ESloxM_1j5tHuDir6ww75QsBaMiPsvI0Iob8tYvSIaKyvQ';
