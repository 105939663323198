import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { styles } from './styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { IInsuredData, ISearchInsuredResponse } from '../../interface/quotes/searchInsured';
import Button from '../button/button';
import { IIndustryActivitiesData } from '../../interface/quotes/industryActivities';

export interface IInsuredListlProps {
    title: string;
    confirmModalStatus: boolean;
    role?: string;
    data?: ISearchInsuredResponse;
    onAdd?: any;
    agentCode?: string;
    industry?: IIndustryActivitiesData;
    buttonVariant?: 'text' | 'outlined' | 'contained';
    onCancel: () => void;
}

const InsuredListModal = (props: IInsuredListlProps) => {
    const { classes } = styles();
    const { title, role, confirmModalStatus, data, onAdd, agentCode, onCancel, buttonVariant, industry } = props;
    const [error, setError] = React.useState(false);
    const validateInsuredCode = (value: IInsuredData) => {
        const industryName = value.industry__r.industryName
        ? value.industry__r.industryName :
        industry?.industry?.find(ind => ind.industryCode === value.industry__r.name)?.industryName;
        // eslint-disable-next-line no-console
        if (agentCode == value.retail_Broker__r?.broker_Code__c) {
            const data = {
                name: value.name,
                physicalStreet: value.billingAddress.street,
                website: value.website,
                physicalCity: value.billingAddress.city,
                physicalZip: value.billingAddress.postalCode,
                physicalState: value.billingAddress.state,
                insuredId: value.id,
                industryName: value.industry__r.name+'#'+industryName
            };
            onAdd(data);
            setError(false);
        } else {
            setError(true);
        }
    };
    const onClear = () => {
        onCancel();
        setError(false);
    };
    return (
        <Dialog
            classes={{ paper: classes.shapeInsured }}
            aria-labelledby="customized-dialog-title"
            open={confirmModalStatus}
            maxWidth="sm"
            fullWidth
            role={role}
        >
            <DialogTitle id="customized-dialog-title" classes={{ root: classes.warningModalTitle }}>
                <Typography variant="h6">{title}</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }} className={classes.warningModalContentInsured}>
                <Grid xs={12}>
                    <List>
                        {data?.records?.map((value) => (
                            <ListItem key={value.id} disableGutters className={classes.listItem}>
                                <ListItem
                                    button
                                    onClick={() => {
                                        validateInsuredCode(value);
                                    }}
                                    role='list-role'
                                >
                                    <ListItemText primary={value.name} />
                                </ListItem>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </DialogContent>
            {error && (
                <Grid className={classes.errorGrid}>
                    We have received a submission for this Insured from another retail broker and cannot process your
                    request
                </Grid>
            )}
            <Button
                color="primary"
                variant={buttonVariant}
                className={classes.buttonCancel}
                autoFocus
                onClick={onClear}
                content="Cancel"
                data-testid="cancel-btn"
                role='cancel-button'
            />
        </Dialog>
    );
};
export default InsuredListModal;
