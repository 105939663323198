import axios, { InternalAxiosRequestConfig } from 'axios';
import { updatePendingAPIAction } from '../modules/quotes';
import store from '../modules/store';
import msalInstance from '../constants/authentication/authProvider';

const instance = axios.create({});

let isTestEnv = false;

export const setTestEnv = (value: boolean) => {
    isTestEnv = value;
};
const accounts = msalInstance.getAllAccounts();
const logoutRequest = {
    account: msalInstance.getAccountByHomeId(accounts[0]?.localAccountId),
};

instance.interceptors.request.use(async function (config: InternalAxiosRequestConfig) {
    try {
        if (!isTestEnv) {
            const graphAPI = config.url?.includes('graph.microsoft.com');
            const tokenFile = await import('./getAccessToken');
            const accessToken = await tokenFile.getAccessToken(graphAPI);
            store.dispatch(updatePendingAPIAction(1));
            if (accessToken) {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
                config.headers['Access-Control-Allow-Credentials'] = true;
                config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
            }
            return config;
        } else {
            return config;
        }
    } catch (error) {
        return config;
    }
});

instance.interceptors.response.use(
    (response: any) => {
        if (!isTestEnv) {
            store.dispatch(updatePendingAPIAction(-1));
        }
        return response;
    },

    (error) => {
        if (!isTestEnv) {
            store.dispatch(updatePendingAPIAction(-1));
        }
        if (!error.response) {
            throw new Error(JSON.stringify({ message: error.message }));
        } else {
            if (error.response.status === 401) {
                msalInstance.logoutRedirect(logoutRequest);
                throw new Error(JSON.stringify({ message: 'Session Expired' }));
            }
            throw new Error(JSON.stringify(error.response.data));
        }
    }
);

export default instance;
