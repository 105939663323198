import React from 'react';
import { Grid } from '@mui/material';
import { Facebook, Pinterest, Linkedin, Twitter, Youtube } from '../../assets/icons';
import { styles } from './styles';

const Footer = () => {
    const { classes } = styles();
    return (
        <>
            <div className={classes.footer}>
                <Grid container direction="row" xs={12} className={classes.container}>
                    <Grid item xs={10} className={classes.notes}>
                        @ 2022 Evolve MGA. All Rights Reserved.
                    </Grid>
                    <Grid item xs={2}>
                        <a rel="noopener noreferrer" href="https://twitter.com/EvolveMGA" target="_blank">
                            <img src={Twitter} className={classes.images} />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.facebook.com/evolvemga" target="_blank">
                            <img src={Facebook} className={classes.images} />
                        </a>
                        <a rel="noopener noreferrer" href="https://www.linkedin.com/company/evolve-mga" target="_blank">
                            <img src={Linkedin} className={classes.images} />
                        </a>
                        <a rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UCfy28TwfSn1bGCQ3bkKX8ug"
                            target="_blank"
                        >
                            <img src={Youtube} className={classes.images} />
                        </a>
                        <a rel="noopener noreferrer"
                            href="https://www.pinterest.com/business/hub"
                            target="_blank"
                        >
                            <img src={Pinterest} className={classes.images} />
                        </a>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
export default Footer;
