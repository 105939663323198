import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0394BD',
        },
        background: {
            default: '#FFFFFF',
            paper: '#F7F8FB',
        },
        secondary: {
            main: '#FFFFFF',
            light: '#0065FF',
        },
        text: {
            primary: '#2C2A29',
        },
        error: {
            main: '#ff0000',
            light: '#C84400',
        },
    },
    typography: {
        fontFamily: ['Nunito Sans'].join(',')
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 675,
            md: 995,
            lg: 1345,
            xl: 1920,
        },
    }
});

export default theme;
