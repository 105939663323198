import { makeStyles } from 'tss-react/mui';

export const containerStyles = makeStyles()((theme: any) => ({
    root: {
        background: '#F7F8FB',
        color: '#4C5159',
        fontFamily: 'Nunito Sans',
    },
    pageContent: {
        backgroundColor: theme.palette.background.paper,
        paddingLeft: 140,
        paddingRight: 140,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 100,
            paddingRight: 100,
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
}));
