import React, { Dispatch } from 'react';
import FormComponent from '../../components/form-field/form-field';
import { withFormik, Form, FormikProps } from 'formik';
import { Button, Grid, Tooltip } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { IReduxState } from '../../modules/store';
import { backTo } from '../../assets/icons';
import { IViewPolicyDetails } from '../../interface/policies/viewPolicy';
import { IViewData } from './policies';
import moment from 'moment';
import CustomizedAccordions from '../../components/accordian/accordian';
import { AnyAction } from 'redux';
import { getFilesActionSuccess, sendEmailAction } from '../../modules/quotes';
import { radiovalues, securityRequirements } from '../../constants/securityRequirements';
import HelpEmail from '../help-email/help-email';
import { IBrokerDetails } from '../../interface/quotes/getBrokerDetails';
import { ISecurityReq } from '../../interface/common';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMsal } from '@azure/msal-react';
import { lossrunsEmail } from '../../constants/helpEmail';

import { currencyFormat } from '../../utils/currencyFormat';
interface IViewPolicyProps {
    backToQuotesClick: () => void;
    view: IViewData;
    viewPolicyDetails?: IViewPolicyDetails;
    brokerDetails?: IBrokerDetails;
    documentList?: any;
    getFilesSuccess: (data: any) => void;
    viewClaim: (id?: string, policyNumber?: string) => void;
    sendEmail: (data: any) => void;
}
interface IViewPolicyValues {
    insuredName: string;
    physicalStreet: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    endorsements__c: string;
    mediaLiability: string;
    deductible__c: string;
    limit__c: string;
    vrnA__Initial_Premium_Amount__c: string;
    bound_Total_Payable__c: string;
    Cyber_Crime_Sublimit__c: string;
    Reputational_Harm_Sublimit__c: string;
    Industry__c: string;
    Business_Activities__c: string;
    VRNA__Account_Manager__r_name: string;
    vrnA__Account_Manager__r_email: string;
    Gross_Annual_Revenue__c: string;
    physicalCountry: string;
    vrnA__Effective_Date__c: string;
    vrnA__Expiration_Date__c: string;
    total_Fees__c: string;
    vrnA__Surplus_Lines_Tax__c: string;
    surplus_Lines_Tax_Rate__c: string;
    surcharge_Rate__c: string;
    Surcharge_Amount__c: string;
    flat_Rate__c: string;
    municipality_Tax_Rate__c: string;
    vrnA__State_Municipality_Tax__c: string;
    VRNA__Policy_Number__c: string;
    vrnA__Broker_Commission_Percent__c: string;
    emailFilteringSolution: string;
    MFA_on_Remote_Network_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_on_Email_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_for_Privileged_Accounts: 'Yes' | 'No' | 'Unknown' | null;
    Offline_backups: 'Yes' | 'No' | 'Unknown' | null;
    EDR_Fully_Deployed: 'Yes' | 'No' | 'Unknown' | null;
    Network_Monitoring: 'Yes' | 'No' | 'Unknown' | null;
    Annual_Phishing_Training: 'Yes' | 'No' | 'Unknown' | null;
    Email_Filtering: 'Yes' | 'No' | 'Unknown' | null;
    Update_Critical_Patches: 'Yes' | 'No' | 'Unknown' | null;
    Anti_virus_Software_and_Firewalls: 'Yes' | 'No' | 'Unknown' | null;
    Daily_Backups_Critical_Data: 'Yes' | 'No' | 'Unknown' | null;
    Incident_Greater_than_10k: 'Yes' | 'No' | 'Unknown' | null;
    Legal_Action: 'Yes' | 'No' | 'Unknown' | null;
    Regulatory_Action: 'Yes' | 'No' | 'Unknown' | null;
    Cannabis_or_Crypto: 'Yes' | 'No' | 'Unknown' | null;
    Penetration_Test_Last_24_mo: 'Yes' | 'No' | 'Unknown' | null;
    Aerospace_or_Defense_Ind: 'Yes' | 'No' | 'Unknown' | null;
    Grantmaking_or_giving_foundation: 'Yes' | 'No' | 'Unknown' | null;
    Provide_Technology_Services: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Change: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Request: 'Yes' | 'No' | 'Unknown' | null;
    Estimated_Number_of_PII: number | null;
    EOL_or_EOS_Software: 'Yes' | 'No' | 'Unknown' | null;
}
const ViewPolicy = (props: IViewPolicyProps & FormikProps<IViewPolicyValues>) => {
    const {
        handleChange,
        values,
        backToQuotesClick,
        handleSubmit,
        view,
        documentList,
        viewClaim,
        getFilesSuccess,
        viewPolicyDetails,
        sendEmail
    } = props;
    const { accounts } = useMsal();
    const email = accounts[0]?.username;
    const { classes } = useStyles();

    React.useEffect(() => {
        return () => {
            getFilesSuccess([]);
        };
    }, []);

    const selectStatusBackgroundColor = (color?: string) => {
        switch (color) {
            case 'RED':
                return classes.activeDiv;
            case 'GREEN':
                return classes.activeGreenDiv;
            case 'PURPLE':
                return classes.expiredDiv;
        }
    };
    const lossRunsEmail = () => {
        const data = {
            FromEmail: 'noreply@evolvemga.com',
            ToEmail: lossrunsEmail,
            ccEmail: email,
            Subject: `Loss Run Request - ${viewPolicyDetails?.vrnA__Account__r?.name}`,
            // eslint-disable-next-line max-len
            Body: `<html><head/><body><div>Hello,<br/><br/>Thank you for requesting loss runs! We will process your request and have them over shortly. <br/><p>Policy Number - ${viewPolicyDetails?.vrnA__Policy_Number__c}</p><br/><br/>Thank you,<br/>Evolve MGA</div></body></html>`,
            Message: '',
        };
        sendEmail(data);
    };

    return (
        <Grid container className={classes.quotesContainer}>
            <Grid container xs={12} className={classes.container}>
                <Grid item xs={5} className={classes.backToQuotesDiv}>
                    <img src={backTo} />
                    <Button className={classes.backToQuotesButton} onClick={backToQuotesClick} role="backToPolicy">
                        Back to policies
                    </Button>
                </Grid>
                <Grid xs={2} className={classes.claimsHistory} onClick={lossRunsEmail} role='requestLossRunInPolicy'>
                    Request Loss Runs
                </Grid>
                <Grid
                    item
                    xs={2}
                    className={classes.claimsHistory}
                    onClick={() => viewClaim(viewPolicyDetails?.id || '',
                    viewPolicyDetails?.vrnA__Policy_Number__c || '')}
                    role='claimsHistoryInPolicyView'
                >
                    Claims History
                </Grid>
                <Grid xs={2}>
                    <HelpEmail
                        emailDetails={{
                            brokerName:
                                (viewPolicyDetails?.vrnA__Broker__r?.broker_Code__c ||
                                    viewPolicyDetails?.vrnA__Broker__c ||
                                    '') +
                                    ': ' +
                                    viewPolicyDetails?.vrnA__Broker__r?.name || '',
                            subject: `Policy: ${viewPolicyDetails?.vrnA__First_Named_Insured__c}`,
                            toEmail: {
                                email: viewPolicyDetails?.vrnA__Account_Manager__r?.email || undefined,
                                name: viewPolicyDetails?.vrnA__Account_Manager__r?.name || undefined,
                            },
                        }}
                    />
                </Grid>
                {view && (
                    <Grid
                        item
                        xs={1}
                        className={`${classes.viewDiv} ${selectStatusBackgroundColor(
                            view?.color?.toUpperCase()
                        )}`}
                        style={{ height: '27px' }}
                    >
                        {view?.status}
                    </Grid>
                )}
            </Grid>
            <Grid container xs={12} className={classes.mainGrid}></Grid>
            <Grid container xs={12} className={classes.viewContentGrid}>
                <Grid item xs={12} className={classes.formContainer}>
                    <Form onSubmit={handleSubmit}>
                        {documentList.length > 0 && (
                            <CustomizedAccordions
                                title={'Policy Documents'}
                                panel="documents"
                                defaultOpen="documents"
                                role="documents"
                            >
                                <Grid container xs={12} className={classes.documentContainer} spacing={2}>
                                    {documentList.map((document: any, index: any) => (
                                        <Grid item xs={5} key={index} className={classes.documentItem}>
                                            <Grid container xs={12}>
                                                <Grid item xs={9}>
                                                    <a
                                                        href={document.fileLink}
                                                        target="_blank"
                                                        rel="noreferrer noopener"
                                                        className={classes.documentLink}
                                                    >
                                                        {document.fileName}
                                                    </a>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {document.createdDate &&
                                                        moment.utc(new Date(document.createdDate)).format('MM/DD/yyyy')}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CustomizedAccordions>
                        )}
                        <CustomizedAccordions
                            title={'Insured Details'}
                            panel="insured_details"
                            defaultOpen="insured_details"
                            role="insuredDetails"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Business Name"
                                        id="insuredName"
                                        placeholder="Insured's Name"
                                        className={classes.inputField1}
                                        value={values.insuredName}
                                        name="insuredName"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Country"
                                        id="physicalCountry"
                                        placeholder="Insured Country"
                                        className={classes.inputField}
                                        value={values.physicalCountry}
                                        name="physicalCountry"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured State"
                                        id="physicalState"
                                        placeholder="Insured State"
                                        className={classes.inputField}
                                        value={values.physicalState}
                                        name="physicalState"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured City"
                                        id="physicalCity"
                                        placeholder="Insured City"
                                        className={classes.inputField}
                                        value={values.physicalCity}
                                        name="physicalCity"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Street"
                                        id="physicalStreet"
                                        placeholder="Insured Street"
                                        className={classes.inputField}
                                        value={values.physicalStreet}
                                        name="physicalStreet"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Insured Zip/Postal Code"
                                        id="physicalZip"
                                        placeholder="Insured Zip/Postal Code"
                                        className={classes.inputField}
                                        value={values.physicalZip}
                                        name="physicalZip"
                                        isReadOnly
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={'Insured Business Information'}
                            panel="business_info"
                            role="businessInfo"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Industry Code"
                                        id="Industry__c"
                                        placeholder="Industry Code"
                                        className={classes.inputField}
                                        value={values.Industry__c}
                                        name="Industry__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Business Activities"
                                        id="Business_Activities__c"
                                        placeholder="Business Activities"
                                        className={classes.inputField}
                                        value={values.Business_Activities__c}
                                        name="Business_Activities__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Assigned Underwriter Name"
                                        id="VRNA__Account_Manager__r_name"
                                        placeholder="Assigned Underwriter Name"
                                        className={classes.inputField}
                                        value={values.VRNA__Account_Manager__r_name}
                                        name="VRNA__Account_Manager__r_name"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Assigned Underwriter Email"
                                        id="VRNA__Account_Manager__r_email"
                                        placeholder="Assigned Underwriter Email"
                                        className={classes.inputField}
                                        value={values.vrnA__Account_Manager__r_email}
                                        name="VRNA__Account_Manager__r_email"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Gross Annual Revenue"
                                        id="Gross_Annual_Revenue__c"
                                        placeholder="Gross Annual Revenue"
                                        className={classes.inputField}
                                        value={values.Gross_Annual_Revenue__c}
                                        name="Gross_Annual_Revenue__c"
                                        isReadOnly
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={'Coverage Details'}
                            panel="coverage_details"
                            role="coverageDetails"
                        >
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Limit Bound"
                                        id="limit__c"
                                        placeholder="Limit Bound"
                                        className={classes.inputField}
                                        value={values.limit__c}
                                        name="limit__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Deductible Bound"
                                        id="deductible__c"
                                        placeholder="Deductible Bound"
                                        className={classes.inputField}
                                        value={values.deductible__c}
                                        name="deductible__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Premium Bound"
                                        id="vrnA__Initial_Premium_Amount__c"
                                        placeholder="Premium Bound"
                                        className={classes.inputField}
                                        value={values.vrnA__Initial_Premium_Amount__c}
                                        name="vrnA__Initial_Premium_Amount__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Total Payable"
                                        id="bound_Total_Payable__c"
                                        placeholder="Total Payable"
                                        className={classes.inputField}
                                        value={values.bound_Total_Payable__c}
                                        name="bound_Total_Payable__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Cyber Crime Limit"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="Cyber_Crime_Sublimit__c"
                                        placeholder="Cyber Crime Limit"
                                        className={classes.inputField}
                                        value={values.Cyber_Crime_Sublimit__c}
                                        name="Cyber_Crime_Sublimit__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Reputational Harm Limit"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="Reputational_Harm_Sublimit__c"
                                        placeholder="Reputational Harm Limit"
                                        className={classes.inputField}
                                        value={values.Reputational_Harm_Sublimit__c}
                                        name="Reputational_Harm_Sublimit__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Media Liability"
                                        labelDescription="(Subject to Underwriter Confirmation)"
                                        id="mediaLiability"
                                        placeholder="Media Liability"
                                        className={classes.inputField}
                                        value={values.mediaLiability}
                                        name="mediaLiability"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Effective Date"
                                        id="vrnA__Effective_Date__c"
                                        placeholder="Effective Date"
                                        className={classes.inputField}
                                        value={values.vrnA__Effective_Date__c}
                                        name="vrnA__Effective_Date__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Expiration Date"
                                        id="vrnA__Expiration_Date__c"
                                        placeholder="Expiration Date"
                                        className={classes.inputField}
                                        value={values.vrnA__Expiration_Date__c}
                                        name="vrnA__Expiration_Date__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Total Fees"
                                        id="total_Fees__c"
                                        placeholder="Total Fees"
                                        className={classes.inputField}
                                        value={values.total_Fees__c}
                                        name="total_Fees__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Policy Number"
                                        id="VRNA__Policy_Number__c"
                                        placeholder="Policy Number"
                                        className={classes.inputField}
                                        value={values.VRNA__Policy_Number__c}
                                        name="VRNA__Policy_Number__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Broker Commission"
                                        id="vrnA__Broker_Commission_Percent__c"
                                        placeholder="Broker Commission"
                                        className={classes.inputField}
                                        value={values.vrnA__Broker_Commission_Percent__c}
                                        name="vrnA__Broker_Commission_Percent__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Surplus Lines Tax"
                                        id="vrnA__Surplus_Lines_Tax__c"
                                        placeholder="Surplus Lines Tax"
                                        className={classes.inputField}
                                        value={values.vrnA__Surplus_Lines_Tax__c}
                                        name="vrnA__Surplus_Lines_Tax__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Surplus Lines Tax Rate"
                                        id="surplus_Lines_Tax_Rate__c"
                                        placeholder="Surplus Lines Tax Rate"
                                        className={classes.inputField}
                                        value={values.surplus_Lines_Tax_Rate__c}
                                        name="surplus_Lines_Tax_Rate__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Surcharge Rate"
                                        id="surcharge_Rate__c"
                                        placeholder="Surcharge Rate"
                                        className={classes.inputField}
                                        value={values.surcharge_Rate__c}
                                        name="surcharge_Rate__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Surcharge Amount"
                                        id="Surcharge_Amount__c"
                                        placeholder="Surcharge Amount"
                                        className={classes.inputField}
                                        value={values.Surcharge_Amount__c}
                                        name="Surcharge_Amount__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Flate Rate"
                                        id="flat_Rate__c"
                                        placeholder="Flate Rate"
                                        className={classes.inputField}
                                        value={values.flat_Rate__c}
                                        name="flat_Rate__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="Municipality Tax Rate"
                                        id="municipality_Tax_Rate__c"
                                        placeholder="Municipality Tax Rate"
                                        className={classes.inputField}
                                        value={values.municipality_Tax_Rate__c}
                                        name="municipality_Tax_Rate__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="input"
                                        handleChange={handleChange}
                                        label="State/Municipality Tax"
                                        id="vrnA__State_Municipality_Tax__c"
                                        placeholder="State/Municipality Tax"
                                        className={classes.inputField}
                                        value={values.vrnA__State_Municipality_Tax__c}
                                        name="vrnA__State_Municipality_Tax__c"
                                        isReadOnly
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormComponent
                                        component="textarea"
                                        handleChange={handleChange}
                                        label="Endorsements"
                                        id="endorsements__c"
                                        placeholder="Endorsements"
                                        className={classes.textAreaField}
                                        value={values.endorsements__c}
                                        name="endorsements__c"
                                        isReadOnly
                                        rows={3}
                                        rowsMax={3}
                                        datatestid="endorsements"
                                    />
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                        <CustomizedAccordions
                            title={'Security Requirements'}
                            panel="security_req"
                            role="securityRequirements"
                        >
                            <Grid container spacing={2} className={classes.securityMainContainer}>
                                <Grid item>
                                    {securityRequirements.map((req: ISecurityReq, index) => (
                                        <Grid
                                            container
                                            xs={11}
                                            spacing={2}
                                            key={index}
                                            className={classes.securityContainerView}
                                        >
                                            <Grid item xs={8}>
                                                {req.label}
                                                {req?.tooltipContent && (
                                                    <Tooltip title={req.tooltipContent} placement="top-start">
                                                        <IconButton className={classes.tooltip}>
                                                            <InfoOutlinedIcon className={classes.tooltipIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </Grid>
                                            <Grid item xs={4}>
                                                {req.valueLabel === 'Estimated_Number_of_PII' ? (
                                                    <FormComponent
                                                        component="input"
                                                        handleChange={handleChange}
                                                        id="Estimated_Number_of_PII"
                                                        placeholder="Estimated Number of PII"
                                                        className={classes.inputField}
                                                        value={values.Estimated_Number_of_PII}
                                                        name="Estimated_Number_of_PII"
                                                        isReadOnly
                                                    />
                                                ) : (
                                                    <FormComponent
                                                        required
                                                        value={(values as any)[req.valueLabel]}
                                                        name={req.valueLabel}
                                                        component="radio"
                                                        id={req.valueLabel}
                                                        radioOptions={radiovalues}
                                                        datatestid={`securityLabel-${req.valueLabel}`}
                                                        disabled
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </CustomizedAccordions>
                    </Form>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ViewPolicyForm = withFormik<IViewPolicyProps, IViewPolicyValues>({
    mapPropsToValues: (props) => {
        const controlFields = props.viewPolicyDetails?.control_questions__r;
        const additionalFields = props.viewPolicyDetails?.additional_fields__r;
        return {
            insuredName: props.viewPolicyDetails?.vrnA__Account__r?.name || '',
            physicalStreet: props.viewPolicyDetails?.vrnA__Account__r?.billingAddress?.street || '',
            physicalCity: props.viewPolicyDetails?.vrnA__Account__r?.billingAddress?.city || '',
            physicalState: props.viewPolicyDetails?.vrnA__Account__r?.billingAddress?.state || '',
            physicalZip: props.viewPolicyDetails?.vrnA__Account__r?.billingAddress?.postalCode || '',
            endorsements__c:
                (props.viewPolicyDetails?.endorsements__c &&
                    props.viewPolicyDetails?.endorsements__c
                        .split(';')
                        .map((line, index) => `${index + 1}. ${line}`)
                        .join('\n')) ||
                '',
            mediaLiability:
                (props.viewPolicyDetails?.coverages__c &&
                    props.viewPolicyDetails?.coverages__c.includes('Media Liability') ?
                    'Full Limits' : 'No Coverage') ||
                '',
            deductible__c:
                (props.viewPolicyDetails?.deductible__c && currencyFormat(props.viewPolicyDetails?.deductible__c, 0)) ||
                '$0',
            limit__c:
                (props.viewPolicyDetails?.limit__c && currencyFormat(props.viewPolicyDetails?.limit__c, 0)) || '$0',
            vrnA__Initial_Premium_Amount__c:
                (props.viewPolicyDetails?.vrnA__Initial_Premium_Amount__c &&
                    currencyFormat(props.viewPolicyDetails?.vrnA__Initial_Premium_Amount__c)) ||
                '$0',
            bound_Total_Payable__c:
                (props.viewPolicyDetails?.bound_Total_Payable__c &&
                    currencyFormat(props.viewPolicyDetails?.bound_Total_Payable__c))
                    ||
                '$0.00',
            Cyber_Crime_Sublimit__c:
                (props.viewPolicyDetails?.cyber_Crime_Sublimit__c &&
                    currencyFormat(props.viewPolicyDetails?.cyber_Crime_Sublimit__c,0))
                    ||
                '$0.00',
            Reputational_Harm_Sublimit__c: props.viewPolicyDetails?.reputational_Harm_Sublimit__c || '',
            Industry__c: props.viewPolicyDetails?.vrnA__Account__r?.industry__r?.industryName || '',
            Business_Activities__c: props.viewPolicyDetails?.business_Activities__c || '',
            VRNA__Account_Manager__r_name: props.viewPolicyDetails?.vrnA__Account_Manager__r?.name || '',
            vrnA__Account_Manager__r_email: props.viewPolicyDetails?.vrnA__Account_Manager__r?.email || '',
            Gross_Annual_Revenue__c:
            (props.viewPolicyDetails?.gross_Annual_Revenue__c &&
                    currencyFormat(props.viewPolicyDetails?.gross_Annual_Revenue__c,0))
                ||
            '$0',
            physicalCountry: props.viewPolicyDetails?.vrnA__Account__r?.billingAddress?.country || '',
            vrnA__Effective_Date__c:
                (props.viewPolicyDetails?.vrnA__Effective_Date__c &&
                    moment(props.viewPolicyDetails?.vrnA__Effective_Date__c).format('MM/DD/yyyy')) ||
                '',
            vrnA__Expiration_Date__c:
                (props.viewPolicyDetails?.vrnA__Expiration_Date__c &&
                    moment(props.viewPolicyDetails?.vrnA__Expiration_Date__c).format('MM/DD/yyyy')) ||
                '',
            total_Fees__c:
                (props.viewPolicyDetails?.total_Fees__c &&
                    currencyFormat(props.viewPolicyDetails?.total_Fees__c,0))
                    ||
                '$0.00',
            vrnA__Surplus_Lines_Tax__c:
                (props.viewPolicyDetails?.vrnA__Surplus_Lines_Tax__c &&
                    currencyFormat(props.viewPolicyDetails?.vrnA__Surplus_Lines_Tax__c))
                    ||
                '',
            surplus_Lines_Tax_Rate__c:
                (props.viewPolicyDetails?.surplus_Lines_Tax_Rate__c &&
                    `${props.viewPolicyDetails?.surplus_Lines_Tax_Rate__c.toFixed(2)}%`) ||
                '0',
            surcharge_Rate__c:
                (props.viewPolicyDetails?.surcharge_Rate__c &&
                    `${props.viewPolicyDetails?.surcharge_Rate__c.toFixed(2)}%`) ||
                '0',
            flat_Rate__c:
                (props.viewPolicyDetails?.flat_Rate__c &&
                    currencyFormat(props.viewPolicyDetails?.flat_Rate__c))
                    ||
                '$0.00',
            municipality_Tax_Rate__c:
                (props.viewPolicyDetails?.municipality_Tax_Rate__c &&
                    `${props.viewPolicyDetails?.municipality_Tax_Rate__c.toFixed(2)}%`) ||
                '0',
            vrnA__State_Municipality_Tax__c:
                (props.viewPolicyDetails?.vrnA__State_Municipality_Tax__c &&
                    currencyFormat(props.viewPolicyDetails?.vrnA__State_Municipality_Tax__c))
                    ||
                '$0.00',
            VRNA__Policy_Number__c: props.viewPolicyDetails?.vrnA__Policy_Number__c || '',
            vrnA__Broker_Commission_Percent__c:
                (props.viewPolicyDetails?.vrnA__Broker_Commission_Percent__c &&
                    `${props.viewPolicyDetails?.vrnA__Broker_Commission_Percent__c.toFixed(2)}%`) ||
                '0',
            Surcharge_Amount__c:
                (props.viewPolicyDetails?.surcharge_Amount__c &&
                    currencyFormat(props.viewPolicyDetails?.surcharge_Amount__c))
                ||
                '$0.00',
            MFA_on_Remote_Network_Enabled: controlFields?.mfA_on_Remote_Network_Enabled__c || 'Unknown',
            MFA_on_Email_Enabled: controlFields?.mfA_on_Email_Enabled__c || 'Unknown',
            MFA_for_Privileged_Accounts: controlFields?.mfA_for_Privileged_Accounts__c || 'Unknown',
            Offline_backups: controlFields?.offline_Backups__c || 'Unknown',
            EDR_Fully_Deployed: controlFields?.edR_Fully_Deployed__c || 'Unknown',
            Network_Monitoring: controlFields?.network_Monitoring__c || 'Unknown',
            Annual_Phishing_Training: controlFields?.annual_Phishing_Training__c || 'Unknown',
            Email_Filtering: controlFields?.email_Filtering__c || 'Unknown',
            Update_Critical_Patches: controlFields?.update_Critical_Patches__c || 'Unknown',
            Anti_virus_Software_and_Firewalls: controlFields?.anti_virus_Software_and_Firewalls__c || 'Unknown',
            Daily_Backups_Critical_Data: controlFields?.daily_Backups_Critical_Data__c || 'Unknown',
            Incident_Greater_than_10k: controlFields?.incident_Greater_than_10k__c || 'Unknown',
            Legal_Action: controlFields?.legal_Action__c || 'Unknown',
            Regulatory_Action: controlFields?.regulatory_Action__c || 'Unknown',
            Cannabis_or_Crypto: controlFields?.cannabis_or_Crypto__c || 'Unknown',
            Penetration_Test_Last_24_mo: controlFields?.penetration_Test_Last_24_mo__c || 'Unknown',
            Aerospace_or_Defense_Ind: controlFields?.aerospace_or_Defense_Ind__c || 'Unknown',
            Grantmaking_or_giving_foundation: controlFields?.grantmaking_or_giving_foundation__c || 'Unknown',
            Provide_Technology_Services: controlFields?.provide_Technology_Services__c || 'Unknown',
            Dual_Authorization_Change: controlFields?.dual_Authorization_Change__c || 'Unknown',
            Dual_Authorization_Request: controlFields?.dual_Authorization_Request__c || 'Unknown',
            Estimated_Number_of_PII: additionalFields?.estimated_Number_of_PII__c || 0,
            EOL_or_EOS_Software: controlFields?.eoL_or_EOS_Software__c || 'Unknown',
            emailFilteringSolution: controlFields?.email_Filtering__c || '',
        };
    },
    enableReinitialize: true,
    handleSubmit: () => {
        //formik handlesubmit
    },
})(ViewPolicy);

const mapStateToProps = (state: IReduxState) => ({
    viewPolicyDetails: state.policy.viewPolicyDetails,
    brokerDetails: state.quote.brokerDetails,
    documentList: state.quote.files,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getFilesSuccess: (data: any) => dispatch(getFilesActionSuccess(data)),
    sendEmail: (data: any) => dispatch(sendEmailAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPolicyForm);
