import { Grid } from '@mui/material';
import { Alert } from '@mui/material';
import React from 'react';
import { styles } from './styles';

export interface IBannerProps {
    text: {
        heading: string;
        content: string;
    };
}

const Banner = (props: IBannerProps) => {
    const { classes } = styles();
    const { text } = props;
    return (
        <Alert icon={false} severity="warning" className={classes.alertGrid}>
            <Grid container xs={12} className={classes.outerGrid}>
                <Grid item xs={1}>
                    <Grid container xs={12}>
                        <Grid item xs={10} className={classes.headingContainer}>
                            {text.heading}
                        </Grid>
                        <Grid item xs={2} className={classes.splitter}></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={11} className={classes.contentGrid}>
                    {text.content}
                </Grid>
            </Grid>
        </Alert>
    );
};

export default Banner;
