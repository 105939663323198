import React from 'react';
import { Avatar, Grid, Container } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { Evolve } from '../../assets/icons';
import { getUsernameLogo } from '../../utils/getUsername';
import { styles } from './styles';
import Tabs from '../../components/tab/tab';
import routes from '../../constants/routes';

const Header = () => {
    const { classes } = styles();
    const { accounts } = useMsal();
    let username;
    if (accounts[0]?.username) {
        username = getUsernameLogo(accounts[0]?.name || '');
    }

    return (
        <div className={classes.header} role="header">
            <Container maxWidth="xl" className={classes.pageContent}>
                <Grid container>
                    <Grid item md={3} xs={3}>
                        <img src={Evolve} className={classes.logoContainer} />
                    </Grid>
                    <Grid item md={8} xs={7}>
                        <Tabs tabClassName={classes.tabs} list={routes} variant="scrollable" indicatorColor="primary" />
                    </Grid>
                    <Grid item md={1} xs={2} className={classes.textRight}>
                        <Grid className={classes.avatarOver}>
                            <Grid>
                                <Avatar className={classes.avatar}>{username?.logo}</Avatar>
                            </Grid>
                            <Grid className={classes.logoName}>{username?.name}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};
export default Header;
