import { AnyAction } from 'redux';
import { IPolicies } from '../interface/policies/policiesTable';
import { IViewPolicyDetails } from '../interface/policies/viewPolicy';

export interface IPoliciesReducer {
    policies: IPolicies[];
    viewPolicyDetails?: any;
}

export const GET_POLICIES_LIST = 'GET_POLICIES_LIST';
export const GET_POLICIES_LIST_SUCCESS = 'GET_POLICIES_LIST_SUCCESS';
export const GET_VIEW_POLICY_DETAILS = 'GET_VIEW_POLICY_DETAILS';
export const GET_VIEW_POLICY_DETAILS_SUCCESS = 'GET_VIEW_POLICY_DETAILS_SUCCESS';

export const getPoliciesAction = (value: string[]) => ({
    type: GET_POLICIES_LIST,
    value,
});
export const getPoliciesSuccessAction = (value: IPolicies) => ({
    type: GET_POLICIES_LIST_SUCCESS,
    value,
});

export const getViewPolicyDetailsAction = (value: string) => ({
    type: GET_VIEW_POLICY_DETAILS,
    value,
});

export const getViewPolicyDetailsSuccessAction = (value: IViewPolicyDetails) => ({
    type: GET_VIEW_POLICY_DETAILS_SUCCESS,
    value,
});

export const PoliciesReducerInit: IPoliciesReducer = {
    policies: [],
    viewPolicyDetails: undefined,
};

export const policiesReducer = (state = PoliciesReducerInit, action: AnyAction) => {
    switch (action.type) {
        case GET_POLICIES_LIST_SUCCESS:
            return {
                ...state,
                policies: action.value,
            };
        case GET_VIEW_POLICY_DETAILS_SUCCESS:
            return {
                ...state,
                viewPolicyDetails: action.value,
            };
        default:
            return state;
    }
};

export default policiesReducer;
