import { IStates } from '../interface/quotes/states';

export const statesResponse: IStates = {
    countryCode: 'US',
    countryName: 'United States',
    states: [
        {
            stateCode: 'CA',
            stateName: 'California',
            conCode: 'US',
        },
        {
            stateCode: 'PA',
            stateName: 'Pennsylvania',
            conCode: 'US',
        },
    ],
};
