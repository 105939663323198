import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()(() => ({
    footer: {
        width: '100%',
        display: 'flex',
        height: '75px',
        backgroundColor: 'black',
    },
    images: {
        width: '28px',
        height: '27px',
        padding: '7px',
    },
    container: {
        marginTop: '20px',
    },
    notes: {
        fontSize: '13px',
        color: '#bbb5b5ba',
        paddingLeft: '45px',
    },
}));
