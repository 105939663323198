import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CircularIndeterminate from '../../components/loader/loader';
import { routePath } from '../../constants/routes';
import Policies from '../../pages/policies/policies';
import NoBrokerRecordsPage from '../../pages/quotes/noBrokerError';
import Quotes from '../../pages/quotes/quotes';
import Renewals from '../../pages/renewals/renewals';
import Claims from '../../pages/claims/claims';

const AppRoutes = () => {
    return (
        <Suspense
            fallback={
                <div>
                    <CircularIndeterminate />
                </div>
            }
        >
            <Routes>
                <Route path="/" element={<Navigate to={routePath.quotes.index} replace />}/>
                <Route path={routePath.quotes.index} element={<Quotes />} />
                <Route path={routePath.policies.index} element={<Policies />} />
                <Route path={routePath.renewals.index} element={<Renewals />} />
                <Route path ={routePath.claims.index}  element={<Claims/>} />
                <Route path={routePath.NoBrokerDetails.index} element={<NoBrokerRecordsPage />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
