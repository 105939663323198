import { IPolicies } from '../interface/policies/policiesTable';

export const policiesResponse: IPolicies[] = [
    {
        vrnA__Account__r: {
            name: 'Jacob Jones',
        },
        deductible__c: 710,
        id: '12345',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Red',
    },
    {
        vrnA__Account__r: {
            name: 'Bessie Cooper',
        },
        deductible__c: 710,
        id: '123455',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 6010000,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Expired',
        policy_Color: 'Purple',
    },
    {
        vrnA__Account__r: {
            name: 'Jerome Bell',
        },
        deductible__c: 710,
        id: '6',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Green',
    },
    {
        vrnA__Account__r: {
            name: 'Ralph Edwards',
        },
        deductible__c: 710,
        id: '123457',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Red',
    },
    {
        vrnA__Account__r: {
            name: 'Robert Fox',
        },
        deductible__c: 710,
        id: '123485',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Red',
    },
    {
        vrnA__Account__r: {
            name: 'Kristin Watson',
        },
        deductible__c: 710,
        id: '887624872',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Expired',
        policy_Color: 'Purple',
    },
    {
        vrnA__Account__r: {
            name: 'Kathryn Murphy',
        },
        deductible__c: 710,
        id: '123242',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Green',
    },
    {
        vrnA__Account__r: {
            name: 'Dianne Russell',
        },
        deductible__c: 710,
        id: '213423',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Expired',
        policy_Color: 'Purple',
    },
    {
        vrnA__Account__r: {
            name: 'Floyd Miles',
        },
        deductible__c: 710,
        id: '324234',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Red',
    },
    {
        vrnA__Account__r: {
            name: 'Albert Flores',
        },
        deductible__c: 710,
        id: '2342134',
        vrnA__Initial_Premium_Amount__c: 1000,
        limit__c: 601,
        discount_Quoted_Premium_1__c: 100,
        quoted_Base_1__c: 200,
        vrnA__Effective_Date__c: '5/27/15',
        vrnA__Expiration_Date__c: '5/27/15',
        bound_Total_Payable__c: 1010,
        vrnA__Policy_Number__c: '1234',
        policy_Status: 'Active',
        policy_Color: 'Green',
    },
];

export const viewPolicyResponse: IPolicies = {
    vrnA__Account__r: {
        name: 'Jacob Jones',
    },
    deductible__c: 710,
    id: '12345',
    vrnA__Initial_Premium_Amount__c: 1000,
    limit__c: 601,
    discount_Quoted_Premium_1__c: 100,
    quoted_Base_1__c: 200,
    vrnA__Effective_Date__c: '5/27/15',
    vrnA__Expiration_Date__c: '5/27/15',
    bound_Total_Payable__c: 1010,
    vrnA__Policy_Number__c: '1234',
    policy_Status: 'Active',
    policy_Color: 'Red',
};
