const policyList = [
    {
        id: 0,
        label: 'All',
        value: 'all',
    },
    {
        id: 1,
        label: 'Active',
        value: 'active',
    },
    {
        id: 2,
        label: 'Expired',
        value: 'expired',
    },
];

const quotesList  = [
    {
        id: 0,
        label: 'All',
        value: 'all',
    },
    {
        id: 1,
        label: 'Active',
        value: 'active',
    },
    {
        id: 2,
        label: 'Inactive',
        value: 'inactive',
    },
    {
        id: 3,
        label: 'Declined',
        value: 'declined',
    },
];
const renewalsList = [
    {
        id: 0,
        label: 'All',
        value: 'all',
    },
    {
        id: 1,
        label: 'Upcoming Renewal',
        value: 'upcoming renewal',
        role: 'upcoming_renewal'
    },
    {
        id: 2,
        label: 'Expiring Renewal',
        value: 'expiring renewal',
        role: 'expiring_renewal'
    },
    {
        id: 3,
        label: 'Policy Expired',
        value: 'policy expired',
        role: 'policy_expired'
    },
];

export {policyList,quotesList,renewalsList};