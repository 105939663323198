import React, { Dispatch } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { containerStyles } from './styles';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Grid } from '@mui/material';
import ErrorBoundary from '../error-boundary/error-boundary';
import Header from '../header/header';
import Routes from '../route/route';
import CircularIndeterminate from '../loader/loader';
import { IReduxState } from '../../modules/store';
import { connect } from 'react-redux';
import Footer from '../footer/footer';
import { IBrokerDetails } from '../../interface/quotes/getBrokerDetails';
import { getBrokerDetailsAction } from '../../modules/quotes';
import { AnyAction } from 'redux';
import { useMsal } from '@azure/msal-react';
import { ToastContainer } from 'react-toastify';

interface IProps {
    pendingAPICount: number;
    brokerDetails: IBrokerDetails;
    getBrokerDetails: (data: string) => void;
}
const App = (props: IProps) => {
    const { pendingAPICount = 0, brokerDetails, getBrokerDetails } = props;
    const history = useNavigate();
    const request = {
        scopes: [
            'offline_access',
            'openid',
            'https://evolveLLCB2C.onmicrosoft.com/b9ab72f7-c856-4b32-9897-af54e5aa77cf/User.Read',
        ],
    };
    useMsalAuthentication(InteractionType.Redirect, request);

    const { classes } = containerStyles();
    const { accounts } = useMsal();
    const email = accounts[0]?.username;

    React.useEffect(() => {
        if (email) {
            getBrokerDetails(email);
        }
    }, [email]);
    React.useEffect(() => {
        if(brokerDetails?.records?.length === 0){
            history('/evolve/noBrokerDetails');
        }
    }, [brokerDetails]);
    return (
        <AuthenticatedTemplate>
            {pendingAPICount ? <CircularIndeterminate /> : ''}
            <CssBaseline classes={{ root: classes.pageContent }} />
            <ToastContainer />
            <Grid container className={classes.root}>
                <ErrorBoundary>
                    {!(pendingAPICount >= 0 && brokerDetails?.records?.length === 0) ? <Header /> : null}
                </ErrorBoundary>
                <ErrorBoundary>
                    <Routes />
                </ErrorBoundary>
                <ErrorBoundary>
                    {!(pendingAPICount >= 0 && brokerDetails?.records?.length === 0) ? <Footer /> : null}
                </ErrorBoundary>
            </Grid>
        </AuthenticatedTemplate>
    );
};

const mapStateToProps = (state: IReduxState) => ({
    pendingAPICount: state.quote.pendingAPICount,
    brokerDetails: state.quote.brokerDetails,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getBrokerDetails: (data: string) => dispatch(getBrokerDetailsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App as any);
