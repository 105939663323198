import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import {
    createQuoteAPI,
    getBrokerDetailsAPI,
    getFilesAPI,
    getIndustryActivitiesAPI,
    getInsuredDetailsAPI,
    getQuotesListAPI,
    getStatesAPI,
    sendEmailAPI,
    updateQuoteAPI,
    viewQuoteAPI,
} from '../end-points/quotes';
import { ISearchInsuredResponse } from '../interface/quotes/searchInsured';
import { ISuccessResponse } from '../interface/common';
import {
    createQuoteAction,
    createQuoteSuccessAction,
    createQuoteSuccessActionDetails,
    getBrokerDetailsAction,
    getBrokerDetailsSuccessAction,
    getFilesAction,
    getFilesActionSuccess,
    getIndustryActivitiesSuccessAction,
    getQuotesAction,
    getQuotesSuccessAction,
    getStatesSuccessAction,
    getViewQuoteDetailsAction,
    getViewQuoteDetailsSuccessAction,
    insuredModalStatusAction,
    searchInsuredAction,
    searchInsuredSuccessAction,
    sendEmailAction,
    sendEmailModalAction,
    updateQuoteAction,
    updateQuoteSuccessIdAction,
} from '../modules/quotes';
import { toastConfig } from '../constants/toaster';

export function* updateQuoteSaga(action: ReturnType<typeof updateQuoteAction>) {
    try {
        const updateResponse: ISuccessResponse<any> = yield call(updateQuoteAPI, action.value);
        if (updateResponse.data[0].outStatus) {
            if (updateResponse.data[0].success && updateResponse.data[0].errormessage) {
                toast.info(updateResponse.data[0].errormessage);
            }
            yield put(createQuoteSuccessAction());
            yield put(updateQuoteSuccessIdAction(updateResponse.data[0].id));
        } else {
            toast.error(updateResponse.data[0].errormessage);
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* createQuoteSaga(action: ReturnType<typeof createQuoteAction>) {
    try {
        const createResponse: ISuccessResponse<any> = yield call(createQuoteAPI, action.value);
        if (createResponse.data[0].outStatus) {
            if(createResponse.data[0].success && createResponse.data[0].errormessage){
                toast.info(createResponse.data[0].errormessage);
            }
            yield put(createQuoteSuccessAction());
            yield put(
                createQuoteSuccessActionDetails({
                    id: createResponse.data[0].id,
                    referralStatus: createResponse.data[0].quote_status,
                })
            );
        } else {
            toast.error(createResponse.data[0].errormessage);
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* getQuotesSaga(action: ReturnType<typeof getQuotesAction>) {
    try {
        const quotesResponse: ISuccessResponse<any> = yield call(getQuotesListAPI, action.value);
        yield put(getQuotesSuccessAction(quotesResponse.data.compositeResponse[0].body.records));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}

export function* searchInsuredSaga(action: ReturnType<typeof searchInsuredAction>) {
    try {
        const response: ISuccessResponse<ISearchInsuredResponse> = yield call(getInsuredDetailsAPI, action.value);
        yield put(searchInsuredSuccessAction(response.data));
        yield put(insuredModalStatusAction());
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* getBrokerDetailsSaga(action: ReturnType<typeof getBrokerDetailsAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(getBrokerDetailsAPI, action.value);
        const data = response.data;
        yield put(getBrokerDetailsSuccessAction(data));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* getIndustryActivitiesSaga() {
    try {
        const response: ISuccessResponse<any> = yield call(getIndustryActivitiesAPI);
        const data = response.data;
        yield put(getIndustryActivitiesSuccessAction(data));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* getStatesSaga() {
    try {
        const response: ISuccessResponse<any> = yield call(getStatesAPI);
        const data = response.data;
        yield put(getStatesSuccessAction(data));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* viewQuoteSaga(action: ReturnType<typeof getViewQuoteDetailsAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(viewQuoteAPI, action.value);
        const data = response.data;
        yield put(getViewQuoteDetailsSuccessAction(data));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* getFilesSaga(action: ReturnType<typeof getFilesAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(getFilesAPI, action.value);
        const data = response.data;
        yield put(getFilesActionSuccess(data));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* sendEmailSaga(action: ReturnType<typeof sendEmailAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(sendEmailAPI, action.value);
        const data = response.data;
        if (data.message === 'Mail send Sucessfully !') {
            toast.info('Email Send Successfully', toastConfig);
            yield put(sendEmailModalAction(false));
        } else {
            toast.error('Email Send Failed', toastConfig);
            yield put(sendEmailModalAction(false));
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
