import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme: any) => ({
    link: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
        textTransform: 'none',
        width: '112px',
    },
    linkSelected: {
        color: '#0394BD',
        textDecoration: 'none',
    },
    tab: {
        textTransform: 'none',
        minWidth: theme.spacing(10),
        width: theme.spacing(14),
        '&.MuiTab-textColorInherit': {
            opacity: 1,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.background.default,
        },
    },
}));
