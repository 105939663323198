import React from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styles } from './styles';
import { DialogContent, DialogTitle } from '@mui/material';

export interface IModalProps {
    content?: React.ReactNode;
    title?: string;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    modalStatus: boolean;
    setModalStatus: (modalStatus: boolean) => void;
    className?: string;
    minHeight?: any;
    role?: string;
    isSticky?: boolean;
    removeCloseButton?: boolean;
    customHeader?: string;
    footerContent?: React.ReactNode;
}

const Modal = (props: IModalProps) => {
    const { classes } = styles();
    const {
        content,
        modalStatus,
        className,
        title,
        maxWidth,
        setModalStatus,
        minHeight,
        role,
        removeCloseButton,
        isSticky = false,
        customHeader,
        footerContent,
    } = props;
    return (
        <Dialog
            className={minHeight ? minHeight : ''}
            classes={{ paper: classes.modalShape }}
            aria-labelledby="customized-dialog-title"
            open={modalStatus}
            maxWidth={maxWidth}
            fullWidth
            role={role}
        >
            <DialogTitle>
                <Typography variant="h6" className={customHeader}>
                    {title}
                </Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => setModalStatus(false)}
                    role="close-btn"
                >
                    {removeCloseButton && <CloseIcon />}
                </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: className }} className={isSticky ? classes.modalSpacing : ''}>
                {content}
            </DialogContent>
            <DialogTitle>{footerContent}</DialogTitle>
        </Dialog>
    );
};

export default Modal;
