import React, { Dispatch, ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { useStyles } from './styles';
import CreateNewQuoteForm from './create-quote';
import ViewQuote from './viewEdit-quote';
import QuotesTable from './quotes-table';
import { getFilesAction, getQuotesAction, getViewQuoteDetailsAction, sendEmailModalAction } from '../../modules/quotes';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IReduxState } from '../../modules/store';
import { IQuotes } from '../../interface/quotes/quotesTable';
import { IBrokerDetails, IBrokerRecords } from '../../interface/quotes/getBrokerDetails';
import Banner from '../../components/banner/banner';
import { disclaimerText } from '../../constants/disclaimer';
import { appetiteGuideLink } from '../../constants/helpEmail';
import HelpEmail from '../help-email/help-email';
import { IViewQuote, IViewQuoteById } from '../../interface/quotes/viewQuote';
import { quotesList } from '../../constants/tabLists';
interface IQuoteProps {
    getQuotes: (data: string[]) => void;
    quotes: IQuotes[];
    brokerDetails?: IBrokerDetails;
    getViewQuoteDetails: (data: IViewQuoteById) => void;
    onCreateQuoteSuccess: boolean;
    getFiles: (data: string) => void;
    setHelpModalStatus: (data:boolean) => void;
    helpModalStatus?: boolean;
    viewQuoteDetails?: IViewQuote;
}
interface IViewData {
    isView?: boolean;
    id?: string;
}
const Quotes = (props: IQuoteProps) => {
    type pageStatusEnum = 'createQuote' | 'homePage' | 'viewEdit';
    const {
        getQuotes,
        quotes,
        brokerDetails,
        getViewQuoteDetails,
        onCreateQuoteSuccess,
        helpModalStatus,
        setHelpModalStatus,
        viewQuoteDetails,
        getFiles
    } = props;
    const { classes } = useStyles();
    const [currentPage, setCurrentPage] = useState<pageStatusEnum>('homePage');
    const [tabValue, setTabValue] = React.useState('all');
    const [quotesData, setQuotesData] = useState<IQuotes[]>([]);
    const [view, setView] = React.useState<IViewData>({ isView: false, id: '' });
    const tableRef = useRef(null);
    const [helpEmail, setHelpEmail] = useState<boolean>(false);

    const backToQuotesClick = () => {
        setCurrentPage('homePage');
    };
    const backToViewPage = (value: IViewQuoteById) => {
        const formData = {
            isView: true,
            id: value.id,
        };
        setView(formData);
        getViewQuoteDetails({
            id: value.id,
            limit_type: value.limit_type
        });
        getFiles(value.id || '');
        setCurrentPage('viewEdit');
    };
    const viewQuoteForm = (data: boolean, id?: string, limit_type?: string) => {
        const formData = {
            isView: data,
            id: id,
        };
        setView(formData);
        getViewQuoteDetails({
            id: id || '',
            limit_type: limit_type || 'EVO_Limit_1__c'
        });
        data && getFiles(id || '');
        setCurrentPage('viewEdit');
    };

    const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
        setTabValue(newValue);
        if (tableRef) {
            (tableRef.current as any)?.dataManager.changeCurrentPage(0);
        }
    };

    useEffect(() => {
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getQuotes(data);
        }
    }, [brokerDetails]);

    useEffect(() => {
        if (brokerDetails && onCreateQuoteSuccess) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getQuotes(data);
        }
    }, [onCreateQuoteSuccess]);

    useEffect(() => {
        if(helpEmail){
            setHelpModalStatus(true);
        }
    }, [helpEmail]);

    useEffect(() => {
        if(helpModalStatus !== undefined)
            setHelpEmail(helpModalStatus);
    }, [helpModalStatus]);

    useEffect(() => {
        if (tabValue === 'all') {
            setQuotesData(quotes);
        } else {
            const data = quotes?.filter((quote) => quote?.quote_Status?.toUpperCase() === tabValue.toUpperCase());
            setQuotesData(data);
        }
    }, [quotes, tabValue]);

    const getQuoteDetails = (value: IViewQuoteById) => {
        getViewQuoteDetails({
            id: value.id,
            limit_type: value.limit_type
        });
    };

    const renderComponent = (): ReactElement => {
        let component: ReactElement = <></>;
        switch (currentPage) {
            case 'homePage':
                component = (
                    <>
                        <Banner text={disclaimerText} />
                        <Grid container xs={12} className={classes.gridContainer}>
                            <Grid item xs={10} role="header" className={classes.headerName}>
                                My Quotes
                            </Grid>
                            <Grid item xs={2} className={classes.buttonContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.button}
                                    onClick={() => setCurrentPage('createQuote')}
                                    role='create-new'
                                >
                                    + Create New
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item md={10} xs={10} className={classes.tabContainer}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    indicatorColor="primary"
                                    className={classes.tabs}
                                >
                                    {quotesList.map((item) => (
                                        <Tab
                                            key={item.id}
                                            value={item.value}
                                            label={item.label}
                                            className={classes.statusTab}
                                            role='tab-section'
                                        />
                                    ))}
                                </Tabs>
                            </Grid>
                            <Grid item xs={2} className={classes.appetiteQuoteGuide}>
                                <a
                                    href={appetiteGuideLink}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className={classes.documentLink}
                                    role='evolve-appetite'
                                >
                                    Evolve Appetite Guide
                                </a>
                            </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.tableContainer}>
                            <QuotesTable
                                quotes={quotesData}
                                tableRef={tableRef}
                                viewQuote={viewQuoteForm}
                                helpEmail={setHelpEmail}
                                getQuoteDetails={getQuoteDetails}
                            />
                            <HelpEmail
                                emailDetails={{
                                    brokerName: viewQuoteDetails?.records[0] &&
                                        (viewQuoteDetails?.records[0].vrnA__Broker__r.broker_Code__c ||
                                            viewQuoteDetails?.records[0].vrnA__Broker__c ||
                                            '') +
                                            ': ' +
                                            viewQuoteDetails?.records[0]?.vrnA__Broker__r?.name || '',
                                    subject: `Quote: ${viewQuoteDetails?.records[0].vrnA__Account__r.name || ''}`,
                                    toEmail: {
                                        email: viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.email,
                                        name: viewQuoteDetails?.records[0]?.vrnA__Account_Manager__r?.name,
                                    },
                                }}
                                isTable={true}
                            />
                        </Grid>
                    </>
                );
                break;
            case 'createQuote':
                component = (
                    <CreateNewQuoteForm backToQuotesClick={backToQuotesClick} backToViewPage={backToViewPage} />
                );
                break;
            case 'viewEdit':
                component = (
                    <ViewQuote backToQuotesClick={backToQuotesClick} isView={view} backToViewPage={backToViewPage} />
                );
                break;
        }
        return component;
    };

    return renderComponent();
};
const mapStateToProps = (state: IReduxState) => ({
    quotes: state.quote.quotes,
    brokerDetails: state.quote.brokerDetails,
    onCreateQuoteSuccess: state.quote.onCreateQuoteSuccess,
    helpModalStatus: state.quote.sendEmailModal,
    viewQuoteDetails: state.quote.viewQuoteDetails
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getQuotes: (data: string[]) => dispatch(getQuotesAction(data)),
    getViewQuoteDetails: (data: IViewQuoteById) => dispatch(getViewQuoteDetailsAction(data)),
    getFiles: (data: string) => dispatch(getFilesAction(data)),
    setHelpModalStatus: (data: boolean) => dispatch(sendEmailModalAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
