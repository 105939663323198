import { ISearchInsuredResponse } from '../interface/quotes/searchInsured';

export const searchInsuredResponse: ISearchInsuredResponse = {
    records: [
        {
            id: '0016g000012NK11AAG',
            name: 'ACME, LLC',
            website: 'http://acme.com/',
            primary_Contact__c: '7234972183',
            billingAddress: {
                city: 'San Francisco',
                country: 'United States',
                countryCode: 'US',
                postalCode: '94102',
                state: 'California',
                stateCode: 'CA',
                street: '800 Market Street, Ste. 800',
            },
            retail_Broker__r: {
                id: '0016g0000xUAAW',
                broker_Code__c: 'BRK-1778',
            },
            industry__r: {
                combo__c: 'Construction--',
                industryName: 'Construction',
                name: 'CN',
            }
        },
        {
            id: '0016g000012NAAG',
            name: 'ACME NEW, LLC',
            website: 'http://acmea.com/',
            primary_Contact__c: '7234972183',
            billingAddress: {
                city: 'San Francisco',
                country: 'United States',
                countryCode: 'US',
                postalCode: '94102',
                state: 'Pennsylvania',
                stateCode: 'PA',
                street: '500 Street, Ste. 800',
            },
            retail_Broker__r: {
                id: '0016g000012NKxUAAW',
                broker_Code__c: 'BRK-177814',
            },
            industry__r: {
                combo__c: 'Construction--',
                industryName: 'Construction',
                name: 'CN'
            }
        },
    ],
};
