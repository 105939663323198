import React, { Dispatch } from 'react';
import FormComponent from '../../components/form-field/form-field';
import { withFormik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Grid, FormLabel, Button, Checkbox, InputAdornment, IconButton, Typography } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ICreateQuoteRequest, INewInsured } from '../../interface/quotes/createQuote';
import SubmitConfirmationPage from './submitConfirmation';
import {
    createQuoteAction,
    getIndustryActivitiesAction,
    getStatesAction,
    insuredResetAction,
    //noInsuredModalStatusAction,
    resetInsuredAction,
    resetQuoteSuccessAction,
    searchInsuredAction,
} from '../../modules/quotes';
import moment from 'moment';
import WarningModal from '../../components/modal/warning-modal';
import ConfirmationModal from '../../components/modal/modal';
import InsuredListModal from '../../components/modal/insuredList-modal';
import { IReduxState } from '../../modules/store';
import { IInsuredData, ISearchInsured, ISearchInsuredResponse } from '../../interface/quotes/searchInsured';
import { close, backTo } from '../../assets/icons';
import { useMsal } from '@azure/msal-react';
import { getUsernameLogo } from '../../utils/getUsername';
import { IBrokerDetails, IBrokerRecords } from '../../interface/quotes/getBrokerDetails';
import { IIndustryActivitiesData } from '../../interface/quotes/industryActivities';
import { IStates } from '../../interface/quotes/states';
import { getHelpEmail } from '../../utils/getHelpEmail';
import Banner from '../../components/banner/banner';
import { disclaimerText } from '../../constants/disclaimer';
import HelpEmail from '../help-email/help-email';
import { radiovalues, securityRequirements } from '../../constants/securityRequirements';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ISecurityReq } from '../../interface/common';
import { appetiteGuideLink } from '../../constants/helpEmail';
import {
    createQuoteReferredMessage,
    createQuoteSubmitMessage,
    insuredBusinesslabelContent,
} from '../../constants/textContent';
import { IViewQuoteById } from '../../interface/quotes/viewQuote';
import { errorMessages, piiLimit } from '../../constants/errorMessages';
import { requestedLimits } from '../../constants/requestedLimits';
import { currencyFormat } from '../../utils/currencyFormat';

interface ISuccessDetails {
    id?: string;
    referralStatus?: string;
}
interface ICreateQuoteProps {
    createQuote: (data: ICreateQuoteRequest) => void;
    backToQuotesClick: () => void;
    onCreateQuoteSuccess?: boolean;
    resetQuote: () => void;
    resetInsured: () => void;
    searchInsured: (data: ISearchInsured) => void;
    insuredDetails?: ISearchInsuredResponse;
    resetInsuredDetails: () => void;
    insuredModalPopUp?: boolean;
    brokerDetails?: IBrokerDetails;
    getIndustryActivities: () => void;
    backToViewPage: (value: IViewQuoteById) => void;
    industryActivities?: IIndustryActivitiesData;
    getStates: () => void;
    states?: IStates;
    successDetails: ISuccessDetails;
    //noInsuredModalStatus:(data:boolean)=>void;
    //noInsuredFoundModalStatus?:boolean;
}
interface ICreateQuoteFormValues {
    agentCode: string;
    insuredName: string;
    website: string | null;
    physicalStreet: string;
    physicalCity: string;
    physicalState: string;
    physicalZip: string;
    industryActivities: string;
    employeeCount: string;
    grossRevenue: string;
    needByDate: string | null;
    limits: string[];
    insuredId: string;
    isNewInsured: boolean;
    brokerCode: string;
    effectiveDate: string | null;
    emailFilteringSolution: string;
    MFA_on_Remote_Network_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_on_Email_Enabled: 'Yes' | 'No' | 'Unknown' | null;
    MFA_for_Privileged_Accounts: 'Yes' | 'No' | 'Unknown' | null;
    Offline_backups: 'Yes' | 'No' | 'Unknown' | null;
    EDR_Fully_Deployed: 'Yes' | 'No' | 'Unknown' | null;
    Network_Monitoring: 'Yes' | 'No' | 'Unknown' | null;
    Annual_Phishing_Training: 'Yes' | 'No' | 'Unknown' | null;
    Email_Filtering: 'Yes' | 'No' | 'Unknown' | null;
    Update_Critical_Patches: 'Yes' | 'No' | 'Unknown' | null;
    Anti_virus_Software_and_Firewalls: 'Yes' | 'No' | 'Unknown' | null;
    Daily_Backups_Critical_Data: 'Yes' | 'No' | 'Unknown' | null;
    Incident_Greater_than_10k: 'Yes' | 'No' | 'Unknown' | null;
    Legal_Action: 'Yes' | 'No' | 'Unknown' | null;
    Regulatory_Action: 'Yes' | 'No' | 'Unknown' | null;
    Cannabis_or_Crypto: 'Yes' | 'No' | 'Unknown' | null;
    Penetration_Test_Last_24_mo: 'Yes' | 'No' | 'Unknown' | null;
    Aerospace_or_Defense_Ind: 'Yes' | 'No' | 'Unknown' | null;
    Grantmaking_or_giving_foundation: 'Yes' | 'No' | 'Unknown' | null;
    Provide_Technology_Services: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Change: 'Yes' | 'No' | 'Unknown' | null;
    Dual_Authorization_Request: 'Yes' | 'No' | 'Unknown' | null;
    Estimated_Number_of_PII: number | null;
    EOL_or_EOS_Software: 'Yes' | 'No' | 'Unknown' | null;
}
const CreateQuoteForm = (props: ICreateQuoteProps & FormikProps<ICreateQuoteFormValues>) => {
    const {
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
        backToQuotesClick,
        onCreateQuoteSuccess,
        resetQuote,
        resetInsured,
        searchInsured,
        insuredDetails,
        resetInsuredDetails,
        setValues,
        insuredModalPopUp,
        brokerDetails,
        getIndustryActivities,
        industryActivities,
        getStates,
        states,
        handleSubmit,
        setFieldTouched,
        backToViewPage,
        successDetails,
        //noInsuredModalStatus,
        //noInsuredFoundModalStatus
    } = props;
    const { classes } = useStyles();
    const { accounts } = useMsal();
    const [successModalStatus, setSuccessModalStatus] = React.useState(false);
    const [insuredModalStatus, setInsuredModalStatus] = React.useState(false);
    const [errorModalStatus, setErrorModalStatus] = React.useState(false);
    const [confirmationModalStatus, SetConfirmationModalStatus] = React.useState(false);
    const [noInsuredFoundModalStatus, setNoInsuredFoundModalStatus] = React.useState(false);
    const [inputDisabled, setInputDisabled] = React.useState(false);
    const [insuredDataFieldDisable, setInsuredDataFieldDisable] = React.useState(false);
    const [fieldsDisable, setFieldsDisable] = React.useState(true);
    const [closeButton, setCloseButton] = React.useState(false);
    const [error, setError] = React.useState('');
    const [insuredNameError, setInsuredNameError] = React.useState('');
    const [agencyCodeError, setAgencyCodeError] = React.useState('');
    const [agentDetails, setAgentDetails] = React.useState<IBrokerRecords[]>();
    const [submitError, setSubmitError] = React.useState('');
    const [submitted, setSubmitted] = React.useState(false);
    const [grossRevenueError, setGrossRevenueError] = React.useState('');
    const [existingInsuredDetails, setExistingInsuredDetails] = React.useState<IInsuredData | undefined>(undefined);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [isGRFormatted, setIsGRFormatted] = React.useState(false);
    const email = accounts[0]?.username;
    let username;
    if (accounts[0]?.username) {
        username = getUsernameLogo(accounts[0]?.name || '');
    }
    const validateError = () => {
        if (Object.keys(errors).length > 0) {
            setSubmitError(errorMessages().submitError);
        }
    };
    const searchInsuredDetails = () => {
        const insuredName = values.insuredName.trim();
        if (!values.agentCode) {
            setAgencyCodeError(errorMessages().required);
        } else {
            setAgencyCodeError('');
            searchInsured({
                insuredName: insuredName,
            });
        }
    };
    const radioOptionSelected = (data: boolean) => {
        setIsSubmit(data);
    };
    const submitPage = () => {
        if (isSubmit) {
            const floatedValue = values.grossRevenue.replace('$', '').replaceAll(',', '');
            const formatedGrossValue = Number(floatedValue);
            const date = new Date();
            const currentDate = moment(date).format('yyyy-MM-DD');
            let insuredData: INewInsured = {};
            const industry = values.industryActivities.split('#');
            const primaryContact = agentDetails && agentDetails[0].id;
            if (values.isNewInsured == true) {
                insuredData = {
                    Name: values.insuredName,
                    Retail_Broker: values.brokerCode,
                    BillingCountry: 'United States',
                    BillingCity: values.physicalCity.trim(),
                    BillingStreet: values.physicalStreet,
                    BillingState: values.physicalState,
                    BillingPostalCode: values.physicalZip,
                    website: values.website,
                    IndustryCode: industry[0],
                    IndustryName: industry[1],
                    Is_MGA_Broker: true,
                    Primary_Contact: primaryContact,
                };
            } else {
                insuredData.Primary_Contact = existingInsuredDetails?.primary_Contact__c || null;
                if (existingInsuredDetails?.website !== values.website) {
                    insuredData.website = values.website;
                }
            }
            const data = {
                Mode_of_Submission: 'Broker Portal',
                Insured_Id: values.insuredId,
                Broker_Id: values.brokerCode,
                Total_no_of_employees: values.employeeCount,
                Date_App_Recieved: currentDate,
                EVO_Limit_1: Number(values?.limits[0]) ? Number(values?.limits[0]) : null,
                EVO_Limit_2: Number(values?.limits[1]) ? Number(values?.limits[1]) : null,
                EVO_Limit_3: Number(values?.limits[2]) ? Number(values?.limits[2]) : null,
                EVO_Limit_4: Number(values?.limits[3]) ? Number(values?.limits[3]) : null,
                Gross_annual_revenue: formatedGrossValue,
                Need_by_date: moment(values.needByDate).format('yyyy-MM-DD'),
                Expected_Close_Date: moment(values.effectiveDate).format('yyyy-MM-DD'),
                Business_Activities: industry[1],
                Is_NewInsured: values.isNewInsured ? 'Yes' : 'No',
                InsuredAccount: insuredData,
                Primary_Contact: primaryContact,
                ControlQuestions: {
                    mfa_on_remote_network_enabled: values.MFA_on_Remote_Network_Enabled,
                    mfa_on_email_enabled: values.MFA_on_Email_Enabled,
                    mfa_for_priveleged_accounts: values.MFA_for_Privileged_Accounts,
                    offline_backups: values.Offline_backups,
                    edr_fully_deployed: values.EDR_Fully_Deployed,
                    network_Monitoring: values.Network_Monitoring,
                    annual_phising_training: values.Annual_Phishing_Training,
                    email_filtering: values.Email_Filtering,
                    update_critical_patches: values.Update_Critical_Patches,
                    up_to_date_anti_virus_firewall: values.Anti_virus_Software_and_Firewalls,
                    daily_critical_data_backup: values.Daily_Backups_Critical_Data,
                    incident_Greater_than_10k: values.Incident_Greater_than_10k,
                    cyber_event_legal_action: values.Legal_Action,
                    cyber_event_regulatory_action: values.Regulatory_Action,
                    direct_supply_cannabis_cryptocurrency: values.Cannabis_or_Crypto,
                    penetration_Test_Last24M: values.Penetration_Test_Last_24_mo,
                    products_for_aerospace_defense: values.Aerospace_or_Defense_Ind,
                    grantmaking_giving_foundation: values.Grantmaking_or_giving_foundation,
                    technology_services: values.Provide_Technology_Services,
                    dual_authorization_change: values.Dual_Authorization_Change,
                    dual_authorization_request: values.Dual_Authorization_Request,
                    eol_or_eos_software: values.EOL_or_EOS_Software,
                },
                AdditionalFields: {
                    estimated_number_of_pii: Number(values.Estimated_Number_of_PII),
                },
            };
            props.createQuote(data);
            SetConfirmationModalStatus(false);
        } else {
            SetConfirmationModalStatus(false);
            setErrorModalStatus(true);
        }
    };
    const handleSubmitValidation = () => {
        if (Object.keys(errors).length === 0) {
            SetConfirmationModalStatus(true);
        }
    };
    const onInsuredNameAdd = (data: any) => {
        setValues({
            ...values,
            insuredName: data.name || '',
            physicalStreet: data.physicalStreet || '',
            website: data.website,
            physicalCity: data.physicalCity || '',
            physicalZip: data.physicalZip || '',
            physicalState: data.physicalState || '',
            insuredId: data.insuredId || '',
            isNewInsured: false,
            industryActivities: data.industryName || '',
            employeeCount: '',
            grossRevenue: '',
            needByDate: null,
            limits: [],
        });
        setExistingInsuredDetails(data);
        setInsuredModalStatus(false);
        resetInsured();
        setInputDisabled(true);
        setInsuredDataFieldDisable(true);
        setFieldsDisable(false);
        setCloseButton(true);
    };
    const handleLimitChange = (event: any) => {
        const id = event.target.value;
        let newArray: any[];
        if (values.limits.find((element: any) => element === id)) {
            newArray = values.limits.filter((data: any) => data !== id);
            setFieldValue('limits', newArray);
        } else {
            if (!values.limits.length) {
                setFieldTouched('limits', true);
            }
            newArray = [...values.limits, event.target.value];
            setFieldValue('limits', newArray);
        }
    };
    const brokerDetailUpdate = (e: any) => {
        setError('');
        const data = brokerDetails?.records.filter((result: IBrokerRecords) => {
            if (result.account.broker_Code__c === e.target.value) {
                setFieldValue('brokerCode', result.accountId);
                return result;
            }
        });
        setAgentDetails(data);
    };
    const focusChange = (e: any) => {
        setIsGRFormatted(false);
        if (e.target.value) {
            const newValue = e.target.value.replace('$', '').replaceAll(',', '');
            const formatedGrossValue = Number(newValue);
            setFieldValue('grossRevenue', formatedGrossValue);
        }
    };
    const grossRevenueValueFormat = (value: any) => {
        setIsGRFormatted(true);
        const pattern = /^[0-9]+$/;
        if (!String(value).match(pattern)) {
            setFieldValue('grossRevenue', '$0.00');
        } else {
            const amount = currencyFormat(value);
            setFieldValue('grossRevenue', amount);
        }
    };
    React.useEffect(() => {
        const pattern = /^[0-9]+$/;
        if (!isGRFormatted && values.grossRevenue && !String(values.grossRevenue).match(pattern)){
            setGrossRevenueError(errorMessages().specialCharacterValidation);
        } else {
            setGrossRevenueError('');
        }
    }, [values.grossRevenue]);
    const getBrokerName = () => {
        if (agentDetails) {
            return `${agentDetails[0].account.broker_Code__c} - ${agentDetails[0].account.name}`;
        } else {
            return `${brokerDetails?.records[0].account.broker_Code__c} - ${brokerDetails?.records[0].account.name}`;
        }
    };
    React.useEffect(() => {
        getIndustryActivities();
        getStates();
    }, []);
    React.useEffect(() => {
        if (values.agentCode) {
            setAgencyCodeError('');
        }
    }, [values.agentCode]);
    React.useEffect(() => {
        if (Object.keys(errors).length === 0) {
            setSubmitError('');
        }
    }, [errors]);
    React.useEffect(() => {
        if (insuredModalPopUp) {
            if (insuredDetails?.records?.length == 0) {
                setFieldsDisable(false);
                setNoInsuredFoundModalStatus(true);
                //noInsuredModalStatus(true);
            } else {
                if (insuredDetails?.records?.length) {
                    if (insuredDetails?.records?.length > 10) {
                        setError(errorMessages().searchCriteriaError);
                    } else {
                        setError('');
                        setInsuredModalStatus(true);
                    }
                }
            }
        }
    }, [insuredModalPopUp, insuredDetails]);
    React.useEffect(() => {
        if (onCreateQuoteSuccess) {
            setSuccessModalStatus(true);
        }
    }, [onCreateQuoteSuccess]);

    React.useEffect(() => {
        if (values.insuredName === '') {
            setFieldsDisable(true);
            setValues({
                ...values,
                website: '',
                physicalStreet: '',
                physicalCity: '',
                physicalState: '',
                physicalZip: '',
            });
        }
        const str = values.insuredName;
        if (str.match(/^(?!_)(?!.*_$)(?!.*_)[a-zA-z0-9\s]+$/) || str === '') {
            setInsuredNameError('');
        } else {
            setInsuredNameError(errorMessages().specialCharacterValidation);
        }
    }, [values.insuredName]);
    return (
        <Grid container className={classes.quotesContainer}>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.backToQuotesDiv}>
                    <img src={backTo} />
                    <Button
                        className={classes.backToQuotesButton}
                        onClick={backToQuotesClick}
                        data-testid="backToQuotes"
                    >
                        Back to quotes
                    </Button>
                </Grid>
                <Grid container direction="row" xs={12}>
                    <Grid item xs={2} className={classes.title}>
                        Create new quote
                    </Grid>
                    <Grid item xs={6} className={classes.appetiteGuide}>
                        <a
                            href={appetiteGuideLink}
                            target="_blank"
                            rel="noreferrer noopener"
                            className={classes.documentLink}
                        >
                            Evolve Appetite Guide
                        </a>
                    </Grid>
                    <Grid item xs={2}>
                        <HelpEmail
                            emailDetails={{
                                brokerName: getBrokerName(),
                                subject: 'New Quote',
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} className={classes.draftButtonDiv}>
                        Draft
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.mainGrid} data-testid="main-div">
                <Form onSubmit={handleSubmit}>
                    <Grid container direction="column" className={classes.grid1} xs={12}>
                        <Grid container className={classes.agencyGrid} spacing={5}>
                            <Grid container direction="row" xs={12}>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Agency Name
                                        </Typography>
                                        <Typography variant="body2">
                                            {' '}
                                            {(agentDetails && agentDetails[0]?.account?.name) || '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Agency Email
                                        </Typography>
                                        <Typography variant="body2">{email}</Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Agency Code
                                        </Typography>
                                        <Typography variant="body2">
                                            <FormComponent
                                                component="select"
                                                handleChange={(e) => {
                                                    handleChange(e);
                                                    brokerDetailUpdate(e);
                                                }}
                                                id="agentCode"
                                                className={classes.agentCode}
                                                value={values.agentCode}
                                                name="agentCode"
                                                placeholder="-Select-"
                                                selectOptions={brokerDetails?.records.map((item) => ({
                                                    id: item.account.broker_Code__c,
                                                    name: item.account.broker_Code__c,
                                                }))}
                                                disabled={insuredDataFieldDisable}
                                                datatestid="agentCode"
                                            />
                                            <div className={classes.errorMessage1}>{agencyCodeError}</div>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Agency Address
                                        </Typography>
                                        <Typography variant="body2">
                                            {(agentDetails &&
                                                (agentDetails[0]?.account?.billingAddress?.street || '') +
                                                    '\n' +
                                                    (agentDetails[0]?.account?.billingAddress?.city + ',' || '') +
                                                    (agentDetails[0]?.account?.billingAddress?.state + ',' || '') +
                                                    (agentDetails[0]?.account?.billingAddress?.postalCode || '')) ||
                                                '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            First Name
                                        </Typography>
                                        <Typography variant="body2">
                                            {username?.name?.substring(0, username?.name?.indexOf(' '))}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Last Name
                                        </Typography>
                                        <Typography variant="body2">
                                            {username?.name?.substring(username?.name?.indexOf(' ') + 1)}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Evolve Producer Name
                                        </Typography>
                                        <Typography variant="body2">
                                            {(agentDetails && agentDetails[0]?.account?.broker_Producer_Team__r.name) ||
                                                '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Producer Name
                                        </Typography>
                                        <Typography variant="body2">
                                            {(agentDetails &&
                                                agentDetails[0]?.account?.broker_Producer_Team__r
                                                    .designated_Producer_User__r?.name) ||
                                                '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Producer Email
                                        </Typography>
                                        <Typography variant="body2">
                                            {(agentDetails &&
                                                agentDetails[0]?.account?.broker_Producer_Team__r
                                                    .designated_Producer_User__r?.email) ||
                                                '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.contents}>
                                        <Typography variant="h6" component="h6">
                                            Broker Relationship Manager Email
                                        </Typography>
                                        <Typography variant="body2">
                                            {(agentDetails && getHelpEmail(agentDetails[0]?.account?.territory__c)) ||
                                                '-'}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className={classes.errorMessage}>{error}</div>
                        <Grid container direction="row" className={classes.insuredContainer1} spacing={5}>
                            <Grid item xs={5}>
                                <Grid container direction="row">
                                    <Grid item xs={9}>
                                        <FormComponent
                                            component="input"
                                            handleChange={(e) => {
                                                handleChange(e);
                                            }}
                                            label="Insured Business Name"
                                            id="insuredName"
                                            placeholder="Insured's Name"
                                            className={classes.insuredBusinessName}
                                            touched={touched.insuredName}
                                            value={values.insuredName}
                                            name="insuredName"
                                            required
                                            datatestid="insuredName"
                                            labelContent={insuredBusinesslabelContent}
                                            disabled={inputDisabled}
                                            isReadOnly={Boolean(values.insuredName) && inputDisabled}
                                            endAdornment={
                                                closeButton ? (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                setInputDisabled(false);
                                                                setFieldsDisable(true);
                                                                setValues({
                                                                    ...values,
                                                                    insuredName: '',
                                                                    physicalStreet: '',
                                                                    website: '',
                                                                    physicalCity: '',
                                                                    physicalZip: '',
                                                                    physicalState: '',
                                                                    isNewInsured: true,
                                                                    industryActivities: '',
                                                                    employeeCount: '',
                                                                    grossRevenue: '',
                                                                    needByDate: null,
                                                                    effectiveDate: null,
                                                                    limits: [],
                                                                });
                                                                resetInsuredDetails();
                                                                setInsuredDataFieldDisable(false);
                                                                setCloseButton(false);
                                                            }}
                                                            style={{
                                                                display: values.insuredName !== '' ? 'flex' : 'none',
                                                            }}
                                                            data-testid="closeIcon"
                                                        >
                                                            <img src={close} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ) : (
                                                    <div></div>
                                                )
                                            }
                                        />
                                        <div className={classes.errorMessage1}>
                                            {values.isNewInsured && (submitted ? errors.insuredName : insuredNameError)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="outlined"
                                            className={classes.searchButton}
                                            onClick={searchInsuredDetails}
                                            disabled={
                                                values.insuredName.length < 3 ||
                                                inputDisabled ||
                                                errors?.insuredName?.length === 30 ||
                                                insuredNameError.length > 0
                                            }
                                            data-testid="search-btn"
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Website"
                                    id="website"
                                    placeholder="Website"
                                    className={classes.inputField}
                                    touched={touched.website}
                                    errors={errors.website}
                                    value={values.website}
                                    name="website"
                                    required={!insuredDataFieldDisable}
                                    disabled={fieldsDisable}
                                    datatestid="website"
                                    isReadOnly={Boolean(values.website) && fieldsDisable}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Insured Street"
                                    id="physicalStreet"
                                    placeholder="Physical Street"
                                    className={classes.inputField}
                                    touched={touched.physicalStreet}
                                    errors={errors.physicalStreet}
                                    value={values.physicalStreet}
                                    name="physicalStreet"
                                    required
                                    disabled={fieldsDisable || insuredDataFieldDisable}
                                    isReadOnly={
                                        Boolean(values.physicalStreet) && (fieldsDisable || insuredDataFieldDisable)
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Insured City"
                                    id="physicalCity"
                                    placeholder="Physical City"
                                    className={classes.inputField}
                                    touched={touched.physicalCity}
                                    errors={errors.physicalCity}
                                    value={values.physicalCity}
                                    name="physicalCity"
                                    required
                                    disabled={fieldsDisable || insuredDataFieldDisable}
                                    isReadOnly={
                                        Boolean(values.physicalCity) && (fieldsDisable || insuredDataFieldDisable)
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="select"
                                    handleChange={handleChange}
                                    label="Insured State"
                                    id="physicalState"
                                    placeholder="Physical State"
                                    className={classes.selectField}
                                    touched={touched.physicalState}
                                    errors={errors.physicalState}
                                    value={values.physicalState}
                                    name="physicalState"
                                    required
                                    selectOptions={states?.states.map((item) => ({
                                        id: item.stateName,
                                        name: item.stateName,
                                    }))}
                                    disabled={fieldsDisable || insuredDataFieldDisable}
                                    isReadOnly={
                                        Boolean(values.physicalState) && (fieldsDisable || insuredDataFieldDisable)
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Insured Zip/Postal Code"
                                    id="physicalZip"
                                    placeholder="Physical Zip"
                                    className={classes.inputField}
                                    touched={touched.physicalZip}
                                    errors={errors.physicalZip}
                                    value={values.physicalZip}
                                    name="physicalZip"
                                    required
                                    disabled={fieldsDisable || insuredDataFieldDisable}
                                    isReadOnly={
                                        Boolean(values.physicalZip) && (fieldsDisable || insuredDataFieldDisable)
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="select"
                                    handleChange={handleChange}
                                    label="Industry/Business Activities"
                                    id="industryActivities"
                                    placeholder="Industry/Business Activities"
                                    className={classes.selectField}
                                    touched={touched.industryActivities}
                                    errors={errors.industryActivities}
                                    value={values.industryActivities}
                                    name="industryActivities"
                                    required
                                    selectOptions={industryActivities?.industry?.map((item) => ({
                                        id: item.industryCode + '#' + item.industryName,
                                        name: item.industryName,
                                    }))}
                                    disabled={fieldsDisable}
                                    title={values.industryActivities.split('#')[1]}
                                    datatestid="industryActivities"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Gross Annual Revenue"
                                    id="grossRevenue"
                                    placeholder="Gross Annual Revenue"
                                    className={classes.inputField}
                                    touched={touched.grossRevenue}
                                    errors={errors.grossRevenue}
                                    value={values.grossRevenue}
                                    name="grossRevenue"
                                    required
                                    disabled={fieldsDisable}
                                    onBlur={() => {
                                        grossRevenueValueFormat(values.grossRevenue);
                                    }}
                                    focusChange={focusChange}
                                    datatestid="grossRevenue"
                                />
                                <div className={classes.errorMessage1}>
                                    {!isGRFormatted && grossRevenueError}
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="Estimated Number of Employees"
                                    id="employeeCount"
                                    placeholder="Estimated Number of Employees"
                                    className={classes.inputField}
                                    touched={touched.employeeCount}
                                    errors={errors.employeeCount}
                                    value={values.employeeCount}
                                    name="employeeCount"
                                    required
                                    disabled={fieldsDisable}
                                    datatestid="estimatedNo"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="date"
                                    handleChange={handleChange}
                                    label="Need by Date"
                                    id="needByDate"
                                    placeholder="Need by Date"
                                    className={classes.datePicker}
                                    touched={touched.needByDate}
                                    errors={errors.needByDate}
                                    value={values.needByDate}
                                    name="needByDate"
                                    required
                                    disabled={fieldsDisable}
                                    datatestid="needByDate"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FormComponent
                                    component="date"
                                    handleChange={handleChange}
                                    label="Effective Date"
                                    id="effectiveDate"
                                    placeholder="Effective Date"
                                    className={classes.datePicker}
                                    touched={touched.effectiveDate}
                                    errors={errors.effectiveDate}
                                    value={values.effectiveDate}
                                    name="effectiveDate"
                                    required
                                    disabled={fieldsDisable}
                                />
                            </Grid>
                            <Grid item xs={5} />
                            <Grid item xs={5}>
                                <FormLabel required className={classes.limits}>
                                    Limits
                                </FormLabel>
                                <Grid direction="column" container>
                                    {requestedLimits?.map((item) => (
                                        <Grid key={item.id}>
                                            <Grid spacing={2}>
                                                <Checkbox
                                                    inputProps={{
                                                        'aria-checked': values.limits.some((element: string) => {
                                                            return Number(element) === Number(item.value);
                                                        }),
                                                        // eslint-disable-next-line max-len
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        //@ts-ignore
                                                        'data-testid': 'checkbox',
                                                    }}
                                                    name="limits"
                                                    key={item.id}
                                                    value={item.value}
                                                    checked={values.limits.some((element: string) => {
                                                        return Number(element) === Number(item.value);
                                                    })}
                                                    onChange={(event) => {
                                                        handleLimitChange(event);
                                                    }}
                                                    color="primary"
                                                    disabled={fieldsDisable}
                                                />
                                                <span className={classes.textSize}>
                                                    {item.name.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                                {errors.limits && touched.limits ? (
                                    <div className={classes.errorMessageLimit}>{errors.limits}</div>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12} className={classes.securityHeading}>
                                        Security Requirements
                                    </Grid>
                                    <Grid item>
                                        {securityRequirements.map((req: ISecurityReq, index) => (
                                            <Grid
                                                container
                                                xs={11}
                                                spacing={2}
                                                key={index}
                                                className={classes.securityContainer}
                                            >
                                                <Grid item xs={8}>
                                                    {req.label}
                                                    {req?.tooltipContent && (
                                                        <Tooltip title={req.tooltipContent} placement="top-start">
                                                            <IconButton className={classes.tooltip}>
                                                                <InfoOutlinedIcon className={classes.tooltipIcon} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {req.valueLabel === 'Estimated_Number_of_PII' ? (
                                                        <FormComponent
                                                            component="input"
                                                            handleChange={handleChange}
                                                            id="Estimated_Number_of_PII"
                                                            placeholder="Estimated Number of PII"
                                                            className={classes.inputField}
                                                            touched={touched.Estimated_Number_of_PII}
                                                            errors={errors.Estimated_Number_of_PII}
                                                            value={values.Estimated_Number_of_PII}
                                                            name="Estimated_Number_of_PII"
                                                            disabled={fieldsDisable}
                                                            datatestid="Estimated_Number_of_PII"
                                                        />
                                                    ) : (
                                                        <FormComponent
                                                            required
                                                            value={(values as any)[req.valueLabel]}
                                                            name={req.valueLabel}
                                                            component="radio"
                                                            id={req.valueLabel}
                                                            handleChange={(e) => {
                                                                handleChange(e);
                                                            }}
                                                            radioOptions={radiovalues}
                                                            disabled={fieldsDisable}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.errorMessageSubmit}>{submitError}</div>
                </Form>
            </Grid>
            <Banner text={disclaimerText} />
            <Grid container xs={12} direction="row" className={classes.submitContainer}>
                <Grid item xs={1}>
                    <Button variant="outlined" className={classes.cancelButton} onClick={backToQuotesClick}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={2}>
                    <Button
                        className={classes.submitButton}
                        onClick={() => {
                            props.handleSubmit();
                            handleSubmitValidation();
                            validateError();
                            setSubmitted(true);
                        }}
                        type="submit"
                        data-testid="submit-btn"
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
            <WarningModal
                title={
                    successDetails.referralStatus === 'Referred' ? createQuoteReferredMessage : createQuoteSubmitMessage
                }
                buttonVariant="text"
                onCancel={() => {
                    setSuccessModalStatus(false);
                    resetQuote();
                    resetInsuredDetails();
                    backToViewPage({
                        id: successDetails.id || '',
                        limit_type: 'EVO_Limit_1__c',
                    });
                }}
                confirmModalStatus={successModalStatus}
                cancelButtonTitle="Done"
            />
            <ConfirmationModal
                removeCloseButton={false}
                maxWidth={'md'}
                title="Request Quote"
                content={<SubmitConfirmationPage data={radioOptionSelected} />}
                modalStatus={confirmationModalStatus}
                setModalStatus={SetConfirmationModalStatus}
                className={classes.modal}
                customHeader={classes.modalHeader}
                footerContent={
                    <>
                        <Button className={classes.nextButton} onClick={submitPage}>
                            Next
                        </Button>
                    </>
                }
            />
            <ConfirmationModal
                removeCloseButton={true}
                maxWidth={'sm'}
                content={
                    // eslint-disable-next-line max-len
                    'This risk falls outside of our Underwriting appetite because they do not meet the criteria in the Schedule of Information. Please reach out to Asmith@evolvemga.com if you would like us to consider further.'
                }
                modalStatus={errorModalStatus}
                setModalStatus={setErrorModalStatus}
                className={classes.modal}
            />
            <InsuredListModal
                title={`Search Result for '${values.insuredName}'`}
                confirmModalStatus={insuredModalStatus}
                role="insured-list"
                data={insuredDetails && insuredDetails}
                onAdd={onInsuredNameAdd}
                industry={industryActivities}
                agentCode={values.agentCode}
                onCancel={() => {
                    setInsuredModalStatus(false);
                    resetInsured();
                    setFieldsDisable(true);
                }}
                buttonVariant="text"
            />
            <WarningModal
                message="No results. Please complete the remaining fields and submit for processing"
                buttonVariant="text"
                onCancel={() => {
                    setNoInsuredFoundModalStatus(false);
                    //noInsuredModalStatus(false);
                    setInputDisabled(true);
                    setCloseButton(true);
                    resetInsured();
                    setValues({
                        ...values,
                        physicalStreet: '',
                        website: '',
                        physicalCity: '',
                        physicalZip: '',
                        physicalState: '',
                        isNewInsured: true,
                        industryActivities: '',
                        employeeCount: '',
                        grossRevenue: '',
                        needByDate: null,
                        limits: [],
                    });
                }}
                confirmModalStatus={noInsuredFoundModalStatus}
                cancelButtonTitle="Ok"
                noData
                role="no-data-modal"
            />
        </Grid>
    );
};

const CreateNewQuoteForm = withFormik<ICreateQuoteProps, ICreateQuoteFormValues>({
    mapPropsToValues: () => {
        return {
            agentCode: '',
            insuredName: '',
            website: null,
            physicalStreet: '',
            physicalCity: '',
            physicalState: '',
            physicalZip: '',
            industryActivities: '',
            employeeCount: '',
            grossRevenue: '',
            needByDate: null,
            cyberClaim: '2',
            limits: [],
            insuredId: '',
            isNewInsured: true,
            brokerCode: '',
            effectiveDate: null,
            emailFilteringSolution: '',
            MFA_on_Remote_Network_Enabled: 'Unknown',
            MFA_on_Email_Enabled: 'Unknown',
            MFA_for_Privileged_Accounts: 'Unknown',
            Offline_backups: 'Unknown',
            EDR_Fully_Deployed: 'Unknown',
            Network_Monitoring: 'Unknown',
            Annual_Phishing_Training: 'Unknown',
            Email_Filtering: 'Unknown',
            Update_Critical_Patches: 'Unknown',
            Anti_virus_Software_and_Firewalls: 'Unknown',
            Daily_Backups_Critical_Data: 'Unknown',
            Incident_Greater_than_10k: 'Unknown',
            Legal_Action: 'Unknown',
            Regulatory_Action: 'Unknown',
            Cannabis_or_Crypto: 'Unknown',
            Penetration_Test_Last_24_mo: 'Unknown',
            Aerospace_or_Defense_Ind: 'Unknown',
            Grantmaking_or_giving_foundation: 'Unknown',
            Provide_Technology_Services: 'Unknown',
            Dual_Authorization_Change: 'Unknown',
            Dual_Authorization_Request: 'Unknown',
            Estimated_Number_of_PII: 0,
            EOL_or_EOS_Software: 'Unknown',
        };
    },
    validate: (values, props) => {
        const errors: any = {};
        if (!values?.limits?.length) {
            errors.limits = errorMessages().required;
        } else if (values?.limits?.length > 4) {
            errors.limits = errorMessages('4').maxLimitSelect;
        }
        if (values.grossRevenue == '$0.00' || !values.grossRevenue) {
            errors.grossRevenue = errorMessages().grossRevenue;
        }
        if (!props.insuredDetails?.records.length && !values.website) {
            errors.website = errorMessages().required;
        }
        if (values.isNewInsured) {
            if (values.physicalStreet && !values.physicalStreet.match(/^(?!_)(?!.*_$)(?!.*_)[a-zA-z0-9\s]+$/)) {
                errors.physicalStreet = 'Please use alpha numeric characters';
            }
            if (values.physicalCity && !values.physicalCity.match(/^(?!_)(?!.*_$)(?!.*_)[a-zA-z\s]+$/)) {
                errors.physicalCity = 'Please use alphabet characters';
            }
            if (values.insuredName && !values.insuredName.match(/^(?!_)(?!.*_$)(?!.*_)[a-zA-z0-9\s]+$/)) {
                errors.insuredName =
                    'Please use alphabet and numerical characters only.Special characters will not work.';
            }
        }
        return errors;
    },
    validationSchema: Yup.object({
        insuredName: Yup.string().required('Required').max(255, 'Maximum limit exceeded'),
        website: Yup.string()
            .matches(
                // eslint-disable-next-line no-useless-escape
                /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                errorMessages().validUrl
            )
            .max(255, errorMessages().maxLimit)
            .nullable(),
        physicalStreet: Yup.string().required(errorMessages().required).max(150, errorMessages().maxLimit),
        physicalCity: Yup.string()
            .required(errorMessages().required)
            .matches(/^(?!_)(?!.*_$)(?!.*_)[a-zA-Z\s]+$/, errorMessages().validCity)
            .max(30, errorMessages().maxLimit),
        physicalState: Yup.string().trim().required(errorMessages().required),
        physicalZip: Yup.string()
            .required(errorMessages().required)
            .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)+$/, errorMessages().zipFormatError),
        industryActivities: Yup.string().trim().required(errorMessages().required),
        employeeCount: Yup.string()
            .required(errorMessages().required)
            .max(8, errorMessages('8').valueLength)
            .matches(/^[0-9]+$/, errorMessages().wholeNumbersOnly),
        grossRevenue: Yup.string().max(18, errorMessages('18').valueLength).required(errorMessages().required),
        needByDate: Yup.string().nullable().required(errorMessages().required),
        effectiveDate: Yup.string().nullable().required(errorMessages().required),
        Estimated_Number_of_PII: Yup.number()
            .integer()
            .typeError(errorMessages().validNumber)
            .max(piiLimit, errorMessages().maxLimit),
    }),
    enableReinitialize: true,
    handleSubmit: () => {
        //formik handleSubmit
    },
})(CreateQuoteForm);

const mapStateToProps = (state: IReduxState) => ({
    onCreateQuoteSuccess: state.quote.onCreateQuoteSuccess,
    insuredDetails: state.quote.insuredDetails,
    insuredModalPopUp: state.quote.insuredModalPopUp,
    brokerDetails: state.quote.brokerDetails,
    industryActivities: state.quote.industryActivities,
    states: state.quote.states,
    successDetails: state.quote.sucessDetails,
    //noInsuredFoundModalStatus: state.quote.noInsuredFoundModalStatus
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    createQuote: (data: ICreateQuoteRequest) => dispatch(createQuoteAction(data)),
    resetQuote: () => dispatch(resetQuoteSuccessAction()),
    searchInsured: (data: ISearchInsured) => dispatch(searchInsuredAction(data)),
    resetInsured: () => dispatch(resetInsuredAction()),
    getIndustryActivities: () => dispatch(getIndustryActivitiesAction()),
    getStates: () => dispatch(getStatesAction()),
    resetInsuredDetails: () => dispatch(insuredResetAction()),
    //noInsuredModalStatus: (data:boolean)=>dispatch(noInsuredModalStatusAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewQuoteForm);
