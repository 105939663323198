import { call, put } from 'redux-saga/effects';
import { getRenewalsListAPI, viewRenewalsAPI } from '../end-points/renewals';
import { ISuccessResponse } from '../interface/common';
import {
    getRenewalsAction,
    getRenewalsSuccessAction,
    getViewRenewalDetailsAction,
    getViewRenewalDetailsSuccessAction,
} from '../modules/renewals';

export function* getRenewalsSaga(action: ReturnType<typeof getRenewalsAction>) {
    try {
        const renewalsResponse: ISuccessResponse<any> = yield call(getRenewalsListAPI, action.value);
        yield put(getRenewalsSuccessAction(renewalsResponse.data.compositeResponse[0].body.records));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}

export function* viewRenewalsSaga(action: ReturnType<typeof getViewRenewalDetailsAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(viewRenewalsAPI, action.value);
        yield put(getViewRenewalDetailsSuccessAction(response.data.records[0]));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
