import React from 'react';
import { Button, Grid } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { IReduxState } from '../../modules/store';
import { backTo } from '../../assets/icons';
import HelpEmail from '../help-email/help-email';
import { IBrokerDetails } from '../../interface/quotes/getBrokerDetails';
import ClaimsTable from '../claims/claims-table';
import { IGetClaimsResponse } from '../../interface/claims/getClaimsResponse';

interface IViewClaimProps {
    backToQuotesClick: () => void;
    viewDetails?: {
        broker: {
            code: string | null;
            name: string | null;
        };
        insuredName: string | null;
        accountManager: {
            name: string | null;
            email: string | null;
        }
    };
    brokerDetails?: IBrokerDetails;
    tableRef: any;
    claimsData?: IGetClaimsResponse;
    policy?: boolean;
    defaultSearch: string;
}

const viewClaims = (props: IViewClaimProps) => {
    const {
        backToQuotesClick,
        viewDetails,
        claimsData,
        tableRef,
        policy,
        defaultSearch
    } = props;
    const { classes } = useStyles();

    return (
        <Grid container className={classes.quotesContainer}>
            <Grid container xs={12} className={classes.container}>
                <Grid item xs={9} className={classes.backToQuotesDiv}>
                    <img src={backTo} />
                    <Button
                        className={classes.backToQuotesButton}
                        onClick={backToQuotesClick}
                        role='button-claims'
                    >
                        {policy ? 'Back to policies' : 'Back to renewals'}
                    </Button>
                </Grid>
                <Grid xs={2}>
                    <HelpEmail
                        emailDetails={{
                            brokerName:
                                (viewDetails?.broker.code || '') +
                                    ': ' + viewDetails?.broker.name || '',
                            subject: `Policy: ${viewDetails?.insuredName}`,
                            toEmail: {
                                email: viewDetails?.accountManager.email || undefined,
                                name: viewDetails?.accountManager.name || undefined,
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container xs={12} className={classes.mainGrid}></Grid>
            <Grid container xs={12} className={classes.viewContentGrid}>
                <Grid item xs={12} className={classes.claimsHeading}>
                    Claims History
                </Grid>
                <Grid item xs={12} className={classes.formContainer}>
                    <ClaimsTable
                        claims={claimsData?.claims || []}
                        tableRef={tableRef}
                        defaultSearch={defaultSearch}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: IReduxState) => ({
    brokerDetails: state.quote.brokerDetails,
});

export default connect(mapStateToProps, null)(viewClaims);
