import { makeStyles } from 'tss-react/mui';
import theme from '../../constants/theme';

export const useStyles = makeStyles()({
    errorMessage: {
        color: theme.palette.error.main,
        fontSize: '14px',
    },
    labelField: {
        color: '#4C5159',
        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.main,
        },
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Nunito Sans',
    },
    radioField: {
        color: theme.palette.text.primary,
        fontSize: '14px',
    },
    field: {
        fontSize: '14px',
        '& .MuiInputBase-input': {
            fontSize: '14px',
            color: theme.palette.text.primary,
            fontWeight: 500,
        },
        '&.Mui-disabled': {
            color: theme.palette.text.primary,
            backgroundColor: '#F6F6F6'
        },
        '&.MuiInputBase-readOnly': {
            backgroundColor: '#F6F6F6'
        },
    },
    fieldDisabled: {
        fontSize: '14px',
        '& .MuiInputBase-input': {
            fontSize: '14px',
        },
        '&.Mui-disabled': {
            color: 'red !important',
            backgroundColor: '#F6F6F6'
        },
    },
    menuProps: {
        '&.MuiPaper-root': {
            backgroundColor: theme.palette.background.default,
            maxWidth: 140,
        },
    },
    tooltip: {
        height: '1px',
        '&.MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
            height: '0em',
        },
        '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px',
        },
    },
    tooltipIcon: {
        height: '18px !important',
    },
    menuItem: {
        fontSize: '14px',
    },
    selectField: {
        fontSize: '14px',
        height: '40px',
        width: '100%',
        margin: '10px 0px 10px 0px',
        '& .MuiSelect-select': {
            padding: '0px 0px 0px 0px',
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiSelect-select:not([multiple]) option': {
            backgroundColor: theme.palette.background.default,
        },
    },
    labelContent: {
        fontWeight: 100,
        fontSize: '11px'
    },
    labelDescription: {
        fontSize: '12px',
        fontWeight: 'normal',
        marginBottom: '-7px'
    },
    descriptionDiv: {
        float:'left'
    }
});
