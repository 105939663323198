import { all, takeLatest } from 'redux-saga/effects';
import { GET_CLAIMS_LIST } from '../modules/claims';
import { GET_POLICIES_LIST, GET_VIEW_POLICY_DETAILS } from '../modules/policies';
import {
    CREATE_QUOTE,
    GET_BROKER_DETAIL,
    GET_FILES,
    GET_QUOTES_LIST,
    GET_STATES,
    GET_VIEW_QUOTE_DETAILS,
    INDUSTRY_ACTIVITIES,
    SEARCH_INSURED,
    SEND_EMAIL,
    UPDATE_QUOTE,
} from '../modules/quotes';
import { GET_RENEWALS_LIST, GET_VIEW_RENEWAL_DETAILS } from '../modules/renewals';
import { getClaimsSaga } from './claims';
import { getPoliciesSaga, viewPolicySaga } from './policies';
import {
    createQuoteSaga,
    getBrokerDetailsSaga,
    getFilesSaga,
    getIndustryActivitiesSaga,
    getQuotesSaga,
    getStatesSaga,
    searchInsuredSaga,
    sendEmailSaga,
    updateQuoteSaga,
    viewQuoteSaga,
} from './quotes';
import { getRenewalsSaga, viewRenewalsSaga } from './renewals';

export function* rootSaga() {
    yield all([
        takeLatest(CREATE_QUOTE, createQuoteSaga),
        takeLatest(SEARCH_INSURED, searchInsuredSaga),
        takeLatest(GET_QUOTES_LIST, getQuotesSaga),
        takeLatest(GET_BROKER_DETAIL, getBrokerDetailsSaga),
        takeLatest(INDUSTRY_ACTIVITIES, getIndustryActivitiesSaga),
        takeLatest(GET_STATES, getStatesSaga),
        takeLatest(GET_VIEW_QUOTE_DETAILS, viewQuoteSaga),
        takeLatest(GET_POLICIES_LIST, getPoliciesSaga),
        takeLatest(UPDATE_QUOTE, updateQuoteSaga),
        takeLatest(GET_VIEW_POLICY_DETAILS, viewPolicySaga),
        takeLatest(GET_RENEWALS_LIST, getRenewalsSaga),
        takeLatest(GET_VIEW_RENEWAL_DETAILS, viewRenewalsSaga),
        takeLatest(GET_FILES, getFilesSaga),
        takeLatest(SEND_EMAIL, sendEmailSaga),
        takeLatest(GET_CLAIMS_LIST, getClaimsSaga),
    ]);
}
