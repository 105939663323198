import React, { Dispatch, ReactElement, useEffect, useRef, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useStyles } from './styles';
import RenewalsTable from './renewals-table';
import { getFilesAction } from '../../modules/quotes';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IReduxState } from '../../modules/store';
import { IBrokerDetails, IBrokerRecords } from '../../interface/quotes/getBrokerDetails';
import { IRenewals } from '../../interface/renewals/renewalsTable';
import { getRenewalsAction, getViewRenewalDetailsAction } from '../../modules/renewals';
import ViewRenewalsForm from './view-renewals';
import { IViewQuoteById } from '../../interface/quotes/viewQuote';
import ViewClaims from '../claims/view-claims';
import { getClaimsAction } from '../../modules/claims';
import { IViewRenewalDetails } from '../../interface/renewals/viewRenewals';
import { IGetClaimsRequest } from '../../interface/claims/getClaimsRequest';
import { renewalsList } from '../../constants/tabLists';
interface IRenewalsProps {
    getRenewals: (data: string[]) => void;
    renewals: IRenewals[];
    brokerDetails?: IBrokerDetails;
    getViewRenewalDetails: (data: IViewQuoteById) => void;
    getFiles: (data: string) => void;
    claimsData: any;
    getClaims: (data: IGetClaimsRequest) =>  void;
    viewRenewalDetails: IViewRenewalDetails;
}
const Renewals = (props: IRenewalsProps) => {
    type pageStatusEnum = 'homePage' | 'view' | 'viewClaim';
    const {
        renewals,
        brokerDetails,
        getRenewals,
        getViewRenewalDetails,
        getFiles,
        getClaims,
        claimsData,
        viewRenewalDetails
    } = props;
    const { classes } = useStyles();
    const [currentPage, setCurrentPage] = useState<pageStatusEnum>('homePage');
    const [tabValue, setTabValue] = React.useState('all');
    const [searchValue, setSearchValue] = React.useState<string>();
    const [renewalsData, setRenewalsData] = useState<IRenewals[]>([]);

    const tableRef = useRef(null);
    const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
        setTabValue(newValue);
        if (tableRef) {
            (tableRef.current as any)?.dataManager.changeCurrentPage(0);
        }
    };
    useEffect(() => {
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getRenewals(data);
        }
    }, [brokerDetails]);

    useEffect(() => {
        if (tabValue === 'all') {
            setRenewalsData(renewals);
        } else {
            const data = renewals?.filter(
                (renewal: any) => renewal?.renewal_Status?.toUpperCase() === tabValue.toUpperCase()
            );
            setRenewalsData(data);
        }
    }, [renewals, tabValue]);
    const backToRenewalsClick = () => {
        setCurrentPage('homePage');
    };
    const viewRenewalsForm = (id?: string, limit?: string) => {
        getViewRenewalDetails({
            id: id || '',
            limit_type: limit || ''
        });
        getFiles(id || '');
        setCurrentPage('view');
    };
    const viewClaim = (id?: string, limit?: string | null, searchString?: string) =>{
        setSearchValue(searchString || '');
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getClaims({
                LossRunRequired: false,
                Brokercode: data,
            });
        }
        getViewRenewalDetails({
            id: id || '',
            limit_type: limit || ''
        });
        setCurrentPage('viewClaim');
    };
    const renderComponent = (): ReactElement => {
        let component: ReactElement = <></>;
        switch (currentPage) {
            case 'homePage':
                component = (
                    <>
                        <Grid container xs={12} className={classes.gridContainer}>
                            <Grid item xs={10} role="header" className={classes.headerName}>
                                Upcoming Renewals
                            </Grid>
                        </Grid>
                        <Grid item md={8} xs={7} className={classes.tabContainer}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                aria-label="Renewal tabs"
                                indicatorColor="primary"
                                className={classes.tabs}
                                role="renewalTabs"
                            >
                                {renewalsList.map((item) => (
                                    <Tab
                                        key={item.id}
                                        value={item.value}
                                        label={item.label}
                                        style={{ color: tabValue === item.value ? '#0394BD' : '#4C5159' }}
                                        role={`tab-${item.role}`}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                        <Grid xs={12} className={classes.tableContainer}>
                            <RenewalsTable
                                renewals={renewalsData}
                                tableRef={tableRef}
                                viewRenewals={viewRenewalsForm}
                                viewClaim={viewClaim}
                            />
                        </Grid>
                    </>
                );
                break;
            case 'view':
                component = <ViewRenewalsForm backToRenewalsClick={backToRenewalsClick} viewClaim={viewClaim}/>;
                break;
            case 'viewClaim':
                component = <ViewClaims
                    backToQuotesClick={backToRenewalsClick}
                    tableRef={tableRef}
                    claimsData={claimsData}
                    defaultSearch={searchValue || ''}
                    viewDetails={{
                        broker: {
                            code: viewRenewalDetails?.vrnA__Broker__r?.broker_Code__c ||
                                viewRenewalDetails?.vrnA__Broker__c || '',
                            name: viewRenewalDetails?.vrnA__Broker__r?.name || ''
                        },
                        insuredName: viewRenewalDetails?.vrnA__Account__r?.name || '',
                        accountManager: {
                            name: viewRenewalDetails?.vrnA__Account_Manager__r?.email || '',
                            email: viewRenewalDetails?.vrnA__Account_Manager__r?.name || ''
                        }
                    }}
                />;
        }
        return component;
    };

    return renderComponent();
};
const mapStateToProps = (state: IReduxState) => ({
    renewals: state.renewals.renewals,
    brokerDetails: state.quote.brokerDetails,
    claimsData: state.claims.claims,
    viewRenewalDetails: state.renewals.viewRenewalDetails
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getRenewals: (data: string[]) => dispatch(getRenewalsAction(data)),
    getViewRenewalDetails: (data: IViewQuoteById) => dispatch(getViewRenewalDetailsAction(data)),
    getFiles: (data: string) => dispatch(getFilesAction(data)),
    getClaims: (data: IGetClaimsRequest) => dispatch(getClaimsAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Renewals);
