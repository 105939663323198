export const requestedLimits = [
    {
        id: 1,
        name: '$500k',
        value: 500000,
    },
    {
        id: 2,
        name: '$1m',
        value: 1000000,
    },
    {
        id: 3,
        name: '$2m',
        value: 2000000,
    },
    {
        id: 4,
        name: '$3m',
        value: 3000000,
    },
    {
        id: 5,
        name: '$5m',
        value: 5000000,
    },
];