import { IBrokerDetails } from '../interface/quotes/getBrokerDetails';

export const brokerDetailsResponse: IBrokerDetails = {
    records: [
        {
            id: '0030R00001NB5XbQAL',
            accountId: '0010R00001NDuG0QAL',
            account: {
                name: 'Nevada Insurance Agency LLC',
                broker_Code__c: 'BRK-1778',
                billingAddress: {
                    city: 'San Rafael',
                    country: 'United States',
                    countryCode: 'US',
                    postalCode: '94901',
                    state: 'California',
                    stateCode: 'CA',
                    street: '1752 Lincoln Avenue',
                },
                broker_Producer_Team__r: {
                    name: 'Nevada Insurance Agency LLC - 12/7/2021',
                    attributes: {
                        type: '',
                        url: '',
                    },
                    designated_Producer_User__r: {
                        name: 'paneved',
                        email: 'panived@ev.com',
                    },
                },
                territory__c: 'northwest',
            },
        },
        {
            id: '0030R5XbQAL',
            accountId: '0010R00001N',
            account: {
                name: 'AYM Agency LLC',
                broker_Code__c: 'BRK-10000',
                billingAddress: {
                    city: 'Rafael',
                    country: 'United States',
                    countryCode: 'US',
                    postalCode: '94901',
                    state: 'California',
                    stateCode: 'CA',
                    street: '1752 Lincoln Avenue',
                },
                broker_Producer_Team__r: {
                    name: 'Nevada Insurance Agency LLC - 12/7/2021',
                    attributes: {
                        type: '',
                        url: '',
                    },
                    designated_Producer_User__r: {
                        name: 'evovva',
                        email: 'evovva@ng.com',
                    },
                },
                territory__c: 'southwest',
            },
        },
    ],
};
