import React from 'react';
import FormComponent from '../../components/form-field/form-field';
import { Grid } from '@mui/material';
import { useStyles } from './styles';
import { withFormik, FormikProps } from 'formik';
import { submitOptions } from '../../constants/submitConfirmation';
interface IFormProps {
    data?: any;
}
interface IFormValues {
    radioOption: string;
}
const SubmitConfirmationPage = (props: IFormProps & FormikProps<IFormValues>) => {
    const { handleChange, values, data } = props;
    React.useEffect(() => {
        if (values.radioOption === '1') {
            data(true);
        } else {
            data(false);
        }
    }, [values.radioOption]);
    const { classes } = useStyles();
    return (
        <Grid className={classes.textSize}>
            <p>
                Please confirm that the company(and its subsidaries) applying for this insurance meet the following
                criteria:
            </p>
            <ul>
                <li>
                    They have not experienced a cyber event in the past three years that has resulted in a direct
                    financial loss of more than $10,000.
                </li>
                <li>
                    They have not had any legal action brought or threatened against them in the last five years as a
                    direct result of a cyber event.
                </li>
                <li>
                    They have not had a regulatory action initiated against them in the last five years as a direct
                    result of a cyber event.
                </li>
                <li>
                    They are not involved in the direct supply of goods or services to the cannabis industry, not are
                    they involved directly in the use or supply of cryptocurrency.
                </li>
            </ul>
            <FormComponent
                component="radio"
                handleChange={handleChange}
                label="Schedule of Information"
                id="radioOption"
                value={values.radioOption}
                name="radioOption"
                radioOptions={submitOptions}
                className={classes.radio}
                direction="column"
                required
            />
        </Grid>
    );
};
const SubmitConfirmationPageForm = withFormik<IFormProps, IFormValues>({
    mapPropsToValues: () => {
        return {
            radioOption: '1',
        };
    },
    handleSubmit: () => {
        //formik handleSubmit
    },
})(SubmitConfirmationPage);

export default SubmitConfirmationPageForm;
