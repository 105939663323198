import { IViewRenewalDetails, IViewRenewals } from '../interface/renewals/viewRenewals';

export const viewRenewalsResponse: IViewRenewals = {
    totalSize: 1,
    done: true,
    records: [
        {
            id: '12345',
            vrnA__Account__r: {
                name: 'Oregon Flat Fee Test 1',
                billingAddress: {
                    city: 'Portland',
                    country: 'United States',
                    countryCode: 'US',
                    postalCode: '92039',
                    state: 'Pennsylvania',
                    stateCode: 'PA',
                    street: '1234 Main St',
                },
                recordTypeId: '0126g0000015eJiAAI',
                website: 'www.evolvemga.com',
                broker_Commission__c: 15,
                industry__c: 'a3Y6g000002BljmEAC',
                industry__r: {
                    name: 'CN',
                    industryName: '',
                },
                vrnA__MGA_Broker_Checkbox__c: false,
                retail_Broker__c: '0010R00001NDuG0QAL',
                retail_Broker__r: {
                    name: 'Nevada Insurance Agency LLC',
                },
            },
            vrnA__Status__c: 'New',
            endorsements__c: null,
            vrnA__Account__c: '0010R00001OLgP9QAL',
            date_App_Recieved__c: '2022-01-27',
            coverages__c: 'Cyber Crime;Media Liability',
            evO_Limit_1__c: 1000000,
            evO_Limit_2__c: 2000000,
            evO_Limit_3__c: 3000000,
            evO_Limit_4__c: null,
            option_1_Deductible__c: 10.0,
            option_2_Deductible__c: 2500,
            option_3_Deductible__c: 5000,
            option_4_Deductible__c: null,
            quoted_Base_1__c: null,
            discount_Quoted_Premium_1__c: null,
            quoted_Base_2__c: null,
            discount_Quoted_Premium_2__c: null,
            quoted_Base_3__c: null,
            discount_Quoted_Premium_3__c: null,
            quoted_Base_4__c: null,
            discount_Quoted_Premium_4__c: null,
            business_Activities__c: null,
            date_Quoted__c: '2022-01-27',
            closed_Date__c: null,
            vrnA__Broker__c: '0010R00001NDuG0QAL',
            vrnA__Total_Number_of_Employees__c: 5,
            gross_Annual_Revenue__c: 2000000.4,
            vrnA__Broker_Commission_Percent__c: 15,
            cyber_Crime_Sublimit__c: 1006,
            need_By_Date__c: null,
            vrnA__Broker__r: {
                name: 'Nevada Insurance Agency LLC',
                broker_Code__c: 'BRK-1778',
            },
            quote_Status: '',
            quote_limit: null,
            quote_limit_type: null,
            quote_Deductible: null,
            quote_Discount_Premium: null,
            quote_Duplicate: null,
            errorMessage: null,
            total_Fees__c: 300,
            vrnA__State_Municipality_Tax__c: 0,
            stamping_Fee_Amount__c: 10.98,
            surcharge_Amount__c: 73.2,
            surplus_Lines_Tax_Amount__c: 73.2,
            reputational_Harm_Sublimit__c: 'Full Limits',
            vrnA__Account_Manager__c: '0056g000004GtwjAAC',
            vrnA__Account_Manager__r: {
                name: 'test name',
                email: 'test@gmail.com',
            },
            vrnA__Expected_Close_Date__c: null,
            subjectivities__c: null,
            discount_Total_Payable_1__c: null,
            option_1_Total_Payable__c: null,
            referral_Status: '',
            option_2_Total_Payable__c: null,
            option_3_Total_Payable__c: null,
            option_4_Total_Payable__c: null,
            option_1_Quote_Reference__c: '',
            stamping_Fee_Rate__c: 0,
            surcharge_Rate__c: 0,
            surplus_Lines_Tax_Rate__c: 0,
            municipality_Tax_Rate__c: 0,
            flat_Rate__c: 190,
            total_Payable_1__c: 12,
            total_Payable_2__c: 23,
            total_Payable_3__c: 2000,
            total_Payable_4__c: 0,
            renewal_Status: 'upcoming renewal',
            renewal_Status__c: '',
            control_questions__r: {
                id: '',
                edR_Fully_Deployed__c: 'Yes',
                offline_Backups__c: 'Yes',
                anti_virus_Software_and_Firewalls__c: 'Yes',
                daily_Backups_Critical_Data__c: 'Yes',
                incident_Greater_than_10k__c: 'Yes',
                legal_Action__c: 'Yes',
                regulatory_Action__c: 'Yes',
                cannabis_or_Crypto__c: 'Yes',
                penetration_Test_Last_24_mo__c: 'Yes',
                aerospace_or_Defense_Ind__c: 'Yes',
                grantmaking_or_giving_foundation__c: 'Yes',
                provide_Technology_Services__c: 'Yes',
                dual_Authorization_Change__c: 'Yes',
                dual_Authorization_Request__c: 'Yes',
                eoL_or_EOS_Software__c: 'Yes',
                mfA_on_Email_Enabled__c: 'Yes',
                annual_Phishing_Training__c: 'Yes',
                email_Filtering__c: 'Yes',
                network_Monitoring__c: 'Yes',
                mfA_for_Privileged_Accounts__c: 'Yes',
                update_Critical_Patches__c: 'Yes',
                mfA_on_Remote_Network_Enabled__c: 'Yes',
            },
            additional_fields__r: {
                id: '',
                estimated_Number_of_PII__c: 100,
            },
            evolve_Prior_Policy_Expiration_Date__c: 'String',
            total_Payable: 'String',
            option_Deductible: 1000,
            referral_Status__c: 'String'
        },
    ],
};

export const viewRenewalsResponseUpcoming: IViewRenewalDetails = {
    id: 'a0W0R000005aHdIUAU',
    vrnA__Account__r: {
        name: 'Oregon Flat Fee Test 1',
        billingAddress: {
            city: 'Portland',
            country: 'United States',
            countryCode: 'US',
            postalCode: '92039',
            state: 'Pennsylvania',
            stateCode: 'PA',
            street: '1234 Main St',
        },
        recordTypeId: '0126g0000015eJiAAI',
        website: 'www.evolvemga.com',
        broker_Commission__c: 15,
        industry__c: 'a3Y6g000002BljmEAC',
        industry__r: {
            name: 'CN',
            industryName: '',
        },
        vrnA__MGA_Broker_Checkbox__c: false,
        retail_Broker__c: '0010R00001NDuG0QAL',
        retail_Broker__r: {
            name: 'Nevada Insurance Agency LLC',
        },
    },
    vrnA__Status__c: 'New',
    endorsements__c:'Media Liability;Reputational Harm Sublimit',
    vrnA__Account__c: '0010R00001OLgP9QAL',
    date_App_Recieved__c: '2022-01-27',
    coverages__c: 'Cyber Crime;Media Liability',
    evO_Limit_1__c: 1000000,
    evO_Limit_2__c: 2000000,
    evO_Limit_3__c: 3000000,
    evO_Limit_4__c: null,
    option_1_Deductible__c: 10.0,
    option_2_Deductible__c: 2500,
    option_3_Deductible__c: 5000,
    option_4_Deductible__c: null,
    quoted_Base_1__c: null,
    discount_Quoted_Premium_1__c: null,
    quoted_Base_2__c: null,
    discount_Quoted_Premium_2__c: null,
    quoted_Base_3__c: null,
    discount_Quoted_Premium_3__c: null,
    quoted_Base_4__c: null,
    discount_Quoted_Premium_4__c: null,
    business_Activities__c: null,
    date_Quoted__c: '2022-01-27',
    closed_Date__c: null,
    vrnA__Broker__c: '0010R00001NDuG0QAL',
    vrnA__Total_Number_of_Employees__c: 5,
    gross_Annual_Revenue__c: 2000000.4,
    vrnA__Broker_Commission_Percent__c: 15,
    cyber_Crime_Sublimit__c: 1006,
    need_By_Date__c: null,
    vrnA__Broker__r: {
        name: 'Nevada Insurance Agency LLC',
        broker_Code__c: 'BRK-1778',
    },
    quote_Status: '',
    quote_limit: null,
    quote_limit_type: null,
    quote_Deductible: null,
    quote_Discount_Premium: null,
    quote_Duplicate: null,
    errorMessage: null,
    total_Fees__c: 300,
    vrnA__State_Municipality_Tax__c: 0,
    stamping_Fee_Amount__c: 10.98,
    surcharge_Amount__c: 73.2,
    surplus_Lines_Tax_Amount__c: 73.2,
    reputational_Harm_Sublimit__c: 'Full Limits',
    vrnA__Account_Manager__c: '0056g000004GtwjAAC',
    vrnA__Account_Manager__r: {
        name: 'test name',
        email: 'test@gmail.com',
    },
    vrnA__Expected_Close_Date__c: null,
    subjectivities__c: null,
    discount_Total_Payable_1__c: null,
    option_1_Total_Payable__c: null,
    referral_Status: '',
    option_2_Total_Payable__c: null,
    option_3_Total_Payable__c: null,
    option_4_Total_Payable__c: null,
    option_1_Quote_Reference__c: '',
    stamping_Fee_Rate__c: 0,
    surcharge_Rate__c: 0,
    surplus_Lines_Tax_Rate__c: 0,
    municipality_Tax_Rate__c: 0,
    flat_Rate__c: 190,
    total_Payable_1__c: 12,
    total_Payable_2__c: 23,
    total_Payable_3__c: 2000,
    total_Payable_4__c: 0,
    renewal_Status: 'upcoming renewal',
    renewal_Status__c: '',
    control_questions__r: {
        id: '',
        edR_Fully_Deployed__c: 'Yes',
        offline_Backups__c: 'Yes',
        anti_virus_Software_and_Firewalls__c: 'Yes',
        daily_Backups_Critical_Data__c: 'Yes',
        incident_Greater_than_10k__c: 'Yes',
        legal_Action__c: 'Yes',
        regulatory_Action__c: 'Yes',
        cannabis_or_Crypto__c: 'Yes',
        penetration_Test_Last_24_mo__c: 'Yes',
        aerospace_or_Defense_Ind__c: 'Yes',
        grantmaking_or_giving_foundation__c: 'Yes',
        provide_Technology_Services__c: 'Yes',
        dual_Authorization_Change__c: 'Yes',
        dual_Authorization_Request__c: 'Yes',
        eoL_or_EOS_Software__c: 'Yes',
        mfA_on_Email_Enabled__c: 'Yes',
        annual_Phishing_Training__c: 'Yes',
        email_Filtering__c: 'Yes',
        network_Monitoring__c: 'Yes',
        mfA_for_Privileged_Accounts__c: 'Yes',
        update_Critical_Patches__c: 'Yes',
        mfA_on_Remote_Network_Enabled__c: 'Yes',
    },
    additional_fields__r: {
        id: '',
        estimated_Number_of_PII__c: 100,
    },
    evolve_Prior_Policy_Expiration_Date__c: 'String',
    total_Payable: 'String',
    option_Deductible: 1000,
    referral_Status__c: 'String'
};

export const viewRenewalsResponseExpired: IViewRenewalDetails = {
    id: 'a0W0R000005aHdIUAU',
    vrnA__Account__r: {
        name: 'Oregon Flat Fee Test 1',
        billingAddress: {
            city: 'Portland',
            country: 'United States',
            countryCode: 'US',
            postalCode: '92039',
            state: 'Pennsylvania',
            stateCode: 'PA',
            street: '1234 Main St',
        },
        recordTypeId: '0126g0000015eJiAAI',
        website: 'www.evolvemga.com',
        broker_Commission__c: 15,
        industry__c: 'a3Y6g000002BljmEAC',
        industry__r: {
            name: 'CN',
            industryName: '',
        },
        vrnA__MGA_Broker_Checkbox__c: false,
        retail_Broker__c: '0010R00001NDuG0QAL',
        retail_Broker__r: {
            name: 'Nevada Insurance Agency LLC',
        },
    },
    vrnA__Status__c: 'New',
    endorsements__c: null,
    vrnA__Account__c: '0010R00001OLgP9QAL',
    date_App_Recieved__c: '2022-01-27',
    coverages__c: 'Cyber Crime;Media Liability',
    evO_Limit_1__c: 1000000,
    evO_Limit_2__c: 2000000,
    evO_Limit_3__c: 3000000,
    evO_Limit_4__c: null,
    option_1_Deductible__c: 10.0,
    option_2_Deductible__c: 2500,
    option_3_Deductible__c: 5000,
    option_4_Deductible__c: null,
    quoted_Base_1__c: null,
    discount_Quoted_Premium_1__c: null,
    quoted_Base_2__c: null,
    discount_Quoted_Premium_2__c: null,
    quoted_Base_3__c: null,
    discount_Quoted_Premium_3__c: null,
    quoted_Base_4__c: null,
    discount_Quoted_Premium_4__c: null,
    business_Activities__c: null,
    date_Quoted__c: '2022-01-27',
    closed_Date__c: null,
    vrnA__Broker__c: '0010R00001NDuG0QAL',
    vrnA__Total_Number_of_Employees__c: 5,
    gross_Annual_Revenue__c: 2000000.4,
    vrnA__Broker_Commission_Percent__c: 15,
    cyber_Crime_Sublimit__c: 1006,
    need_By_Date__c: null,
    vrnA__Broker__r: {
        name: 'Nevada Insurance Agency LLC'
    },
    quote_Status: '',
    quote_limit: null,
    quote_limit_type: null,
    quote_Deductible: null,
    quote_Discount_Premium: null,
    quote_Duplicate: null,
    errorMessage: null,
    total_Fees__c: 300,
    vrnA__State_Municipality_Tax__c: 0,
    stamping_Fee_Amount__c: 10.98,
    surcharge_Amount__c: 73.2,
    surplus_Lines_Tax_Amount__c: 73.2,
    reputational_Harm_Sublimit__c: 'Full Limits',
    vrnA__Account_Manager__c: '0056g000004GtwjAAC',
    vrnA__Account_Manager__r: {
        name: 'test name',
        email: 'test@gmail.com',
    },
    vrnA__Expected_Close_Date__c: null,
    subjectivities__c: null,
    discount_Total_Payable_1__c: null,
    option_1_Total_Payable__c: null,
    referral_Status: '',
    option_2_Total_Payable__c: null,
    option_3_Total_Payable__c: null,
    option_4_Total_Payable__c: null,
    option_1_Quote_Reference__c: '',
    stamping_Fee_Rate__c: 0,
    surcharge_Rate__c: 0,
    surplus_Lines_Tax_Rate__c: 0,
    municipality_Tax_Rate__c: 0,
    flat_Rate__c: 190,
    total_Payable_1__c: 12,
    total_Payable_2__c: 23,
    total_Payable_3__c: 2000,
    total_Payable_4__c: 0,
    renewal_Status: 'expiring Renewal',
    renewal_Status__c: '',
    control_questions__r: {
        id: '',
        edR_Fully_Deployed__c: 'Yes',
        offline_Backups__c: 'Yes',
        anti_virus_Software_and_Firewalls__c: 'Yes',
        daily_Backups_Critical_Data__c: 'Yes',
        incident_Greater_than_10k__c: 'Yes',
        legal_Action__c: 'Yes',
        regulatory_Action__c: 'Yes',
        cannabis_or_Crypto__c: 'Yes',
        penetration_Test_Last_24_mo__c: 'Yes',
        aerospace_or_Defense_Ind__c: 'Yes',
        grantmaking_or_giving_foundation__c: 'Yes',
        provide_Technology_Services__c: 'Yes',
        dual_Authorization_Change__c: 'Yes',
        dual_Authorization_Request__c: 'Yes',
        eoL_or_EOS_Software__c: 'Yes',
        mfA_on_Email_Enabled__c: 'Yes',
        annual_Phishing_Training__c: 'Yes',
        email_Filtering__c: 'Yes',
        network_Monitoring__c: 'Yes',
        mfA_for_Privileged_Accounts__c: 'Yes',
        update_Critical_Patches__c: 'Yes',
        mfA_on_Remote_Network_Enabled__c: 'Yes',
    },
    additional_fields__r: {
        id: '',
        estimated_Number_of_PII__c: 100,
    },
    evolve_Prior_Policy_Expiration_Date__c: 'String',
    total_Payable: 'String',
    option_Deductible: 1000,
    referral_Status__c: 'String'
};

export const viewRenewalsResponsePolicyExpired: IViewRenewalDetails = {
    id: 'a0W0R000005aHdIUAU',
    vrnA__Account__r: {
        name: 'Oregon Flat Fee Test 1',
        billingAddress: {
            city: 'Portland',
            country: 'United States',
            countryCode: 'US',
            postalCode: '92039',
            state: 'Pennsylvania',
            stateCode: 'PA',
            street: '1234 Main St',
        },
        recordTypeId: '0126g0000015eJiAAI',
        website: 'www.evolvemga.com',
        broker_Commission__c: 15,
        industry__c: 'a3Y6g000002BljmEAC',
        industry__r: {
            name: 'CN',
            industryName: '',
        },
        vrnA__MGA_Broker_Checkbox__c: false,
        retail_Broker__c: '0010R00001NDuG0QAL',
        retail_Broker__r: {
            name: 'Nevada Insurance Agency LLC',
        },
    },
    vrnA__Status__c: 'New',
    endorsements__c: null,
    vrnA__Account__c: '0010R00001OLgP9QAL',
    date_App_Recieved__c: '2022-01-27',
    coverages__c: 'Cyber Crime;Media Liability',
    evO_Limit_1__c: 1000000,
    evO_Limit_2__c: 2000000,
    evO_Limit_3__c: 3000000,
    evO_Limit_4__c: null,
    option_1_Deductible__c: 10.0,
    option_2_Deductible__c: 2500,
    option_3_Deductible__c: 5000,
    option_4_Deductible__c: null,
    quoted_Base_1__c: null,
    discount_Quoted_Premium_1__c: null,
    quoted_Base_2__c: null,
    discount_Quoted_Premium_2__c: null,
    quoted_Base_3__c: null,
    discount_Quoted_Premium_3__c: null,
    quoted_Base_4__c: null,
    discount_Quoted_Premium_4__c: null,
    business_Activities__c: null,
    date_Quoted__c: '2022-01-27',
    closed_Date__c: null,
    vrnA__Broker__c: null,
    vrnA__Total_Number_of_Employees__c: 5,
    gross_Annual_Revenue__c: 2000000.4,
    vrnA__Broker_Commission_Percent__c: 15,
    cyber_Crime_Sublimit__c: 1006,
    need_By_Date__c: null,
    vrnA__Broker__r: {
        name: 'Nevada Insurance Agency LLC',
    },
    quote_Status: '',
    quote_limit: null,
    quote_limit_type: null,
    quote_Deductible: null,
    quote_Discount_Premium: null,
    quote_Duplicate: null,
    errorMessage: null,
    total_Fees__c: 300,
    vrnA__State_Municipality_Tax__c: 0,
    stamping_Fee_Amount__c: 10.98,
    surcharge_Amount__c: 73.2,
    surplus_Lines_Tax_Amount__c: 73.2,
    reputational_Harm_Sublimit__c: 'Full Limits',
    vrnA__Account_Manager__c: '0056g000004GtwjAAC',
    vrnA__Account_Manager__r: {
        name: 'test name',
        email: 'test@gmail.com',
    },
    vrnA__Expected_Close_Date__c: null,
    subjectivities__c: null,
    discount_Total_Payable_1__c: null,
    option_1_Total_Payable__c: null,
    referral_Status: '',
    option_2_Total_Payable__c: null,
    option_3_Total_Payable__c: null,
    option_4_Total_Payable__c: null,
    option_1_Quote_Reference__c: '',
    stamping_Fee_Rate__c: 0,
    surcharge_Rate__c: 0,
    surplus_Lines_Tax_Rate__c: 0,
    municipality_Tax_Rate__c: 0,
    flat_Rate__c: 190,
    total_Payable_1__c: 12,
    total_Payable_2__c: 23,
    total_Payable_3__c: 2000,
    total_Payable_4__c: 0,
    renewal_Status: 'policy expired',
    renewal_Status__c: '',
    control_questions__r: {
        id: '',
        edR_Fully_Deployed__c: 'Yes',
        offline_Backups__c: 'Yes',
        anti_virus_Software_and_Firewalls__c: 'Yes',
        daily_Backups_Critical_Data__c: 'Yes',
        incident_Greater_than_10k__c: 'Yes',
        legal_Action__c: 'Yes',
        regulatory_Action__c: 'Yes',
        cannabis_or_Crypto__c: 'Yes',
        penetration_Test_Last_24_mo__c: 'Yes',
        aerospace_or_Defense_Ind__c: 'Yes',
        grantmaking_or_giving_foundation__c: 'Yes',
        provide_Technology_Services__c: 'Yes',
        dual_Authorization_Change__c: 'Yes',
        dual_Authorization_Request__c: 'Yes',
        eoL_or_EOS_Software__c: 'Yes',
        mfA_on_Email_Enabled__c: 'Yes',
        annual_Phishing_Training__c: 'Yes',
        email_Filtering__c: 'Yes',
        network_Monitoring__c: 'Yes',
        mfA_for_Privileged_Accounts__c: 'Yes',
        update_Critical_Patches__c: 'Yes',
        mfA_on_Remote_Network_Enabled__c: 'Yes',
    },
    additional_fields__r: {
        id: '',
        estimated_Number_of_PII__c: 100,
    },
    evolve_Prior_Policy_Expiration_Date__c: 'String',
    total_Payable: 'String',
    option_Deductible: 1000,
    referral_Status__c: 'String'
};

export const viewRenewalsResponseEmptyValues: IViewRenewalDetails = {
    id: 'a0W0R000005aHdIUAU',
    vrnA__Account__r: {
        name: null,
        billingAddress: {
            city: null,
            country: null,
            countryCode: null,
            postalCode: null,
            state: null,
            stateCode: null,
            street: null,
        },
        recordTypeId: null,
        website: null,
        broker_Commission__c: 15,
        industry__c: null,
        industry__r: {
            name: null,
            industryName: null,
        },
        vrnA__MGA_Broker_Checkbox__c: false,
        retail_Broker__c: null,
        retail_Broker__r: {
            name: null,
        },
    },
    vrnA__Status__c: null,
    endorsements__c: null,
    vrnA__Account__c: null,
    date_App_Recieved__c: null,
    coverages__c: null,
    evO_Limit_1__c: 1000000,
    evO_Limit_2__c: 2000000,
    evO_Limit_3__c: 3000000,
    evO_Limit_4__c: null,
    option_1_Deductible__c: 10.0,
    option_2_Deductible__c: 2500,
    option_3_Deductible__c: 5000,
    option_4_Deductible__c: null,
    quoted_Base_1__c: null,
    discount_Quoted_Premium_1__c: null,
    quoted_Base_2__c: null,
    discount_Quoted_Premium_2__c: null,
    quoted_Base_3__c: null,
    discount_Quoted_Premium_3__c: null,
    quoted_Base_4__c: null,
    discount_Quoted_Premium_4__c: null,
    business_Activities__c: null,
    date_Quoted__c: null,
    closed_Date__c: null,
    vrnA__Broker__c: null,
    vrnA__Total_Number_of_Employees__c: 5,
    gross_Annual_Revenue__c: 2000000.4,
    vrnA__Broker_Commission_Percent__c: 15,
    cyber_Crime_Sublimit__c: 1006,
    need_By_Date__c: null,
    vrnA__Broker__r: {
        name: null,
    },
    quote_Status: '',
    quote_limit: null,
    quote_limit_type: null,
    quote_Deductible: null,
    quote_Discount_Premium: null,
    quote_Duplicate: null,
    errorMessage: null,
    total_Fees__c: 300,
    vrnA__State_Municipality_Tax__c: 0,
    stamping_Fee_Amount__c: 10.98,
    surcharge_Amount__c: 73.2,
    surplus_Lines_Tax_Amount__c: 73.2,
    reputational_Harm_Sublimit__c: null,
    vrnA__Account_Manager__c: null,
    vrnA__Account_Manager__r: {
        name: null,
        email: null,
    },
    vrnA__Expected_Close_Date__c: null,
    subjectivities__c: null,
    discount_Total_Payable_1__c: null,
    option_1_Total_Payable__c: null,
    referral_Status: '',
    option_2_Total_Payable__c: null,
    option_3_Total_Payable__c: null,
    option_4_Total_Payable__c: null,
    option_1_Quote_Reference__c: '',
    stamping_Fee_Rate__c: 0,
    surcharge_Rate__c: 0,
    surplus_Lines_Tax_Rate__c: 0,
    municipality_Tax_Rate__c: 0,
    flat_Rate__c: 190,
    total_Payable_1__c: 12,
    total_Payable_2__c: 23,
    total_Payable_3__c: 2000,
    total_Payable_4__c: 0,
    renewal_Status: null,
    renewal_Status__c: '',
    control_questions__r: {
        id: '',
        edR_Fully_Deployed__c: null,
        offline_Backups__c: null,
        anti_virus_Software_and_Firewalls__c: null,
        daily_Backups_Critical_Data__c: null,
        incident_Greater_than_10k__c: null,
        legal_Action__c: null,
        regulatory_Action__c: null,
        cannabis_or_Crypto__c: null,
        penetration_Test_Last_24_mo__c: null,
        aerospace_or_Defense_Ind__c: null,
        grantmaking_or_giving_foundation__c: null,
        provide_Technology_Services__c: null,
        dual_Authorization_Change__c: null,
        dual_Authorization_Request__c: null,
        eoL_or_EOS_Software__c: null,
        mfA_on_Email_Enabled__c: null,
        annual_Phishing_Training__c: null,
        email_Filtering__c: null,
        network_Monitoring__c: null,
        mfA_for_Privileged_Accounts__c: null,
        update_Critical_Patches__c: null,
        mfA_on_Remote_Network_Enabled__c: null,
    },
    additional_fields__r: {
        id: '',
        estimated_Number_of_PII__c: 100,
    },
    evolve_Prior_Policy_Expiration_Date__c: null,
    total_Payable: null,
    option_Deductible: 1000,
    referral_Status__c: null
};