import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: any) => ({
    gridContainer: {
        padding: '20px 0px 20px 0px',
    },
    quotesContainer: {
        backgroundColor: '#FFFFFF',
        marginTop: '4px',
    },
    tooltip: {
        height: '1px',
        marginLeft: '-9px',
        '&.MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
            height: '0em',
        },
        '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px',
        },
    },
    claimsHeading: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginLeft: '85px'
    },
    tooltipIcon: {
        height: '18px !important',
    },
    insuredHeading: {
        marginBottom: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    coverageHeading: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    tabContainer: {
        padding: '0px 79px',
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#4C5159',
    },
    headerName: {
        paddingLeft: '84px',
        fontSize: '23px',
        fontWeight: 'bold',
    },
    tableContainer: {
        background: theme.palette.background.default,
        padding: '5px 79px',
        minHeight: 'calc(100vh - 271px)',
    },
    tabs: {
        '& .MuiTab-root': {
            width: '100px !important',
            minWidth: '100px !important',
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            textTransform: 'none',
            lineHeight: '19px',
        },
        '&.MuiTabs-indicator': {
            backgroundColor: 'red !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#0394BD !important',
        },
    },
    activeDiv: {
        background: 'rgba(215, 34, 45, 0.2)',
        border: '1px solid red',
        color: 'red',
        fontSize: '14px'
    },
    activeGreenDiv: {
        background: 'rgba(4, 174, 1, 0.2)',
        border: '1px solid #00A707',
        color: '#04AE01',
        fontSize: '14px'
    },
    inactiveDiv: {
        background: 'rgba(202, 125, 9, 0.2)',
        border: '1px solid #CA7D09',
        color: '#CA7D09',
        fontSize: '14px'
    },
    expiredDiv: {
        background: 'rgb(123 41 213 / 20%)',
        border: '1px solid purple',
        color: 'purple',
        fontSize: '14px'
    },
    statusDiv: {
        height: '22px',
        boxSizing: 'border-box',
        borderRadius: '100px',
        textAlign: 'center',
        width: '80px',
    },
    textAreaField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        fontSize: '15px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
        },
    },
    viewDiv: {
        padding: '10px',
        borderWidth: 'thin',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
    },
    actionsDiv: {
        color: '#0394BD',
        marginLeft: '0px !important',
        marginTop: '0px !important'
    },
    container: {
        position: 'relative',
        height: '88px',
        borderBottom: '1px solid #EAEAEA',
        marginLeft: '84px',
        marginRight: '84px',
        paddingBottom: '20px',
        paddingTop: '30px',
    },
    inputField1: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px 0px 0px 2px',
        borderColor: '#B1B1B1',
        padding: '10px 26px 10px 12px',
        fontSize: '15px',
    },
    backToQuotesButton: {
        color: '#0394BD',
        textTransform: 'none',
        fontWeight: 'bold',
        paddingTop: '0px',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    inputField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        padding: '10px 26px 10px 12px',
        fontSize: '15px',
    },
    mainGrid: {
        marginTop: '27px',
    },
    grid1: {
        marginTop: '10px',
    },
    insuredContainer: {
        padding: '39px',
        paddingLeft: '180px',
        paddingRight: '180px',
    },
    insuredContainer1: {
        paddingTop: '38px',
        paddingLeft: '180px',
        paddingRight: '200px',
    },
    backToQuotesDiv: {
        marginTop: '0px',
    },
    formContainer: {
        paddingLeft: '150px',
        paddingRight: '150px',
    },
    documentItem: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: '10px !important',
        marginRight: '10px !important',
        borderRadius: '10px',
        padding: '10px'
    },
    documentLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none !important'
    },
    viewContentGrid: {
        backgroundColor: theme.palette.background.default,
        minHeight: 'calc(100vh - 270px)',
        paddingBottom: '20px',
    },
    documentContainer: {
        paddingLeft: '20px !important',
        paddingTop: '35px !important',
    },
    securityContainer: {
        margin: '10px 30px',
        borderBottom: 'groove 1px',
    },
    securityContainerView: {
        marginTop: '10px !important',
        borderBottom: 'groove 1px',
    },
    securityMainContainer: {
        paddingLeft: '80px',
    },
    claimsHistory: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: '14px',
        paddingTop: '2px'
    }
}));
