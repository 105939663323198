import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme: any) => ({
    header: {
        width: '100%',
        display: 'flex',
        background: theme.palette.background.paper,
        '@media (max-width:959px)': {
            padding: '0 15px',
            height: '100%',
        },
        boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.05)',
        height: '75px',
        left: '0px',
        top: '0px',
        position: 'sticky',
        backgroundColor: '#FFFFFF',
        zIndex: 100,
    },
    pageContent: {
        paddingLeft: 140,
        paddingRight: 140,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 100,
            paddingRight: 100,
        },
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: 50,
            paddingRight: 50,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    logoContainer: {
        height: '32px',
        marginTop: '20px',
        '@media (max-width:900px)': {
            width: '100%',
        },
    },
    tabs: {
        height: '60px',
        marginTop: '15px',
        '& .MuiTab-root': {
            fontSize: '17px',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#0394BD !important',
        },
    },
    avatarOver: {
        position: 'relative',
        textAlign: 'right',
        display: 'inline-block',
    },
    textRight: {
        textAlign: 'end',
    },
    avatar: {
        cursor: 'pointer',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        marginTop: '15px',
        fontSize: '16px',
    },
    logoName: {
        position: 'absolute',
        color: theme.palette.text.primary,
        fontSize: '12px',
        display: 'block',
        whiteSpace: 'nowrap',
        right: 0,
        bottom: '-18px',
        '@media (max-width:1200px)': {
            right: '60px',
            maxWidth: '15ch',
        },
        '@media (max-width:1100px)': {
            right: '60px',
            maxWidth: '15ch',
        },
    },
}));
