import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { getClaimsListAPI } from '../end-points/claims';
import { ISuccessResponse } from '../interface/common';
import { getClaimsAction, getClaimsSuccessAction } from '../modules/claims';

export function* getClaimsSaga(action: ReturnType<typeof getClaimsAction>) {
    try {
        const claimsResponse: ISuccessResponse<any> = yield call(getClaimsListAPI, action.value);
        if(action.value.IsSendEmail){
            if(claimsResponse.data.outMessage){
                toast.info(claimsResponse.data.outMessage);
            } else if(claimsResponse.data.message) {
                toast.info(claimsResponse.data.message);
            }
        } else {
            yield put(getClaimsSuccessAction(claimsResponse.data));
        }
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
