/* eslint-disable no-console */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { styles } from './styles';
import Button from '../button/button';
import { submitTick } from '../../assets/icons/index';

export interface IWarningModalProps {
    title?: string;
    message?: any;
    cancelButtonTitle?: string;
    buttonVariant: 'text' | 'outlined' | 'contained';
    confirmModalStatus: boolean;
    onCancel: () => void;
    role?: string;
    noData?: boolean;
}

const WarningModal = (props: IWarningModalProps) => {
    const { classes } = styles();
    const {
        title,
        message,
        cancelButtonTitle = 'OK',
        onCancel,
        buttonVariant,
        confirmModalStatus,
        role,
        noData,
    } = props;
    return (
        <Dialog
            classes={{ paper: classes.shape }}
            aria-labelledby="customized-dialog-title"
            open={confirmModalStatus}
            maxWidth="sm"
            fullWidth
            role={role}
        >
            {!noData && <img src={submitTick} className={classes.image} />}
            <DialogTitle id="customized-dialog-title" classes={{ root: classes.warningModalTitle }}>
                <Typography className={classes.title}>{title}</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: classes.dialogContent }} className={classes.warningModalContent}>
                <Grid xs={12} data-testid="warning-title">{message}</Grid>
                <Grid xs={12}>
                    <DialogActions classes={{ root: classes.dialogActions }}>
                        <Button
                            color="primary"
                            variant={buttonVariant}
                            className={classes.button}
                            autoFocus
                            onClick={onCancel}
                            content={cancelButtonTitle}
                            role="delete-cancel-button"
                        />
                    </DialogActions>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
export default WarningModal;
