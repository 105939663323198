import axios from '../utils/interceptor';
import { handleError } from '../utils/handle-error';
import { mockPromise } from '../utils/mock-promise';
import { renewalsResponse } from '../mocks/renewals-mock';
import { viewRenewalsResponse } from '../mocks/view-renewals-mock';
import { baseUrl } from '../constants/apiConstant';
import { IViewQuoteById } from '../interface/quotes/viewQuote';

(window as any).isMockData = false;

export const getRenewalsListAPI = async (value: string[]): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const data1 = {
                Brokercode: value,
            };
            const response = await axios.post(`${baseUrl}/Policy/UpRenewals/Get/`, data1);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(renewalsResponse);
    }
};

export const viewRenewalsAPI = async (value: IViewQuoteById): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.get(`${baseUrl}/renewals/Get/${value.id}/${value.limit_type}`);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(viewRenewalsResponse);
    }
};
