import { lazy } from 'react';

export const routePath = {
    quotes: {
        index: '/evolve/quotes',
    },
    policies: {
        index: '/evolve/policies',
    },
    renewals: {
        index: '/evolve/renewals',
    },
    NoBrokerDetails: {
        index: '/evolve/noBrokerDetails',
    },
    claims: {
        index: '/evolve/claims',
    }
};

const routes = [
    {
        id: 0,
        label: 'QUOTES',
        path: routePath.quotes.index,
        element: import('../pages/quotes/quotes'),
        exact: true,
        // index: '0'
    },
    {
        id: 1,
        label: 'POLICIES',
        path: routePath.policies.index,
        element: import('../pages/policies/policies'),
        exact: true,
        // index: '1'
    },
    {
        id: 2,
        label: 'RENEWALS',
        path: routePath.renewals.index,
        element: import('../pages/renewals/renewals'),
        exact: true,
        // index: '2'
    },
    {
        id: 3,
        label: 'CLAIMS',
        path: routePath.claims.index,
        component: import('../pages/claims/claims'),
        exact: true,
    },
];
export const otherRoutes = [
    {
        path: routePath.NoBrokerDetails.index,
        element: lazy(() => import('../pages/quotes/noBrokerError')),
        // index: '3'
    },
];

export default routes;
