import { AnyAction } from 'redux';
import { IViewQuoteById } from '../interface/quotes/viewQuote';
import { IRenewals } from '../interface/renewals/renewalsTable';
import { IViewRenewalDetails } from '../interface/renewals/viewRenewals';

export interface IRenewalsReducer {
    renewals: IRenewals[];
    viewRenewalDetails?: any;
}

export const GET_RENEWALS_LIST = 'GET_RENEWALS_LIST';
export const GET_RENEWALS_LIST_SUCCESS = 'GET_RENEWALS_LIST_SUCCESS';
export const GET_VIEW_RENEWAL_DETAILS = 'GET_VIEW_RENEWAL_DETAILS';
export const GET_VIEW_RENEWAL_DETAILS_SUCCESS = 'GET_VIEW_RENEWAL_DETAILS_SUCCESS';

export const getRenewalsAction = (value: string[]) => ({
    type: GET_RENEWALS_LIST,
    value,
});
export const getRenewalsSuccessAction = (value: IRenewals) => ({
    type: GET_RENEWALS_LIST_SUCCESS,
    value,
});
export const getViewRenewalDetailsAction = (value: IViewQuoteById) => ({
    type: GET_VIEW_RENEWAL_DETAILS,
    value,
});

export const getViewRenewalDetailsSuccessAction = (value: IViewRenewalDetails) => ({
    type: GET_VIEW_RENEWAL_DETAILS_SUCCESS,
    value,
});

export const RenewalsReducerInit: IRenewalsReducer = {
    renewals: [],
    viewRenewalDetails: undefined,
};

export const renewalsReducer = (state = RenewalsReducerInit, action: AnyAction) => {
    switch (action.type) {
        case GET_RENEWALS_LIST_SUCCESS:
            return {
                ...state,
                renewals: action.value,
            };
        case GET_VIEW_RENEWAL_DETAILS_SUCCESS:
            return {
                ...state,
                viewRenewalDetails: action.value,
            };
        default:
            return state;
    }
};

export default renewalsReducer;
