import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from './styles';

export interface ICustomizedAccordian {
    title: string;
    children?: string | React.ReactNode;
    panel: string;
    defaultOpen?: string;
    role?: string
}
const CustomizedAccordions = (props: ICustomizedAccordian) => {
    const { title, children, panel, defaultOpen, role } = props;
    const { classes } = useStyles();
    const [expanded, setExpanded] = React.useState<string | false>(defaultOpen || '');
    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion
                className={classes.accordian}
                expanded={expanded === panel}
                role={role && `${role}-accordian`}
                onChange={handleChange(panel)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordianSummary}
                    role={role && `${role}-accordianSummary`}
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordianDetails} role={role && `${role}-accordianDetails`}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
export default CustomizedAccordions;
