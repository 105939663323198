import axios from '../utils/interceptor';
import { handleError } from '../utils/handle-error';
import { baseUrl } from '../constants/apiConstant';
import { mockPromise } from '../utils/mock-promise';
import { claimsResponse } from '../mocks/claims-mock';
import { IGetClaimsRequest } from '../interface/claims/getClaimsRequest';

export const getClaimsListAPI = async (value: IGetClaimsRequest): Promise<any> => {
    if (!(window as any).isMockData) {
        try {
            const response = await axios.post(`${baseUrl}/ClaimsLossruns/data`, value);
            return response;
        } catch (error) {
            handleError(error);
        }
    } else {
        return mockPromise(claimsResponse);
    }
};
