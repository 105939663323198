import { IViewPolicy, IViewPolicyDetails } from '../interface/policies/viewPolicy';

export const viewPolicyResponse: IViewPolicy = {
    totalSize: 1,
    done: true,
    records: [
        {
            id: 'a0W0R000005aAchUAE',
            vrnA__Account__c: '0010R00001O6IqhQAF',
            vrnA__First_Named_Insured__c: 'EVO 4.0 Commission Test',
            endorsements__c: 'Subjectivity Condition Clause;Caveman Special Amendatory Clause',
            coverages__c: 'Cyber Crime;Media Liability',
            limit__c: 1000000.0,
            deductible__c: 2500.0,
            vrnA__Initial_Premium_Amount__c: 2000.0,
            bound_Total_Payable__c: 9785.0,
            cyber_Crime_Sublimit__c: 250000,
            reputational_Harm_Sublimit__c: 'Harm Sublimit',
            vrnA__Account__r: {
                industry__r: {
                    name: 'RL0114',
                    industryName: 'Retail - Other',
                    combo__c: 'industry - combo'
                },
                industry__c: 'a3Y6g000001opL7EAI',
                billingAddress: {
                    city: 'albany',
                    country: 'United States',
                    countryCode: 'US',
                    postalCode: '94706',
                    state: 'California',
                    stateCode: 'CA',
                    street: '1104 santa fe ave'
                },
                website: 'www.evo.com',
                name: 'EVO 4.0 Commission Test'
            },
            business_Activities__c: 'Activity',
            vrnA__Account_Manager__c: '0056g000004GtwjAAC',
            vrnA__Account_Manager__r: {
                name: 'Shannon Riggio-Kerley',
                email: 'shannon@evolvemga.com'
            },
            vrnA__Broker__r: {
                name: 'Nevada Insurance Agency LLC',
                broker_Code__c: 'BRK-187195'
            },
            gross_Annual_Revenue__c: 1000000.0,
            vrnA__Effective_Date__c: '2023-01-20',
            vrnA__Expiration_Date__c: '2024-01-20',
            vrnA__Policy_Number__c: '45hghgdwe6',
            vrnA__Status__c: 'Renewal',
            vrnA__Broker__c: 'value',
            referral_Status__c: null,
            policy_Status: 'Active',
            policy_Color: 'Green',
            industry_Combo__c: 'Retail, Leisure and Hospitality-Retail-Other-',
            total_Fees__c: 300.0,
            vrnA__Surplus_Lines_Tax__c: 6900.0,
            flat_Rate__c: 10.0,
            vrnA__State_Municipality_Tax__c: 0.0,
            vrnA__Broker_Commission_Percent__c: 0.0,
            surcharge_Amount__c: 0.0,
            option_1_Quote_Reference__c: '28127',
            municipality_Tax_Rate__c: 25,
            stamping_Fee_Rate__c: 25.0,
            surcharge_Rate__c: 25,
            surplus_Lines_Tax_Rate__c: 300.0,
            sL_TaxRate: null,
            binding_Payable_1__c: '9785.0',
            binding_Payable_2__c: '0.0',
            binding_Payable_3__c: '0.0',
            binding_Payable_4__c: '0.0',
            discount_Quoted_Premium_1__c: 2000.0,
            discount_Quoted_Premium_2__c: 0.0,
            discount_Quoted_Premium_3__c: 0.0,
            discount_Quoted_Premium_4__c: 0.0,
            control_questions__r: {
                id: '',
                edR_Fully_Deployed__c: 'Yes',
                offline_Backups__c: 'Yes',
                anti_virus_Software_and_Firewalls__c: 'Yes',
                daily_Backups_Critical_Data__c: 'Yes',
                incident_Greater_than_10k__c: 'Yes',
                legal_Action__c: 'Yes',
                regulatory_Action__c: 'Yes',
                cannabis_or_Crypto__c: 'Yes',
                penetration_Test_Last_24_mo__c: 'Yes',
                aerospace_or_Defense_Ind__c: 'Yes',
                grantmaking_or_giving_foundation__c: 'Yes',
                provide_Technology_Services__c: 'Yes',
                dual_Authorization_Change__c: 'Yes',
                dual_Authorization_Request__c: 'Yes',
                eoL_or_EOS_Software__c: 'Yes',
                mfA_on_Email_Enabled__c: 'Yes',
                annual_Phishing_Training__c: 'Yes',
                email_Filtering__c: 'Yes',
                network_Monitoring__c: 'Yes',
                mfA_for_Privileged_Accounts__c: 'Yes',
                update_Critical_Patches__c: 'Yes',
                mfA_on_Remote_Network_Enabled__c: 'Yes',
            },
            additional_fields__r: {
                id: '',
                estimated_Number_of_PII__c: 100,
            },
        }
    ],
    errorMessage: null
};

export const viewDataResponse: IViewPolicyDetails =  {
    id: 'a0W0R000005aAchUAE',
    vrnA__Account__c: '0010R00001O6IqhQAF',
    vrnA__First_Named_Insured__c: 'EVO 4.0 Commission Test',
    endorsements__c: 'Subjectivity Condition Clause;Caveman Special Amendatory Clause',
    coverages__c: 'Cyber Crime;Media Liability',
    limit__c: 1000000.0,
    deductible__c: 2500.0,
    vrnA__Initial_Premium_Amount__c: 2000.0,
    bound_Total_Payable__c: 9785.0,
    cyber_Crime_Sublimit__c: 250000,
    reputational_Harm_Sublimit__c: 'Harm Sublimit',
    vrnA__Account__r: {
        industry__r: {
            name: 'RL0114',
            industryName: 'Retail - Other',
            combo__c: 'industry - combo'
        },
        industry__c: 'a3Y6g000001opL7EAI',
        billingAddress: {
            city: 'albany',
            country: 'United States',
            countryCode: 'US',
            postalCode: '94706',
            state: 'California',
            stateCode: 'CA',
            street: '1104 santa fe ave'
        },
        website: 'www.evo.com',
        name: 'EVO 4.0 Commission Test'
    },
    business_Activities__c: 'Activity',
    vrnA__Account_Manager__c: '0056g000004GtwjAAC',
    vrnA__Account_Manager__r: {
        name: 'Shannon Riggio-Kerley',
        email: 'shannon@evolvemga.com'
    },
    vrnA__Broker__r: {
        name: 'Nevada Insurance Agency LLC',
        broker_Code__c: 'BRK-187195'
    },
    gross_Annual_Revenue__c: 1000000.0,
    vrnA__Effective_Date__c: '2023-01-20',
    vrnA__Expiration_Date__c: '2024-01-20',
    vrnA__Policy_Number__c: '45hghgdwe6',
    vrnA__Status__c: 'Renewal',
    vrnA__Broker__c: 'value',
    referral_Status__c: null,
    policy_Status: 'Active',
    policy_Color: 'Green',
    industry_Combo__c: 'Retail, Leisure and Hospitality-Retail-Other-',
    total_Fees__c: 300.0,
    vrnA__Surplus_Lines_Tax__c: 6900.0,
    flat_Rate__c: 10.0,
    vrnA__State_Municipality_Tax__c: 0.0,
    vrnA__Broker_Commission_Percent__c: 0.0,
    surcharge_Amount__c: 0.0,
    option_1_Quote_Reference__c: '28127',
    municipality_Tax_Rate__c: 25,
    stamping_Fee_Rate__c: 25.0,
    surcharge_Rate__c: 25,
    surplus_Lines_Tax_Rate__c: 300.0,
    sL_TaxRate: null,
    binding_Payable_1__c: '9785.0',
    binding_Payable_2__c: '0.0',
    binding_Payable_3__c: '0.0',
    binding_Payable_4__c: '0.0',
    discount_Quoted_Premium_1__c: 2000.0,
    discount_Quoted_Premium_2__c: 0.0,
    discount_Quoted_Premium_3__c: 0.0,
    discount_Quoted_Premium_4__c: 0.0,
    control_questions__r: {
        id: '',
        edR_Fully_Deployed__c: 'Yes',
        offline_Backups__c: 'Yes',
        anti_virus_Software_and_Firewalls__c: 'Yes',
        daily_Backups_Critical_Data__c: 'Yes',
        incident_Greater_than_10k__c: 'Yes',
        legal_Action__c: 'Yes',
        regulatory_Action__c: 'Yes',
        cannabis_or_Crypto__c: 'Yes',
        penetration_Test_Last_24_mo__c: 'Yes',
        aerospace_or_Defense_Ind__c: 'Yes',
        grantmaking_or_giving_foundation__c: 'Yes',
        provide_Technology_Services__c: 'Yes',
        dual_Authorization_Change__c: 'Yes',
        dual_Authorization_Request__c: 'Yes',
        eoL_or_EOS_Software__c: 'Yes',
        mfA_on_Email_Enabled__c: 'Yes',
        annual_Phishing_Training__c: 'Yes',
        email_Filtering__c: 'Yes',
        network_Monitoring__c: 'Yes',
        mfA_for_Privileged_Accounts__c: 'Yes',
        update_Critical_Patches__c: 'Yes',
        mfA_on_Remote_Network_Enabled__c: 'Yes',
    },
    additional_fields__r: {
        id: '',
        estimated_Number_of_PII__c: 100,
    },
};