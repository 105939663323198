import { AnyAction } from 'redux';
import { IGetClaimsRequest } from '../interface/claims/getClaimsRequest';
import { IGetClaimsResponse } from '../interface/claims/getClaimsResponse';

export interface IClaimsReducer {
    claims: IGetClaimsResponse | undefined;
}

export const GET_CLAIMS_LIST = 'GET_CLAIMS_LIST';
export const GET_CLAIMS_LIST_SUCCESS = 'GET_CLAIMS_LIST_SUCCESS';

export const getClaimsAction = (value: IGetClaimsRequest) => ({
    type: GET_CLAIMS_LIST,
    value,
});
export const getClaimsSuccessAction = (value: IGetClaimsResponse) => ({
    type: GET_CLAIMS_LIST_SUCCESS,
    value,
});

export const ClaimsReducerInit: IClaimsReducer = {
    claims: undefined,
};

export const claimsReducer = (state = ClaimsReducerInit, action: AnyAction) => {
    switch (action.type) {
        case GET_CLAIMS_LIST_SUCCESS:
            return {
                ...state,
                claims: action.value,
            };
        default:
            return state;
    }
};

export default claimsReducer;
