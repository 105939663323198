import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Grid } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { styles } from './styles';

interface ITabList {
    id?: number;
    label: string;
    component?: any;
    path?: string;
    exact?: boolean;
    disabled?: boolean;
    hideTab?: boolean;
}

interface ITabProps {
    list: Array<ITabList>;
    tabClassName?: string;
    gridClassName?: string;
    contentClassName?: string;
    stickyClass?: string;
    indicatorColor?: 'secondary' | 'primary';
    variant?: 'scrollable' | 'fullWidth' | 'standard';
    active?: number;
    role?: string;
}

const CustomTabs = (props: ITabProps) => {
    const { list, tabClassName, gridClassName, role, contentClassName, indicatorColor, variant, active } = props;
    const { pathname } = useLocation();
    const { classes } = styles();
    const initialValue = list.find((item) => item.path === pathname)?.id || 0;
    const [value, setValue] = React.useState<number | undefined>(initialValue);
    const [selectedValue, setSelectedValue] = useState<ITabList | undefined>(list.find((item) => item.id === value));
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    useEffect(() => {
        if (active === 0 || active) {
            setValue(active);
            const selected = list.find((item) => item.id === active);
            setSelectedValue(selected);
        }
    }, [active]);
    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setValue(newValue);
        const selected = list.find((item) => item.id === newValue);
        setSelectedValue(selected);
    };
    return (
        <>
            <Grid container className={props.stickyClass ? props.stickyClass : ''}>
                <Grid item xs={12} className={gridClassName} role={role}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor={indicatorColor}
                        textColor="primary"
                        aria-label="EVOLVE-Tabs"
                        variant={variant}
                        scrollButtons="auto"
                        className={tabClassName}
                    >
                        {list.map((item) =>
                            item.path && (item.id === 0 || item.id) ? (
                                <Link
                                    key={item.id}
                                    className={value === item?.id ? classes.linkSelected : classes.link}
                                    to={item.path}
                                    onClick={() => item.id && setValue(item.id)}
                                >
                                    <Tab classes={{ root: classes.tab }} label={item.label} disabled={item.disabled} />
                                </Link>
                            ) : (
                                item.id !== undefined && (
                                    <Tab
                                        label={item.label}
                                        key={item.id}
                                        id={`evolve-${item.id}`}
                                        disabled={item.disabled}
                                        classes={{ root: classes.tab }}
                                    />
                                )
                            )
                        )}
                    </Tabs>
                </Grid>
            </Grid>
            {selectedValue && !selectedValue.path && (
                <Grid item xs={12} className={contentClassName}>
                    {selectedValue.component}
                </Grid>
            )}
        </>
    );
};
export default CustomTabs;
