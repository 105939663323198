/* eslint-disable max-len */
export const securityRequirements = [
    {
        label: "Is multi-factor authentication (MFA) required for all remote access to the Insured's network?",
        valueLabel: 'MFA_on_Remote_Network_Enabled',
    },
    {
        label: 'Is multi-factor authentication (MFA) enabled for remote access to all company email accounts?',
        valueLabel: 'MFA_on_Email_Enabled',
    },
    {
        label: 'Is multi-factor authentication (MFA) used to protect all privileged user accounts (e.g. IT admin accounts)?',
        valueLabel: 'MFA_for_Privileged_Accounts',
    },
    {
        label: 'Does the Insured have daily offline back-ups that are fully disconnected and inaccessible from the organization’s live environment or cloud back-ups secured by MFA?',
        valueLabel: 'Offline_backups',
    },
    {
        label: 'Does the Insured have an endpoint detection response (EDR) solution deployed across all endpoints?',
        valueLabel: 'EDR_Fully_Deployed',
    },
    {
        label: 'Does the Insured have a network monitoring solution to alert the organization to any suspicious activity or malicious behavior on the network?',
        valueLabel: 'Network_Monitoring',
    },
    {
        label: 'Does the Insured provide phishing training and simulated attacks for all employees?',
        valueLabel: 'Annual_Phishing_Training',
    },
    {
        label: 'Does the Insured have email filtering software to scan and filter all inbound and outbound messages for spam and malicious content?',
        valueLabel: 'Email_Filtering',
    },
    {
        label: 'Does the Insured regularly update computer systems and carry out critical patches related to zero-day vulnerabilities as soon as they are released by the vendor?',
        valueLabel: 'Update_Critical_Patches',
    },
    {
        label: 'Does the Insured have up-to-date anti-virus software and firewalls across all devices?',
        valueLabel: 'Anti_virus_Software_and_Firewalls',
    },
    {
        label: 'Does the Insured take daily backups of all critical data?',
        valueLabel: 'Daily_Backups_Critical_Data',
    },
    {
        label: 'Has the Insured experienced a cyber event in the past three years that has resulted in a direct financial loss of more than $10,000?',
        valueLabel: 'Incident_Greater_than_10k',
    },
    {
        label: 'Has the Insured had any legal action brought or threatened against them in the last five years as a direct result of a cyber event?',
        valueLabel: 'Legal_Action',
    },
    {
        label: 'Has the Insured had a regulatory action initiated against them in the last five years as a direct result of a cyber event?',
        valueLabel: 'Regulatory_Action',
    },
    {
        label: 'Is the Insured involved in the direct supply of goods or services to the cannabis industry, or  are they involved directly in the use or supply of cryptocurrency?',
        valueLabel: 'Cannabis_or_Crypto',
    },
    {
        label: 'Has the Insured conducted a penetration test of their network architecture within the past 24 months?',
        valueLabel: 'Penetration_Test_Last_24_mo',
    },
    {
        label: 'Does the Insured manufacture products for the aerospace or defense industries?',
        valueLabel: 'Aerospace_or_Defense_Ind',
    },
    {
        label: 'Are you a grantmaking organization or giving foundation?',
        valueLabel: 'Grantmaking_or_giving_foundation',
    },
    {
        label: 'Does the Insured provide any Technology services (e.g. tech consulting, information technology services, software development, app development, electronics recycling, etc.)?',
        valueLabel: 'Provide_Technology_Services',
    },
    {
        label: 'Before any change is made to a third party’s account details, does the Insured obtain authorization from the third party via an authentication method which is different to the original method used to request the change?',
        valueLabel: 'Dual_Authorization_Change',
    },
    {
        label: 'Before the Insured transfers funds to an account that they haven’t paid into before, does the Insured obtain authorization from the recipient of the funds via an authentication method which is different to the original method used to request the transfer?',
        valueLabel: 'Dual_Authorization_Request',
    },
    {
        label: 'Please provide the approximate number of unique individuals that you collect, store and/or process personally identifiable information from, whether on your own systems or with third parties',
        valueLabel: 'Estimated_Number_of_PII',
        tooltipContent:
            'Definition of "Record" - A record is any first and/or last name paired with one or more of the following: personally identifiable information (DOB, SS#, etc.), financial information, and/or medical information. Note, we are looking for unique records, so multiple pieces of information linking back to one person is considered one record.',
    },
    {
        label: 'Does the Insured have any end of life or end of support software?',
        valueLabel: 'EOL_or_EOS_Software',
    },
];

export const radiovalues = [
    {
        id: 'Yes',
        name: 'Yes',
    },
    {
        id: 'No',
        name: 'No',
    },
    {
        id: 'Unknown',
        name: 'Unknown',
    },
];
