import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    accordianDetails: {
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    },
    accordianSummary: {
        borderBottom: '1px solid #A9ACAF !important',
        '& .css-yw020d-MuiAccordionSummary-expandIconWrapper': {
            color: theme.palette.primary.main,
        },
    },
    accordian: {
        boxShadow: 'none !important',
        backgroundColor: '#ffffff',
        fontSize: '14px',
        '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root': {
            boxShadow: 'none !important',
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none !important',
        },
    },
}));
