import React from 'react';
import MaterialTable from 'material-table';
import { IRenewals } from '../../interface/renewals/renewalsTable';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { formatDate } from '../../utils/dateFormat';
import moment from 'moment';
import { currencyFormat } from '../../utils/currencyFormat';
interface IRenewalsTable {
    renewals: IRenewals[];
    tableRef: any;
    viewRenewals: (id?: string, limit?: string) => void;
    viewClaim: (id?: string, limit?: string, policyNumber?: string) => void;
}
const RenewalsTable = (props: IRenewalsTable) => {
    const { tableRef, renewals, viewRenewals ,viewClaim} = props;
    const { classes } = useStyles();
    const selectStatusBackgroundColor = (status?: string) => {
        switch (status) {
            case 'UPCOMING RENEWAL':
                return classes.upcomingDiv;
            case 'EXPIRING RENEWAL':
                return classes.expiringSoonDiv;
            case 'POLICY EXPIRED':
                return classes.policyExpiredDiv;
        }
    };
    const tableColumns = [
        {
            field: 'vrnA__Account__r.name',
            title: 'INSURED BUSINESS NAME',
            width: '40%',
            cellStyle: { padding: '16px 0px', maxWidth: '315px' },
        },
        {
            field: 'quote_Deductible',
            title: 'DEDUCTIBLE',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IRenewals) => (
                <>
                    {row?.quote_Deductible &&
                        currencyFormat(row?.quote_Deductible)}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IRenewals) => {
                return (
                    String(rowData?.quote_Deductible)
                        .replace(/,$/g, '')
                        .indexOf(String(term).replace(/[,$]/g, '')) != -1
                );
            },
        },
        {
            field: 'quote_limit',
            title: 'LIMIT',
            width: '10%',
            cellStyle: { padding: '0px 12px 0px 0px' },
            render: (row: IRenewals) => (
                <>
                    {row?.quote_limit &&
                        currencyFormat(Number(row?.quote_limit))}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IRenewals) => {
                return (
                    String(rowData?.quote_limit).replace(/,$/g, '').indexOf(String(term).replace(/[,$]/g, '')) != -1
                );
            },
        },
        {
            field: 'quote_Discount_Premium',
            title: 'PREMIUM',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IRenewals) => (
                <>
                    {row?.quote_Discount_Premium != null
                        ? currencyFormat(row?.quote_Discount_Premium)
                        : currencyFormat(row?.quoted_Base_1__c)}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IRenewals) => {
                return (
                    String(
                        rowData?.quote_Discount_Premium != null
                            ? rowData.quote_Discount_Premium
                            : rowData.quoted_Base_1__c
                    )
                        .replace(/[,$]/g, '')
                        .indexOf(String(term).replace(/[,$]/g, '').split('.')[0]) != -1
                );
            },
        },
        {
            field: 'date_Quoted__c',
            title: 'DATE QUOTED',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.date_Quoted__c) - formatDate(a.date_Quoted__c),
            render: (row: IRenewals) => (
                <>
                    {row.date_Quoted__c && moment(row.date_Quoted__c).format('MM/DD/yyyy')}
                </>
            ),
        },
        {
            field: 'vrnA__Effective_Date__c',
            title: 'EFFECTIVE DATE',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.vrnA__Effective_Date__c) - formatDate(a.vrnA__Effective_Date__c),
            render: (row: IRenewals) => (
                <>
                    {row.vrnA__Effective_Date__c && moment(row.vrnA__Effective_Date__c).format('MM/DD/yyyy')}
                </>
            ),
        },
        {
            field: 'vrnA__Expiration_Date__c',
            title: 'EXPIRATION DATE',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.vrnA__Expiration_Date__c) - formatDate(a.vrnA__Expiration_Date__c),
            render: (row: IRenewals) => (
                <>
                    {row.vrnA__Expiration_Date__c && moment(row.vrnA__Expiration_Date__c).format('MM/DD/yyyy')}
                </>
            ),
        },
        {
            field: 'renewal_Status',
            title: 'STATUS',
            cellStyle: { padding: '16px 0px' },
            width: '10%',
            render: (row: IRenewals) => (
                <>
                    {row?.renewal_Status && (
                        <Grid item xs={12}>
                            <input
                                className={`${classes.tableStatusDiv} ${selectStatusBackgroundColor(
                                    row?.renewal_Status?.toUpperCase()
                                )}`}
                                value={row?.renewal_Status}
                                disabled
                            />
                        </Grid>
                    )}
                </>
            ),
        },
        {
            field: 'actions',
            title: 'ACTIONS',
            cellStyle: { padding: '10px 8px' },
            filtering: false,
            sorting: false,
            render: (row: IRenewals) => (
                <Grid container xs={12} className={classes.actionsDiv}>
                        <Grid
                            item
                            xs={5}
                            style={{marginLeft:'2px',cursor: 'pointer',fontSize: '12px' }}
                            onClick={() => {
                                viewRenewals(row?.id, row?.quote_limit_type || '');
                            }}
                            role='viewRenewalFromRenewals'
                        >
                            Renewal Detail
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            style={{ cursor: 'pointer', fontSize: '12px'  }}
                            onClick={() => {
                                viewClaim(row?.id, row?.quote_limit_type || '', row.vrnA__Policy_Number__c);
                            }}
                            //role={`renewal-view-${row?.id}`}
                            role='viewClaimsFromRenewals'
                        >
                            Claims History
                        </Grid>
                </Grid>
            ),
        },
    ];
    return (
        <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={renewals}
            localization={{
                body: {
                    filterRow: {},
                },
                pagination: {
                    labelRowsPerPage: '',
                    labelDisplayedRows: '{from}-{to} of {count}'
                }
            }}
            options={{
                search: true,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                headerStyle: {
                    backgroundColor: '#FFFFFF',
                    fontWeight: 600,
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    padding: '16px 0px',
                },
                rowStyle: {
                    color: '#4C5159',
                    fontSize: '14px'
                },
                overflowY: 'auto',
                thirdSortClick: false,
                showTitle: false,
                sorting: true,
                minBodyHeight: '350px',
            }}
            style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', overflowX: 'auto' }}
        />
    );
};

export default RenewalsTable;
