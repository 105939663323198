import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    gridContainer: {
        padding: '15px 0px 0px 0px',
    },
    quotesContainer: {
        backgroundColor: '#FFFFFF',
        marginTop: '4px',
    },
    tabContainer: {
        padding: '0px 79px',
    },
    insuredHeading: {
        marginBottom: '20px !important',
        marginTop: '10px !important',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    coverageHeading: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    securityHeading: {
        fontSize: '16px',
        fontWeight: 'bold',
        paddingBottom: '30px',
        paddingTop: '15px',
        borderBottom: 'groove 2px',
    },
    securityHeadingView: {
        fontSize: '16px',
        fontWeight: 'bold',
        paddingBottom: '30px',
    },
    title: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#4C5159',
    },
    headerName: {
        paddingLeft: '84px',
        fontSize: '23px',
        fontWeight: 'bold',
    },
    tableContainer: {
        background: theme.palette.background.default,
        padding: '5px 79px',
        minHeight: 'calc(100vh - 323px)',
    },
    buttonContainer: {
        marginTop: '5px',
    },
    tabs: {
        '& .MuiTab-root': {
            width: '100px !important',
            minWidth: '100px !important',
            fontFamily: 'Nunito Sans',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '14px',
            textTransform: 'none',
            lineHeight: '19px',
        },
        '&.MuiTabs-indicator': {
            backgroundColor: 'red !important',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#0394BD !important',
        },
    },
    activeDiv: {
        background: 'rgba(4, 174, 1, 0.2)',
        border: '1px solid #00A707',
        color: '#04AE01',
    },
    inactiveDiv: {
        background: 'rgba(202, 125, 9, 0.2)',
        border: '1px solid #CA7D09',
        color: '#CA7D09',
    },
    declinedDiv: {
        background: 'rgba(215, 34, 45, 0.2)',
        border: '1px solid #D7222D',
        color: '#D7222D',
    },
    statusDiv: {
        height: '22px',
        boxSizing: 'border-box',
        borderRadius: '100px',
        textAlign: 'center',
        width: '80px',
    },
    actionsDiv: {
        color: '#0394BD',
    },
    button: {
        '&:hover': {
            backgroundColor: '#0394BD',
        },
        textTransform: 'none',
        backgroundColor: '#0394BD !important',
        marginLeft: '25%',
    },
    imageDiv: {
        marginTop:'-13px',
        marginRight:'3px',
        cursor: 'pointer',
        '@media (min-width:1345px)': {
            marginTop: '-2px !important',
        },
    },
    container: {
        position: 'relative',
        height: '88px',
        borderBottom: '1px solid #EAEAEA',
        paddingLeft: '84px',
        paddingRight: '84px',
        paddingBottom: '20px',
        paddingTop: '20px',
        backgroundColor: theme.palette.background.default,
    },
    errorMessage: {
        color: '#ff0000',
        fontSize: '14px',
        marginTop: '32px',
        marginLeft: '11%',
    },
    errorMessage1: {
        color: '#ff0000',
        fontSize: '14px',
    },
    errorMessageLimit: {
        color: '#ff0000',
        fontSize: '14px',
        marginTop: '7px',
        marginLeft: '4%',
    },
    insuredBusinessName: {
        margin: '0px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px 0px 0px 2px',
        borderColor: '#B1B1B1',
        padding: '10px 5px 10px 10px',
        fontSize: '15px',
    },
    inputField1: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px 0px 0px 2px',
        borderColor: '#B1B1B1',
        padding: '10px 5px 10px 10px',
        fontSize: '15px',
    },
    inputField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        padding: '10px 5px 10px 10px',
        fontSize: '15px',
    },
    selectField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: '0px',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        padding: '10px 5px 10px 0px',
        fontSize: '14px',
        '&.Mui-disabled': {
            backgroundColor: '#F6F6F6',
            color: '#D1D1D1 !important'
        },
        '&.MuiInputBase-readOnly': {
            backgroundColor: '#F6F6F6'
        },
    },
    agentCode: {
        width: '60%',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '5px',
        padding: '10px 0px 10px 0px',
        fontSize: '14px',
        borderColor: 'black'
    },
    backToQuotesButton: {
        color: '#0394BD',
        textTransform: 'none',
        fontWeight: 'bold',
        paddingTop: '0px',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    draftButtonDiv: {
        borderColor: 'black',
        background: '#B2B6BC66',
        padding: '10px',
        borderWidth: 'thin',
        border: 'solid 1px',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
        fontSize: '14px',
    },
    mainGrid: {
        paddingTop: '15px',
        backgroundColor: theme.palette.background.default,
    },
    grid1: {
        marginTop: '15px',
    },
    agencyGrid: {
        width: '78%',
        marginLeft: '11%',
        padding: '39px',
        height: '291px',
        marginTop: '6px',
        borderRadius: '8px',
        backgroundColor: '#F7F8FB',
    },
    datePicker: {
        padding: '4px 26px 10px 12px !important',
        height: '40px !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        width: '100% !important',
        color: theme.palette.text.primary,
        backgroundColor: 'white',
        '& .MuiOutlinedInput-root': {
            height: '40px',
            fontSize: '13px',
            borderRadius: '2px',
            marginLeft: '-12px',
            marginTop: '-4px',
            marginRight: '-26px'
        },
        '& .MuiOutlinedInput-input': {
            height: '0.4375rem !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: '0px',
        },
        '& .MuiPickersToolbar-toolbar': {
            backgroundColor: 'red',
        },
        '& .MuiPickersDay-daySelected': {
            backgroundColor: 'yellow',
        },
        '& .Mui-disabled ': {
            backgroundColor: '#f6f6f6',
        },
    },
    cancelButton: {
        color: '#4C5159',
        textTransform: 'none',
        borderColor: '#4C5159',
        fontWeight: 'bold',
        marginLeft: '40px',
        marginTop: '20px',
        borderWidth: '2px',
    },
    searchButton: {
        color: '#0394BD',
        textTransform: 'none',
        borderColor: '#0394BD',
        marginTop: '41px',
        height: '40px',
        fontWeight: 'bold',
        borderWidth: '2px',
        width: '100%',
        borderRadius: '0px 4px 4px 0px',
    },
    submitButton: {
        color: 'white',
        textTransform: 'none',
        borderColor: '#0394BD',
        marginTop: '20px',
        marginLeft: '100px',
        height: '40px',
        backgroundColor: '#0394BD',
        width: '74px',
        '&:hover': {
            backgroundColor: '#0394BD',
            boxShadow: 'none',
        },
    },
    submitContainer: {
        boxShadow: '0px 0px 15px #80808080',
        borderColor: '#B1B1B1',
        borderWidth: 'thin',
        width: '100%',
        display: 'flex',
        height: '80px',
    },
    limits: {
        color: '#4C5159 !important',
        '& .MuiFormLabel-asterisk': {
            color: theme.palette.error.main,
        },
        '&.MuiFormLabel-root': {
            fontWeight: 'bold !important',
        },
        fontSize: '14px !important',
        fontFamily: 'Nunito Sans !important',
    },
    insuredContainer: {
        padding: '39px',
        paddingLeft: '208px',
        paddingRight: '208px',
    },
    insuredContainer1: {
        paddingTop: '0px',
        paddingLeft: '200px',
        paddingRight: '200px',
    },
    backToQuotesDiv: {
        marginTop: '0px',
    },
    radio: {
        '&.MuiRadio-colorPrimary.Mui-checked': {
            color: '#0394BD',
        },
    },
    contents: {
        '& h6': {
            fontSize: 14,
            fontWeight: 600,
            paddingTop: 8,
            paddingBottom: 5,
        },
    },
    editView: {
        marginTop: '4px !important',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width:1345px)': {
            marginTop: '1px !important',
        },
    },
    viewActiveDiv: {
        padding: '10px',
        borderWidth: 'thin',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
        background: 'rgba(4, 174, 1, 0.2)',
        border: '1px solid #00A707',
        color: '#04AE01',
        marginLeft: '10px !important',
        fontSize: '14px !important'
    },
    viewInactiveDiv: {
        padding: '10px',
        borderWidth: 'thin',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
        background: 'rgba(202, 125, 9, 0.2)',
        border: '1px solid #CA7D09',
        color: '#CA7D09',
        marginLeft: '10px !important',
    },
    viewPendingDiv: {
        padding: '10px',
        borderWidth: 'thin',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
        border: '1px solid',
        marginLeft: '10px !important',
    },
    downloadIcon: {
        height: '15px',
        paddingRight: '3px',
        marginTop: '3px',
    },
    downloadText: {
        marginTop: '-23px',
        marginLeft: '21px',
    },
    appetiteGuide: {
        paddingTop: '7px',
        paddingLeft: '7px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    appetiteQuoteGuide: {
        paddingTop: '12px',
        paddingLeft: '42px',
        fontWeight: 'bold',
        fontSize: '14px',
        '@media (min-width:1400px)': {
            paddingLeft: '55px',
        },
    },
    tooltip: {
        height: '1px',
        marginLeft: '-9px',
        '&.MuiIconButton-root:hover': {
            backgroundColor: 'transparent',
            height: '0em',
        },
        '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
            padding: '0px',
        },
    },
    tooltipIcon: {
        height: '18px !important',
    },
    editText: {
        marginTop: '-21px',
        marginLeft: '18px',
    },
    viewDeclinedDiv: {
        padding: '10px',
        borderWidth: 'thin',
        textAlign: 'center',
        borderRadius: '28px',
        lineHeight: '4px',
        height: '27px',
        flexBasis: '7%',
        background: 'rgba(215, 34, 45, 0.2)',
        border: '1px solid #D7222D',
        color: '#D7222D',
        marginLeft: '10px !important',
    },
    textAreaField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        fontSize: '15px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
        },
    },
    referralStatus: {
        marginLeft: '20px',
        marginTop: '2px',
        textAlign: 'center',
        fontSize: '14px'
    },
    errorMessageSubmit: {
        color: '#ff0000',
        fontSize: '14px',
        marginTop: '21px',
        marginBottom: '30px',
        marginLeft: '213px',
    },
    editSection: {
        textDecoration: 'none',
        marginLeft: '-3px !important',
        marginRight: '-60px !important',
        marginTop: '2px',
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#0394BD',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    modal: {
        backgroundColor: theme.palette.background.default,
        padding: '24px',
        fontSize: '14px'
    },
    modalHeader: {
        paddingTop: '10px',
        textAlign: 'center',
    },
    nextButton: {
        color: 'white',
        textTransform: 'none',
        borderColor: '#0394BD',
        height: '40px',
        backgroundColor: '#0394BD',
        width: '74px',
        '&:hover': {
            backgroundColor: '#0394BD',
            boxShadow: 'none',
        },
        float: 'right',
    },
    logoContainer: {
        height: '32px',
        marginTop: '25px',
        '@media (max-width:900px)': {
            width: '100%',
        },
    },
    noBrokerErrorCont: {
        width: '100%',
        height: '434px',
        marginTop: '23px',
    },
    formContainer: {
        paddingLeft: '150px',
        paddingRight: '150px',
    },
    documentItem: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: '10px !important',
        marginRight: '10px !important',
        borderRadius: '10px',
        padding: '10px'
    },
    documentLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none !important'
    },
    viewContentGrid: {
        backgroundColor: theme.palette.background.default,
        minHeight: 'calc(100vh - 321px)',
        paddingBottom: '20px',
    },
    documentContainer: {
        paddingLeft: '20px !important',
        paddingTop: '35px !important',
    },
    securityContainer: {
        margin: '10px 30px',
        borderBottom: 'groove 1px',
        fontSize: '14px',
        '& .MuiGrid-item': {
            paddingLeft: '10px',
            paddingTop: '10px',
            paddingBottom: '10px'
        }
    },
    securityContainerView: {
        marginTop: '10px !important',
        borderBottom: 'groove 1px',
    },
    securityMainContainer: {
        paddingLeft: '80px',
    },
    viewIconDiv: {
        width: '15px',
        marginTop: '-10px',
        cursor: 'pointer',
        '@media (min-width:1345px)': {
            marginTop: '0px !important',
        },
    },
    requestBindDiv:{
        cursor:'pointer'
    },
    statusTab: {
        color: '#878c95'
    },
    textSize: {
        fontSize: '14px'
    }
}));
