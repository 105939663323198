import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { IQuotes } from '../../interface/quotes/quotesTable';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import { Edit, viewIcon } from '../../assets/icons';
import moment from 'moment';
import { formatDate } from '../../utils/dateFormat';
import { IViewQuoteById } from '../../interface/quotes/viewQuote';
import { currencyFormat } from '../../utils/currencyFormat';

interface IQuotesTable {
    quotes: IQuotes[];
    tableRef: any;
    viewQuote: (
        data: boolean,
        id?: string,
        quoteLimitType?: string,
        status?: string,
        quoteLimit?: string | number,
        vrnaAccount?: string
    ) => void;
    helpEmail: (data: boolean) => void;
    getQuoteDetails: (data: IViewQuoteById) => void;
}

const QuotesTable = (props: IQuotesTable) => {
    const { quotes, tableRef, viewQuote, helpEmail, getQuoteDetails } = props;
    const [quotesData, setQuotesData] = useState<IQuotes[]>([]);
    const { classes } = useStyles();
    useEffect(() => {
        const data = quotes.map((quote) => {
            quote.premium = quote?.quote_Discount_Premium;
            quote.date_Quoted__c = quote?.date_Quoted__c && moment(quote?.date_Quoted__c).format('MM/DD/yyyy');
            quote.accountName = quote?.vrnA__Account__r?.name;
            return quote;
        });
        data.sort(function (a: any, b: any) {
                const date2: any = new Date(a.date_Quoted__c);
                return (new Date(b.date_Quoted__c) as any) - date2;
        });
        setQuotesData(data);
    }, [quotes]);
    const selectStatusBackgroundColor = (status?: string) => {
        switch (status) {
            case 'ACTIVE':
                return classes.activeDiv;
            case 'INACTIVE':
                return classes.inactiveDiv;
            case 'DECLINED':
                return classes.declinedDiv;
        }
    };
    const tableColumns = [
        {
            field: 'accountName',
            title: 'INSURED BUSINESS NAME',
            width: '40%',
            cellStyle: { padding: '16px 0px', maxWidth: '315px' },
        },
        {
            field: 'quote_Deductible',
            title: 'DEDUCTIBLE',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IQuotes) => (
                <>
                    {row?.quote_Deductible &&
                        currencyFormat(row?.quote_Deductible,0)
                    }
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IQuotes) => {
                return (
                    String(rowData?.quote_Deductible).replace(/,$/g, '').indexOf(String(term).replace(/[,$]/g, '')) !=
                    -1
                );
            },
        },
        {
            field: 'quote_limit',
            title: 'LIMIT',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IQuotes) => (
                <>
                    {row?.quote_limit &&
                        currencyFormat(Number(row?.quote_limit),0)
                    }
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IQuotes) => {
                return String(rowData?.quote_limit).replace(/,$/g, '').indexOf(String(term).replace(/[,$]/g, '')) != -1;
            },
        },
        {
            field: 'premium',
            title: 'PREMIUM',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IQuotes) => (
                <>
                    {row?.premium &&
                        currencyFormat(row?.premium)
                    }
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IQuotes) => {
                return (
                    String(rowData?.premium)
                        .replace(/[,$]/g, '')
                        .indexOf(String(term).replace(/[,$]/g, '').split('.')[0]) != -1
                );
            },
        },
        {
            field: 'date_Quoted__c',
            title: 'DATE QUOTED',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.date_Quoted__c) - formatDate(a.date_Quoted__c),
        },
        {
            field: 'quote_Status',
            title: 'STATUS',
            cellStyle: { padding: '16px 0px' },
            width: '10%',
            render: (row: IQuotes) => (
                <>
                    {row?.quote_Status && (
                        <Grid item xs={12}>
                            <input
                                className={`${classes.statusDiv} ${selectStatusBackgroundColor(
                                    row?.quote_Status?.toUpperCase()
                                )}`}
                                value={row?.quote_Status}
                                disabled
                            />
                        </Grid>
                    )}
                </>
            ),
        },
        {
            field: 'referral_Status',
            title: 'REFERRAL STATUS',
            width: '10%',
            cellStyle: { padding: '16px 0px' },
            render: (row: IQuotes) => <>{row?.referral_Status && row?.referral_Status}</>,
        },
        {
            field: 'actions',
            title: 'ACTIONS',
            filtering: false,
            cellStyle: { padding: '16px 0px' },
            width: '10%',
            sorting: false,
            render: (row: IQuotes) => (
                <Grid container xs={12} className={classes.actionsDiv}>
                    <Grid container xs={4} lg={5}>
                        <Grid className={classes.requestBindDiv} onClick={() => {
                            getQuoteDetails({
                                id: row?.id || '',
                                limit_type: row?.quote_limit_type || 'EVO_Limit_1__c'
                            });
                            helpEmail(true);
                        }} role='request-bind'>
                            Request Bind
                        </Grid>
                    </Grid>
                    <Grid container xs={4} lg={3}>
                        <img src={Edit} className={classes.imageDiv} />
                        <Grid
                            role='editFromQuotePage'
                            onClick={() => {
                                viewQuote(false, row?.id, row?.quote_limit_type);
                            }}
                            className={classes.editView}
                        >
                            Edit
                        </Grid>
                    </Grid>
                    <Grid container xs={4}>
                        <img src={viewIcon} className={classes.viewIconDiv} />
                        <Grid
                            item
                            style={{ marginLeft: '2px' }}
                            onClick={() => {
                                viewQuote(true, row?.id, row?.quote_limit_type);
                            }}
                            className={classes.editView}
                            role='viewFromQuotes'
                        >
                            View
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
    ];

    return (
        <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={quotesData}
            localization={{
                body: {
                    filterRow: {},
                },
                pagination: {
                    labelRowsPerPage: '',
                    labelDisplayedRows: '{from}-{to} of {count}'
                }
            }}
            options={{
                search: true,
                pageSize: 5,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                headerStyle: {
                    backgroundColor: '#FFFFFF',
                    fontWeight: 600,
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    padding: '16px 0px',
                },
                rowStyle: {
                    color: '#4C5159',
                    fontSize:'14px'
                },
                overflowY: 'auto',
                thirdSortClick: false,
                showTitle: false,
                sorting: true,
                minBodyHeight: '350px',
            }}
            style={{ backgroundColor: '#FFFFFF', boxShadow: 'none' }}
        />
    );
};

export default QuotesTable;
