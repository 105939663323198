import { IQuotes } from '../interface/quotes/quotesTable';

export const quotesResponse: IQuotes[] = [
    {
        vrnA__Account__r: {
            name: 'Jacob Jones',
        },
        quote_Deductible: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Active',
        id: 'gfhgf878',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Bessie Cooper',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'InActive',
        id: 'gff878',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Jerome Bell',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Declined',
        id: 'gfhgf8',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Ralph Edwards',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Active',
        id: 'iuityy',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Robert Fox',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Active',
        id: 'pljhj7',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Kristin Watson',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'InActive',
        id: 'gfh',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Kathryn Murphy',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Declined',
        id: 'asg',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Dianne Russell',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'InActive',
        id: '000ygf',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Floyd Miles',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Active',
        id: '123jh',
        referral_Status: '',
    },
    {
        vrnA__Account__r: {
            name: 'Albert Flores',
        },
        option_1_Deductible__c: 710,
        quote_limit: 601,
        date_Quoted__c: '5/27/15',
        quote_Status: 'Declined',
        id: '898nbh',
        referral_Status: '',
    },
];
