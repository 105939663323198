import React, { Dispatch, ReactElement, useEffect, useRef, useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useStyles } from './styles';
import PoliciesTable from './policies-table';
import { getFilesAction } from '../../modules/quotes';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IReduxState } from '../../modules/store';
import { IBrokerDetails, IBrokerRecords } from '../../interface/quotes/getBrokerDetails';
import { IPolicies } from '../../interface/policies/policiesTable';
import { getPoliciesAction, getViewPolicyDetailsAction } from '../../modules/policies';
import ViewPolicyForm from './view-policy';
import ViewClaims from '../claims/view-claims';
import { getClaimsAction } from '../../modules/claims';
import { IViewPolicyDetails } from '../../interface/policies/viewPolicy';
import { IGetClaimsRequest } from '../../interface/claims/getClaimsRequest';
import { IGetClaimsResponse } from '../../interface/claims/getClaimsResponse';

import { policyList } from '../../constants/tabLists';
interface IPoliciesProps {
    getPolicies: (data: string[]) => void;
    policies: IPolicies[];
    brokerDetails?: IBrokerDetails;
    getViewPolicyDetails: (data: string) => void;
    getFiles: (data: string) => void;
    claimsData?: IGetClaimsResponse;
    getClaims: (data: IGetClaimsRequest) => void;
    viewPolicyDetails: IViewPolicyDetails
}
export interface IViewData {
    status?: string;
    color?: string;
}

const Policies = (props: IPoliciesProps) => {
    type pageStatusEnum = 'viewEdit' | 'homePage' | 'viewClaim';
    const {
        getPolicies,
        policies,
        brokerDetails,
        getViewPolicyDetails,
        getFiles,
        getClaims,
        claimsData,
        viewPolicyDetails
    } = props;
    const { classes } = useStyles();
    const [currentPage, setCurrentPage] = useState<pageStatusEnum>('homePage');
    const [tabValue, setTabValue] = React.useState('all');
    const [policiesData, setPoliciesData] = useState<IPolicies[]>([]);
    const [status, setStatus] = React.useState<IViewData>({ color: '', status: '' });
    const [searchvalue, setSearchValue] = React.useState<string>();
    const tableRef = useRef(null);
    const handleChange = (event: React.ChangeEvent<any>, newValue: string) => {
        setTabValue(newValue);
        if (tableRef) {
            (tableRef.current as any)?.dataManager.changeCurrentPage(0);
        }
    };

    useEffect(() => {
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getPolicies(data);
        }
    }, [brokerDetails]);

    const backToQuotesClick = () => {
        setCurrentPage('homePage');
    };

    useEffect(() => {
        if (tabValue === 'all') {
            setPoliciesData(policies);
        } else {
            const data = policies?.filter((policy) => policy?.policy_Status?.toUpperCase() === tabValue.toUpperCase());
            setPoliciesData(data);
        }
    }, [policies, tabValue]);
    const viewPolicyForm = (id?: any, status?: string, color?: string) => {
        const formData = {
            status: status,
            color: color,
        };
        setStatus(formData);
        getViewPolicyDetails(id);
        getFiles(id);
        setCurrentPage('viewEdit');
    };
    const viewClaim = (id?: any, searchString?: string) => {
        setSearchValue(searchString);
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getClaims({
                LossRunRequired: false,
                Brokercode: data
            });
        }
        getViewPolicyDetails(id);
        setCurrentPage('viewClaim');
    };
    const renderComponent = (): ReactElement => {
        let component: ReactElement = <></>;
        switch (currentPage) {
            case 'homePage':
                component = (
                    <>
                        <Grid container xs={12} className={classes.gridContainer}>
                            <Grid item xs={10} role="header" className={classes.headerName}>
                                My Policies
                            </Grid>
                        </Grid>
                        <Grid item md={8} xs={7} className={classes.tabContainer}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                aria-label="Policy tabs"
                                indicatorColor="primary"
                                className={classes.tabs}
                                role="policyTabs"
                            >
                                {policyList.map((item) => (
                                    <Tab
                                        key={item.id}
                                        value={item.value}
                                        label={item.label}
                                        style={{ color: tabValue === item.value ? '#0394BD' : '#4C5159' }}
                                        role={`tab-${item.value}`}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                        <Grid xs={12} className={classes.tableContainer}>
                            <PoliciesTable policies={policiesData} tableRef={tableRef} viewPolicy={viewPolicyForm}
                            viewClaim={viewClaim}/>
                        </Grid>
                    </>
                );
                break;
            case 'viewEdit':
                component = <ViewPolicyForm
                    backToQuotesClick={backToQuotesClick}
                    viewClaim={viewClaim}
                    view={status}
                />;
                break;
            case 'viewClaim':
                component = <ViewClaims
                    backToQuotesClick={backToQuotesClick}
                    tableRef={tableRef}
                    claimsData={claimsData}
                    policy
                    viewDetails={{
                        broker: {
                            code: viewPolicyDetails?.vrnA__Broker__r?.broker_Code__c ||
                            viewPolicyDetails?.vrnA__Broker__c || '',
                            name: viewPolicyDetails?.vrnA__Broker__r?.name || ''
                        },
                        insuredName: viewPolicyDetails?.vrnA__First_Named_Insured__c || '',
                        accountManager: {
                            name: viewPolicyDetails?.vrnA__Account_Manager__r?.email || '',
                            email: viewPolicyDetails?.vrnA__Account_Manager__r?.name || ''
                        }
                    }}
                    defaultSearch={searchvalue || ''}
                />;
        }
        return component;
    };

    return renderComponent();
};
const mapStateToProps = (state: IReduxState) => ({
    policies: state.policy.policies,
    brokerDetails: state.quote.brokerDetails,
    claimsData: state.claims.claims,
    viewPolicyDetails: state.policy.viewPolicyDetails
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getPolicies: (data: string[]) => dispatch(getPoliciesAction(data)),
    getViewPolicyDetails: (data: string) => dispatch(getViewPolicyDetailsAction(data)),
    getFiles: (data: string) => dispatch(getFilesAction(data)),
    getClaims: (data: IGetClaimsRequest) => dispatch(getClaimsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
