import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { IPolicies } from '../../interface/policies/policiesTable';
import {formatDate} from '../../utils/dateFormat';
import { useStyles } from './styles';
import { Grid } from '@mui/material';
import moment from 'moment';
import { currencyFormat } from '../../utils/currencyFormat';

interface IPoliciesTable {
    policies: IPolicies[];
    tableRef: any;
    viewPolicy: (id?: string, status?: string, color?: string) => void;
    viewClaim: (id?: string, policyNumber?: string) => void;
}
const PoliciesTable = (props: IPoliciesTable) => {
    const { policies, tableRef, viewPolicy, viewClaim } = props;
    const [policiesData, setPoliciesData] = useState<IPolicies[]>([]);
    const { classes } = useStyles();

    useEffect(() => {
        const data = policies?.map((policy) => {
            policy.vrnA__Effective_Date__c =
                policy?.vrnA__Effective_Date__c && moment(policy?.vrnA__Effective_Date__c).format('MM/DD/yyyy');
            policy.vrnA__Expiration_Date__c =
                policy?.vrnA__Expiration_Date__c && moment(policy?.vrnA__Expiration_Date__c).format('MM/DD/yyyy');
            policy.accountName = policy?.vrnA__Account__r?.name;
            return policy;
        });
        setPoliciesData(data);
    }, [policies]);

    const selectStatusBackgroundColor = (color?: string) => {
        switch (color) {
            case 'RED':
                return classes.activeDiv;
            case 'GREEN':
                return classes.activeGreenDiv;
            case 'PURPLE':
                return classes.expiredDiv;
        }
    };
    const tableColumns = [
        {
            field: 'accountName',
            title: 'INSURED BUSINESS NAME',
            width: '100%',
            cellStyle: { padding: '10px 0px', maxWidth: '315px' }
        },
        {
            field: 'deductible__c',
            title: 'DEDUCTIBLE BOUND',
            width: '100%',
            cellStyle: { padding: '10px 0px'},
            render: (row: IPolicies) => (
            <>
                {row?.deductible__c && currencyFormat(row?.deductible__c,0)}
            </>
            ),
            customFilterAndSearch: (term: any, rowData: IPolicies) => {
                return (
                    String(rowData?.deductible__c).replace(/,$/g, '').indexOf(String(term).replace(/[,$]/g, '')) != -1
                );
            },
        },
        {
            field: 'limit__c',
            title: 'LIMIT BOUND',
            cellStyle: { padding: '16px 0px' },
            render: (row: IPolicies) => (
                <>
                    {row?.limit__c && currencyFormat(row?.limit__c,0)}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IPolicies) => {
                return String(rowData?.limit__c).replace(/,$/g, '').indexOf(String(term).replace(/[,$]/g, '')) != -1;
            },
        },
        {
            field: 'vrnA__Initial_Premium_Amount__c',
            title: 'PREMIUM BOUND',
            cellStyle: { padding: '16px 0px' },
            render: (row: IPolicies) => (
                <>
                    {row?.vrnA__Initial_Premium_Amount__c && currencyFormat(row?.vrnA__Initial_Premium_Amount__c)}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IPolicies) => {
                return (
                    String(rowData?.vrnA__Initial_Premium_Amount__c)
                        .replace(/,$/g, '')
                        .indexOf(String(term).replace(/[,$]/g, '').split('.')[0]) != -1
                );
            },
        },
        {
            field: 'bound_Total_Payable__c',
            title: 'TOTAL PAYABLE',
            cellStyle: { padding: '16px 0px' },
            render: (row: IPolicies) => (
                <>
                    {row?.bound_Total_Payable__c && currencyFormat(row?.bound_Total_Payable__c)}
                </>
            ),
            customFilterAndSearch: (term: any, rowData: IPolicies) => {
                return (
                    String(rowData?.bound_Total_Payable__c)
                        .replace(/,$/g, '')
                        .indexOf(String(term).replace(/[,$]/g, '').split('.')[0]) != -1
                );
            },
        },
        {
            field: 'vrnA__Effective_Date__c',
            title: 'EFFECTIVE DATE',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.vrnA__Effective_Date__c) - formatDate(a.vrnA__Effective_Date__c),
        },
        {
            field: 'vrnA__Expiration_Date__c',
            title: 'EXPIRATION DATE',
            cellStyle: { padding: '16px 0px' },
            customSort: (a: any,b:any) =>
                formatDate(b.vrnA__Expiration_Date__c) - formatDate(a.vrnA__Expiration_Date__c),
        },
        {
            field: 'vrnA__Policy_Number__c',
            title: 'POLICY NUMBER',
            cellStyle: { padding: '5px 0px' },
        },
        {
            field: 'policy_Status',
            title: 'STATUS',
            cellStyle: { padding: '16px 0px' },
            render: (row: IPolicies) => (
                <>
                    {row?.policy_Status && (
                        <input
                            className={`${classes.statusDiv} ${selectStatusBackgroundColor(
                                row?.policy_Color?.toUpperCase()
                            )}`}
                            value={row?.policy_Status}
                            disabled
                        />
                    )}
                </>
            ),
        },
        {
            field: 'actions',
            title: 'ACTIONS',
            cellStyle: { padding: '10px 8px' },
            filtering: false,
            sorting: false,
            render: (row: IPolicies) => (
                <Grid container xs={12} className={classes.actionsDiv}>
                    <Grid
                        item
                        xs={6}
                        style={{  cursor: 'pointer', fontSize: '12px' }}
                        onClick={() => {
                            viewPolicy(row?.id, row?.policy_Status, row?.policy_Color);
                        }}
                        role='viewPolicyFromPolicies'
                    >
                        Policy Detail
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        style={{ cursor: 'pointer', fontSize: '12px'  }}
                        onClick={() => {
                            viewClaim(row?.id, row.vrnA__Policy_Number__c);
                        }}
                        role='viewClaimsFromPolicies'
                    >
                        Claims History
                    </Grid>
                </Grid>
            ),
        },
    ];
    return (
        <MaterialTable
            tableRef={tableRef}
            columns={tableColumns}
            data={policiesData}
            localization={{
                body: {
                    filterRow: {},
                },
                pagination: {
                    labelRowsPerPage: '',
                    labelDisplayedRows: '{from}-{to} of {count}'
                }
            }}
            options={{
                search: true,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                headerStyle: {
                    backgroundColor: '#FFFFFF',
                    fontWeight: 600,
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    fontSize: '12px',
                    padding: '16px 0px',
                },
                rowStyle: {
                    color: '#4C5159',
                    fontSize: '14px'
                },
                overflowY: 'auto',
                thirdSortClick: false,
                showTitle: false,
                sorting: true,
                minBodyHeight: '350px',
            }}
            style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', overflowX: 'auto' }}
        />
    );
};

export default PoliciesTable;
