import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { useStyles } from './styles';

export default function CircularIndeterminate() {
    const { classes } = useStyles();
    return (
        <div className={classes.root}>
            <Backdrop className={classes.backdrop} open>
                <CircularProgress size={60} />
            </Backdrop>
        </div>
    );
}
