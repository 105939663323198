import { AnyAction } from 'redux';
import { ICreateQuoteRequest } from '../interface/quotes/createQuote';
import { IBrokerDetails } from '../interface/quotes/getBrokerDetails';
import { IIndustryActivitiesData } from '../interface/quotes/industryActivities';
import { IQuotes } from '../interface/quotes/quotesTable';
import { ISearchInsured, ISearchInsuredResponse } from '../interface/quotes/searchInsured';
import { IStates } from '../interface/quotes/states';
import { IUpdateQuoteRequest } from '../interface/quotes/updateQuotes';
import { IViewQuote, IViewQuoteById } from '../interface/quotes/viewQuote';

export interface ISuccessDetails {
    id?: string;
    referralStatus?: string;
}
export interface IQuoteReducer {
    onCreateQuoteSuccess: boolean;
    insuredDetails?: ISearchInsuredResponse;
    insuredModalPopUp: boolean;
    quotes: IQuotes[];
    brokerDetails?: IBrokerDetails;
    industryActivities?: IIndustryActivitiesData;
    states?: IStates;
    viewQuoteDetails?: any;
    updateId?: string;
    pendingAPICount: number;
    files?: any;
    sendEmailModal?: boolean;
    sucessDetails: ISuccessDetails;
    //noInsuredFoundModalStatus:boolean
}
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const RESET_QUOTE = 'RESET_QUOTE';
export const GET_QUOTES_LIST = 'GET_QUOTES_LIST';
export const GET_QUOTES_LIST_SUCCESS = 'GET_QUOTES_LIST_SUCCESS';
export const SEARCH_INSURED = 'SEARCH_INSURED';
export const SEARCH_INSURED_SUCCESS = 'SEARCH_INSURED_SUCCESS';
export const INSURED_MODAL_STATUS = 'INSURED_MODAL_STATUS';
export const RESET_INSURED = 'RESET_INSURED';
export const GET_BROKER_DETAIL = 'GET_BROKER_DETAIL';
export const GET_BROKER_DETAIL_SUCCESS = 'GET_BROKER_DETAIL_SUCCESS';
export const INDUSTRY_ACTIVITIES = 'INDUSTRY_ACTIVITIES';
export const INDUSTRY_ACTIVITIES_SUCCESS = 'INDUSTRY_ACTIVITIES_SUCCESS';
export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const CREATE_QUOTE_SUCCESS_DETAILS = 'CREATE_QUOTE_SUCCESS_DETAILS';
export const GET_VIEW_QUOTE_DETAILS = 'GET_VIEW_QUOTE_DETAILS';
export const GET_VIEW_QUOTE_DETAILS_SUCCESS = 'GET_VIEW_QUOTE_DETAILS_SUCCESS';
export const CLEAR_VIEW = 'CLEAR_VIEW';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const INSURED_RESET = 'INSURED_RESET';
export const UPDATE_QUOTE_SUCCESS_ID = 'UPDATE_QUOTE_SUCCESS_ID';
export const UPDATE_PENDING_API = 'UPDATE_PENDING_API';
export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_MODAL_STATUS = 'SEND_EMAIL_MODAL_STATUS';
//export const NO_INSURED_MODAL_STATUS = 'NO_INSURED_MODAL_STATUS';

export const updatePendingAPIAction = (value: number) => ({
    type: UPDATE_PENDING_API,
    value,
});

export const insuredResetAction = () => ({
    type: INSURED_RESET,
});
export const updateQuoteAction = (value: IUpdateQuoteRequest) => ({
    type: UPDATE_QUOTE,
    value,
});
export const clearViewAction = () => ({
    type: CLEAR_VIEW,
});
export const getViewQuoteDetailsAction = (value: IViewQuoteById) => ({
    type: GET_VIEW_QUOTE_DETAILS,
    value,
});

export const getViewQuoteDetailsSuccessAction = (value: IViewQuote) => ({
    type: GET_VIEW_QUOTE_DETAILS_SUCCESS,
    value,
});
export const getStatesAction = () => ({
    type: GET_STATES,
});

export const getStatesSuccessAction = (value: IStates) => ({
    type: GET_STATES_SUCCESS,
    value,
});
export const getIndustryActivitiesAction = () => ({
    type: INDUSTRY_ACTIVITIES,
});

export const getIndustryActivitiesSuccessAction = (value: IIndustryActivitiesData) => ({
    type: INDUSTRY_ACTIVITIES_SUCCESS,
    value,
});
export const getBrokerDetailsAction = (value: string) => ({
    type: GET_BROKER_DETAIL,
    value,
});

export const getBrokerDetailsSuccessAction = (value: IBrokerDetails) => ({
    type: GET_BROKER_DETAIL_SUCCESS,
    value,
});
export const createQuoteAction = (value: ICreateQuoteRequest) => ({
    type: CREATE_QUOTE,
    value,
});
export const createQuoteSuccessActionDetails = (value: ISuccessDetails) => ({
    type: CREATE_QUOTE_SUCCESS_DETAILS,
    value,
});
export const updateQuoteSuccessIdAction = (value: string) => ({
    type: UPDATE_QUOTE_SUCCESS_ID,
    value,
});
export const createQuoteSuccessAction = () => ({
    type: CREATE_QUOTE_SUCCESS,
});
export const resetQuoteSuccessAction = () => ({
    type: RESET_QUOTE,
});

export const getQuotesAction = (value: string[]) => ({
    type: GET_QUOTES_LIST,
    value,
});
export const getQuotesSuccessAction = (value: IQuotes) => ({
    type: GET_QUOTES_LIST_SUCCESS,
    value,
});

export const resetInsuredAction = () => ({
    type: RESET_INSURED,
});

export const searchInsuredAction = (value: ISearchInsured) => ({
    type: SEARCH_INSURED,
    value,
});
export const searchInsuredSuccessAction = (value: ISearchInsuredResponse) => ({
    type: SEARCH_INSURED_SUCCESS,
    value,
});
export const insuredModalStatusAction = () => ({
    type: INSURED_MODAL_STATUS,
});
export const getFilesAction = (value: string) => ({
    type: GET_FILES,
    value,
});
export const getFilesActionSuccess = (value: any) => ({
    type: GET_FILES_SUCCESS,
    value,
});
export const sendEmailAction = (value: any) => ({
    type: SEND_EMAIL,
    value,
});
export const sendEmailModalAction = (value: boolean) => ({
    type: SEND_EMAIL_MODAL_STATUS,
    value,
});
// export const noInsuredModalStatusAction = (value:boolean) => ({
//     type: NO_INSURED_MODAL_STATUS,
//     value,
// });

export const QuoteReducerInit: IQuoteReducer = {
    onCreateQuoteSuccess: false,
    insuredDetails: undefined,
    insuredModalPopUp: false,
    quotes: [],
    brokerDetails: undefined,
    industryActivities: undefined,
    states: undefined,
    viewQuoteDetails: undefined,
    updateId: undefined,
    pendingAPICount: 0,
    files: [],
    sendEmailModal: false,
    sucessDetails: { id: undefined, referralStatus: undefined },
    //noInsuredFoundModalStatus: false
};

export const quoteReducer = (state = QuoteReducerInit, action: AnyAction) => {
    switch (action.type) {
        case GET_BROKER_DETAIL_SUCCESS:
            return {
                ...state,
                brokerDetails: {
                    ...action.value,
                },
            };
        case CREATE_QUOTE_SUCCESS:
            return {
                ...state,
                onCreateQuoteSuccess: true,
            };
        case RESET_QUOTE:
            return {
                ...state,
                onCreateQuoteSuccess: false,
            };
        case GET_QUOTES_LIST_SUCCESS:
            return {
                ...state,
                quotes: action.value,
            };
        case RESET_INSURED:
            return {
                ...state,
                insuredModalPopUp: false,
            };
        case SEARCH_INSURED_SUCCESS:
            return {
                ...state,
                insuredDetails: {
                    ...action.value,
                },
            };
        case INSURED_RESET:
            return {
                ...state,
                insuredDetails: undefined,
            };
        case INSURED_MODAL_STATUS:
            return {
                ...state,
                insuredModalPopUp: true,
            };
        case INDUSTRY_ACTIVITIES_SUCCESS:
            return {
                ...state,
                industryActivities: action.value,
            };
        case GET_STATES_SUCCESS:
            return {
                ...state,
                states: action.value,
            };
        case GET_VIEW_QUOTE_DETAILS_SUCCESS:
            return {
                ...state,
                viewQuoteDetails: action.value,
            };
        case CREATE_QUOTE_SUCCESS_DETAILS:
            return {
                ...state,
                sucessDetails: {
                    id: action.value.id,
                    referralStatus: action.value.referralStatus,
                },
            };
        case UPDATE_QUOTE_SUCCESS_ID:
            return {
                ...state,
                updateId: action.value,
            };
        case CLEAR_VIEW:
            return {
                ...state,
                viewQuoteDetails: undefined,
            };
        case UPDATE_PENDING_API:
            return {
                ...state,
                pendingAPICount: state.pendingAPICount + action.value,
            };
        case GET_FILES_SUCCESS:
            return {
                ...state,
                files: action.value,
            };
        case SEND_EMAIL_MODAL_STATUS:
            return {
                ...state,
                sendEmailModal: action.value,
            };
        // case NO_INSURED_MODAL_STATUS:
        //     return{
        //         ...state,
        //     noInsuredFoundModalStatus : action.value
        //     };
        default:
            return state;
    }
};

export default quoteReducer;
