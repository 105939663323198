import React, { Dispatch, ReactElement, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './styles';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { IReduxState } from '../../modules/store';
import { IBrokerDetails, IBrokerRecords } from '../../interface/quotes/getBrokerDetails';
import ClaimsTable from './claims-table';
import { getClaimsAction } from '../../modules/claims';
import { IGetClaimsRequest } from '../../interface/claims/getClaimsRequest';
import { IGetClaimsResponse } from '../../interface/claims/getClaimsResponse';
interface IRenewalsProps {
    getClaims: (data: IGetClaimsRequest) => void;
    brokerDetails?: IBrokerDetails;
    claimsData?: IGetClaimsResponse;
}
const Renewals = (props: IRenewalsProps) => {
    const { brokerDetails, getClaims, claimsData } = props;
    const { classes } = useStyles();

    const tableRef = useRef(null);
    useEffect(() => {
        if (brokerDetails) {
            const data = brokerDetails?.records.map((result: IBrokerRecords) => {
                return result.account.broker_Code__c;
            });
            getClaims({
                LossRunRequired: false,
                Brokercode: data
            });
        }
    }, [brokerDetails]);

    const renderComponent = (): ReactElement => {
        let component: ReactElement = <></>;
                component = (
                    <>
                        <Grid container xs={12} className={classes.gridContainer}>
                            <Grid item xs={10} role="header" className={classes.headerName}>
                                Claims History
                            </Grid>
                        </Grid>
                        <Grid xs={12} className={classes.tableContainer}>
                            <ClaimsTable
                                claims={claimsData?.claims || []}
                                tableRef={tableRef}
                            />
                        </Grid>
                    </>
                );
        return component;
    };

    return renderComponent();
};
const mapStateToProps = (state: IReduxState) => ({
    brokerDetails: state.quote.brokerDetails,
    claimsData: state.claims.claims
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    getClaims: (data: IGetClaimsRequest) => dispatch(getClaimsAction(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Renewals);
