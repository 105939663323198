import React from 'react';
import { Grid, Container } from '@mui/material';
import { Evolve } from '../../assets/icons';
import { useStyles } from './styles';

const NoBrokerRecordsPage = () => {
    const { classes } = useStyles();

    return (
        <div>
            <Container maxWidth="xl">
                <Grid container>
                    <Grid item md={3} xs={3}>
                        <img src={Evolve} className={classes.logoContainer} />
                    </Grid>
                    <Container className={classes.noBrokerErrorCont}>
                        No Broker Code available for this user. Please reach out to Asmith@evolvemga.com
                    </Container>
                </Grid>
            </Container>
        </div>
    );
};
export default NoBrokerRecordsPage;
