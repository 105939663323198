import { useMsal } from '@azure/msal-react';
import { Button, Grid } from '@mui/material';
import { FormikProps, withFormik, Form, FieldArray } from 'formik';
import React, { useEffect } from 'react';
import { Add, close, DisabledAdd, Email } from '../../assets/icons';
import FormComponent from '../../components/form-field/form-field';
import Modal from '../../components/modal/modal';
import { styles } from './styles';
import * as Yup from 'yup';
import { AnyAction, Dispatch } from 'redux';
import { sendEmailAction, sendEmailModalAction } from '../../modules/quotes';
import { connect } from 'react-redux';
import { getUsernameLogo } from '../../utils/getUsername';
import { IReduxState } from '../../modules/store';
import { devToEmail, defaultEmail, devFromEmail } from '../../constants/helpEmail';
import { errorMessages } from '../../constants/errorMessages';

interface IHelpEmailProps {
    emailDetails: {
        brokerName?: string;
        subject?: string;
        toEmail?: {
            name?: string;
            email?: string;
        };
    };
    isTable?: boolean;
    sendEmail: (data: any) => void;
    helpModalStatus?: boolean;
    setHelpModalStatus: (data: boolean) => void;
}

interface IHelpEmailFormValues {
    fromEmail: string;
    toEmail: string;
    ccEmail: string;
    subject: string;
    message: string;
    ccEmailList: string[];
    brokerName?: string;
    thanks?: string;
}

const HelpEmail = (props: IHelpEmailProps & FormikProps<IHelpEmailFormValues>) => {
    const {
        handleChange,
        touched,
        errors,
        values,
        handleSubmit,
        setFieldValue,
        helpModalStatus,
        setHelpModalStatus,
        isTable
    } = props;
    const { classes }: any = styles();
    const { accounts } = useMsal();
    useEffect(() => {
        if (accounts.length > 0 && values.message === '') {
            //eslint-disable-next-line max-len
            setFieldValue('message', `\nThanks,\n ${getUsernameLogo(accounts[0]?.name || '').name}\n${accounts[0]?.username || ''}`);
        }
    }, [accounts, values.message]);
    useEffect(() => {
        if (accounts.length > 0 && values.fromEmail === '') {
            setFieldValue('fromEmail', accounts[0]?.username || 'System User');
        }
    }, [accounts, values.fromEmail]);
    const isButtonDisabled = () =>
        values.ccEmail === '' ? true : values.ccEmail !== '' && errors.ccEmail !== undefined;
    const HelpEmailForm = (
        <Form>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <FormComponent
                        component="input"
                        handleChange={handleChange}
                        label="To"
                        id="toEmail"
                        placeholder="Email"
                        className={classes.inputField}
                        touched={touched.toEmail}
                        errors={errors.toEmail}
                        value={values.toEmail}
                        name="toEmail"
                        disabled
                        isReadOnly
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name="ccEmailList"
                        render={(arrayHelpers) => (
                            <>
                                <FormComponent
                                    component="input"
                                    handleChange={handleChange}
                                    label="CC"
                                    id="ccEmail"
                                    placeholder=""
                                    datatestid="cc-email-input"
                                    className={classes.inputField}
                                    touched={touched.ccEmail}
                                    errors={errors.ccEmail}
                                    value={values.ccEmail}
                                    name="ccEmail"
                                    endAdornment={
                                        <Button
                                            className={classes.addButton}
                                            onClick={() => {
                                                arrayHelpers.push(values.ccEmail);
                                                setFieldValue('ccEmail', '');
                                            }}
                                            role="cc-button"
                                            disabled={isButtonDisabled()}
                                        >
                                            {isButtonDisabled() ? (
                                                <img src={DisabledAdd} className={classes.images} />
                                            ) : (
                                                <img src={Add} className={classes.images} />
                                            )}
                                            Add
                                        </Button>
                                    }
                                />
                                <Grid container spacing={2} className={classes.emailContainer}>
                                    {values.ccEmailList.map((mail, index) => (
                                        <Grid item key={index}>
                                            <Grid container className={classes.emailItem}>
                                                <Grid item role={'cc-email-'+index}>{mail}</Grid>
                                                <Grid item>
                                                    <img
                                                        src={close}
                                                        role={'close-button-'+index}
                                                        className={classes.emailCloseButton}
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormComponent
                        component="input"
                        handleChange={handleChange}
                        label="Subject"
                        id="subject"
                        placeholder="Subject"
                        className={classes.inputField}
                        touched={touched.subject}
                        errors={errors.subject}
                        value={values.subject}
                        name="subject"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormComponent
                        component="input"
                        handleChange={handleChange}
                        label="Broker Name"
                        id="brokerName"
                        placeholder="Broker Name"
                        className={classes.inputField}
                        touched={touched.brokerName}
                        errors={errors.brokerName}
                        value={values.brokerName}
                        name="brokerName"
                        disabled
                        isReadOnly
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormComponent
                        component="textarea"
                        handleChange={handleChange}
                        rows={5}
                        rowsMax={5}
                        label="Message"
                        id="message"
                        placeholder="Message"
                        className={classes.textAreaField}
                        touched={touched.message}
                        errors={errors.message}
                        value={values.message}
                        name="message"
                        datatestid="message-input"
                    />
                </Grid>
            </Grid>
        </Form>
    );
    const footerContent = (
        <Grid container xs={12} spacing={2} justifyContent="flex-end">
            <Grid item>
                <Button
                    className={classes.cancelButton}
                    role="cancel-button"
                    onClick={() => setHelpModalStatus(false)}
                    variant="outlined"
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item>
                <Button
                    className={classes.sendButton}
                    onClick={() => handleSubmit()}
                    role="submit-button"
                    variant="contained"
                    color="primary"
                >
                    Send
                </Button>
            </Grid>
        </Grid>
    );
    return (
        <>
            <Grid
                container
                xs={12}
                onClick={() => setHelpModalStatus(true)}
                spacing={2}
                className={classes.contactContainer}
                role="contact-underwriter"
            >
                {!isTable && (
                    <Grid item xs={3} md={2}>
                        <img src={Email} className={classes.images} />
                    </Grid>
                )}
                <Grid
                    item
                    xs={9}
                    md={10}
                    className={!isTable && classes.contactText}
                >
                    {!isTable && 'Contact Underwriter'}
                </Grid>
            </Grid>
            <Modal
                maxWidth={'sm'}
                title="Contact Underwriter"
                content={HelpEmailForm}
                modalStatus={helpModalStatus || false}
                setModalStatus={setHelpModalStatus}
                removeCloseButton
                footerContent={footerContent}
            />
        </>
    );
};

const HelpEmailForm = withFormik<IHelpEmailProps, IHelpEmailFormValues>({
    mapPropsToValues: (props) => {
        return {
            fromEmail: '',
            toEmail:
                process.env.REACT_APP_ENVIRONMENT === 'Production'
                    ? props.emailDetails?.toEmail?.email
                        ? props.emailDetails.toEmail?.email
                        : defaultEmail
                    : devToEmail,
            ccEmail: '',
            subject: `${props.emailDetails?.subject}` || '',
            message: '',
            ccEmailList: [],
            brokerName: props.emailDetails?.brokerName || '',
        };
    },
    validationSchema: Yup.object({
        ccEmail: Yup.string().email(errorMessages().validEmail),
    }),
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        const emailList = [...values.ccEmailList, values.fromEmail];
        const message = values.message.split('\n').join('<br/>');
        const data = {
            FromEmail: process.env.REACT_APP_ENVIRONMENT === 'Production' ? defaultEmail : devFromEmail,
            ToEmail: values.toEmail,
            CCEmail: emailList?.join(','),
            Subject: values.subject,
            // eslint-disable-next-line max-len
            Body: `<html><head/><body><div>Broker Details: ${values.brokerName}<br/><br/>${message}</div></body></html>`,
            replyToEmail: values.fromEmail,
            Message: '',
        };
        props.sendEmail(data);
    },
})(HelpEmail);

const mapStateToProps = (state: IReduxState) => ({
    helpModalStatus: state.quote.sendEmailModal,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    sendEmail: (data: any) => dispatch(sendEmailAction(data)),
    setHelpModalStatus: (data: boolean) => dispatch(sendEmailModalAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpEmailForm);
