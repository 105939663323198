import { IIndustryActivitiesData } from '../interface/quotes/industryActivities';

export const industryActivitiesResponse: IIndustryActivitiesData = {
    industry: [
        {
            industryName: 'Architects',
            industryCode: 'PS02',
        },
        {
            industryName: 'Accounting',
            industryCode: 'PS08',
        },
        {
            industryName: 'Construction',
            industryCode: 'CN',
        },
        {
            industryName: 'Consulting',
            industryCode: 'PS07',
        },
        {
            industryName: 'Contractors',
            industryCode: 'CN',
        },
        {
            industryName: 'Education',
            industryCode: 'ED',
        },
    ],
};
