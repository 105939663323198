import React from 'react';
import {
    TextField,
    FormLabel,
    InputBase,
    Radio,
    Grid,
    InputBaseComponentProps,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { useStyles } from './styles';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export interface ISelectOption<T> {
    id: T;
    name?: string;
    isDisable?: boolean;
}

export interface IRadioOptions {
    id: string;
    name: string;
    isDisable?: boolean;
}

interface IErrorData {
    errors?: string;
    touched?: boolean;
    id?: string;
}

interface IFormProps {
    autoFocus?: boolean;
    value?: string | number | null;
    placeholder?: string;
    component?: 'input' | 'select' | 'textarea' | 'radio' | 'date';
    id: string;
    className?: string;
    label?: string;
    name: string;
    selectOptions?: ISelectOption<string | number>[];
    radioOptions?: IRadioOptions[];
    required?: boolean;
    disabled?: boolean;
    touched?: boolean;
    errors?: string;
    inputVariant?: 'outlined' | 'filled' | 'standard';
    handleChange?: (event: React.ChangeEvent<any> | SelectChangeEvent<string | number | null>) => void;
    onBlur?: (event: React.FocusEvent<any>) => void;
    focusChange?: (event: React.FocusEvent<any>) => void;
    variant?: 'filled' | 'standard' | 'outlined';
    defaultValue?: number;
    rows?: number;
    rowsMax?: number;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    inputComponent?: React.ElementType<InputBaseComponentProps>;
    type?: string;
    reference?: any;
    datatestid?: string;
    isReadOnly?: boolean;
    direction?: any;
    autoComplete?: string;
    tooltipContent?: string;
    labelContent?: string;
    labelDescription?: string;
    title?: string;
}

const ErrorMessageComponent = (props: IErrorData) => {
    const { classes } = useStyles();
    if (props.touched && props.errors) {
        return (
            <div className={classes.errorMessage} role={`${props.id}-error`}>
                {props.errors}
            </div>
        );
    } else {
        return <div role={`${props.id}-error`}>{''}</div>;
    }
};

const InputField = (props: IFormProps) => {
    const { classes } = useStyles();
    const {
        autoFocus,
        touched,
        errors,
        value,
        placeholder,
        id,
        handleChange,
        onBlur,
        className,
        label,
        required,
        disabled,
        startAdornment,
        endAdornment,
        inputComponent,
        type,
        reference,
        datatestid,
        tooltipContent,
        labelContent,
        labelDescription,
        isReadOnly,
        focusChange,
        title
    } = props;
    return (
        <div>
            {label ? (
                <>
                    <FormLabel required={required} className={classes.labelField} htmlFor={id}>
                        <div className={classes.descriptionDiv}>
                            {label}
                            {labelDescription && <div className={classes.labelDescription}>{labelDescription}</div>}
                        </div>
                        {tooltipContent && (
                            <Tooltip title={tooltipContent} placement="top-start">
                                <IconButton className={classes.tooltip}>
                                    <InfoOutlinedIcon className={classes.tooltipIcon} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </FormLabel>
                    <br />
                    {labelContent && <div className={classes.labelContent}>{labelContent}</div>}
                </>
            ) : (
                <></>
            )}
            <InputBase
                autoFocus={autoFocus}
                className={className}
                id={id}
                disabled={!isReadOnly && disabled}
                readOnly={isReadOnly}
                value={value}
                placeholder={placeholder ? placeholder : ''}
                onChange={handleChange}
                onBlur={onBlur}
                inputProps={{ 'aria-label': 'naked', 'data-testid': datatestid }}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                inputComponent={inputComponent}
                type={type}
                inputRef={reference}
                classes={{ root: classes.field }}
                autoComplete="off"
                title={title}
                onFocus={focusChange}
            />
            <br />
            <ErrorMessageComponent id={id} errors={errors} touched={touched} />
        </div>
    );
};

const TextareaField = (props: IFormProps) => {
    const { classes } = useStyles();
    const {
        rows = 1,
        label,
        touched,
        errors,
        disabled,
        required,
        handleChange,
        onBlur,
        className,
        placeholder,
        value,
        id,
        datatestid,
        isReadOnly,
    } = props;
    return (
        <div>
            <FormLabel required={required} className={classes.labelField} htmlFor={id}>
                {label}
            </FormLabel>
            <br />
            <TextField
                disabled={!isReadOnly && disabled}
                multiline
                rows={rows}
                value={value}
                id={id}
                variant="outlined"
                onChange={handleChange}
                placeholder={placeholder ? placeholder : ''}
                onBlur={onBlur}
                className={className}
                size="small"
                inputProps={{ 'data-testid': datatestid }}
                InputProps={{
                    classes: { root: classes.field },
                    readOnly: isReadOnly
                }}
            />
            <ErrorMessageComponent id={id} errors={errors} touched={touched} />
        </div>
    );
};
const SelectField = (props: IFormProps) => {
    const { classes } = useStyles();
    const {
        touched,
        value,
        errors,
        selectOptions = [],
        placeholder,
        className,
        label,
        name,
        variant,
        required,
        disabled,
        handleChange,
        onBlur,
        defaultValue,
        id,
        datatestid,
        isReadOnly,
        title
    } = props;
    const handleChangeSelect = (event: SelectChangeEvent) => {
        handleChange && handleChange(event);
    };
    return (
        <div>
            {label ? (
                <>
                    <FormLabel required={required} className={classes.labelField} htmlFor={id}>
                        {label}
                    </FormLabel>
                    <br />
                </>
            ) : (
                <></>
            )}
            <Select
                variant={variant}
                disabled={!isReadOnly && disabled}
                readOnly={isReadOnly}
                value={value === '' ? '0' : value as any}
                defaultValue={defaultValue ? defaultValue as any : placeholder}
                onChange={handleChangeSelect}
                name={name}
                onBlur={onBlur}
                className={className ?? classes.selectField}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    classes: { paper: classes.menuProps },
                }}
                id={id}
                inputProps={{ 'data-testid': datatestid, 'aria-disabled': disabled }}
                title={title}
            >
                {placeholder && (
                    <MenuItem key={0} value={0} disabled className={classes.menuItem}>
                        {placeholder}
                    </MenuItem>
                )}
                {selectOptions &&
                    selectOptions.map((item) => (
                        <MenuItem
                            key={item.id}
                            value={item.id}
                            disabled={item.isDisable}
                            title={item.name}
                            className={classes.menuItem}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
            </Select>
            <br />
            <ErrorMessageComponent errors={errors} touched={touched} id={id} />
        </div>
    );
};

const RadioField = (props: IFormProps) => {
    const { classes } = useStyles();
    const { touched, errors, id, className, required, label, handleChange, radioOptions, value, direction, disabled } =
        props;
    return (
        <div>
            {label ? (
                <>
                    <FormLabel required={required} className={classes.labelField} htmlFor={id}>
                        {label}
                    </FormLabel>
                    <br />
                </>
            ) : (
                <></>
            )}
            <Grid container direction={direction ? direction : 'row'}>
                {radioOptions &&
                    radioOptions.map((item) => (
                        <div key={item.id}>
                            <Radio
                                key={item.id}
                                color="primary"
                                id={id}
                                value={item.id}
                                onChange={handleChange}
                                className={className}
                                checked={value == item.id}
                                inputProps={{ role: `radio-${item.id}` }}
                                disabled={disabled ? disabled : item.isDisable}
                            />
                            <FormLabel className={classes.radioField} htmlFor={id}>{item.name}</FormLabel>
                        </div>
                    ))}
            </Grid>
            <br />
            <ErrorMessageComponent errors={errors} touched={touched} />
        </div>
    );
};
const DateField = (props: IFormProps) => {
    const { classes } = useStyles();
    const {
        touched,
        errors,
        id,
        className,
        required,
        label,
        handleChange,
        value = null,
        disabled,
        isReadOnly,
       // datatestid
    } = props;

    return (
        <div>
            <FormLabel required={required} className={classes.labelField} htmlFor={id}>
                {label}
            </FormLabel>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    inputFormat="MM/DD/YYYY"
                    value={value}
                    className={className}
                    onChange={(date: any) => {
                        const event = {
                            target: {
                                value: date.$d,
                                id: id,
                            },
                        };
                        handleChange && handleChange(event as any);
                    }}
                    disabled={!isReadOnly && disabled}
                    readOnly={isReadOnly}
                    renderInput={(params) => <TextField {...params}/>}
                 //   data-testid ={datatestid}
                />
            </LocalizationProvider>
            <br />
            <ErrorMessageComponent errors={errors} touched={touched} id={id} />
        </div>
    );
};
const FormComponent = (props: IFormProps) => {
    const { component, selectOptions = [], radioOptions = [], ...rest } = props;
    switch (component) {
        case 'input':
            return InputField({ ...rest });
        case 'select':
            return SelectField({ selectOptions, ...rest });
        case 'textarea':
            return TextareaField({ ...rest });
        case 'radio':
            return RadioField({ radioOptions, ...rest });
        case 'date':
            return DateField({ ...rest });
        default:
            return null;
    }
};
export default FormComponent;
