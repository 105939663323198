import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: '20px',
    },
    button: {
        color: 'white',
        textTransform: 'none',
        borderColor: '#B1B1B1',
        backgroundColor: '#2492b3',
        height: '33px',
        '&:hover': {
            backgroundColor: '#2492b3',
            boxShadow: 'none',
        },
    },
    dialogActions: {
        margin: '13px',
        padding: theme.spacing(1),
        justifyContent: 'center',
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    warningModalContent: {
        padding: '0px 52px',
        textAlign: 'center',
    },
    warningModalContentInsured: {
        padding: '0px 0px 0px 0px',
        '& .MuiListItem-root': {
            paddingTop: '0px',
        },
    },
    image: {
        marginLeft: '165px',
        height: '41px',
        width: '41px',
    },
    warningModalTitle: {
        padding: theme.spacing(1),
        fontWeight: 'bold',
    },
    content: {
        paddingLeft: '16px',
    },
    shape: {
        borderRadius: theme.spacing(0),
        width: '400px',
        height: '360px',
        padding: '90px 12px 50px 12px',
    },
    shapeInsured: {
        borderRadius: theme.spacing(0),
        width: '400px',
        height: '360px',
        padding: '10px 23px 0px',
    },
    modalShape: {
        borderRadius: theme.spacing(0),
        backgroundColor: theme.palette.background.default,
    },
    modalSpacing: {
        padding: '0 24px',
    },
    errorGrid: {
        color: 'red',
        fontSize: '14px',
        height: '62px',
    },
    listItem: {
        '& .MuiListItem-root': {
            '&:hover': {
                color: '#0394BD',
            },
        },
    },
    titleDiv: {
        paddingTop: '30px',
        paddingBottom: '25px',
    },
    buttonCancel: {
        marginLeft: '140px !important',
        marginBottom: '31px !important',
        width: '71px !important',
        color: 'white',
        textTransform: 'none',
        borderColor: '#B1B1B1',
        backgroundColor: '#2492b3',
        height: '33px',
        '&:hover': {
            backgroundColor: '#2492b3',
            boxShadow: 'none',
        },
    },
    TitleDiv: {
        borderBottom: '3px solid #cacad075',
    },
    FooterDiv: {
        borderTop: '3px solid #cacad075',
    },
    title: {
        textAlign: 'center',
        fontSize: '15px',
    },
}));
