import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()(() => ({
    headingContainer: {
        marginTop: '10px',
        color: '#CA7D09',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        letterSpacing: '0.01071em',
    },
    splitter: {
        backgroundColor: '#CA7D09',
        maxWidth: '2px !important',
        height: '40px',
    },
    outerGrid: {
        paddingLeft: '40px',
    },
    contentGrid: {
        paddingLeft: '20px',
        paddingTop: '9px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        letterSpacing: '0.01071em',
        '@media (min-width:1920px)': {
            paddingTop: '10px',
        },
    },
    alertGrid: {
        width: '100%',
        '& .MuiAlert-message.css-1pxa9xg-MuiAlert-message': {
            width: '100%',
        },
        '& .css-1xsto0d': {
            width: '100%'
        }
    },
}));
