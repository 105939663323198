import React from 'react';
import { Button } from '@mui/material';
import { useStyles } from './styles';

interface IButtonProps {
    key?: string;
    value?: string;
    onClick?: () => void;
    disabled?: boolean;
    content?: string | React.ReactNode;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
    className?: string;
    title?: string;
    role?: string;
    autoFocus?: boolean;
    dataTestId?: string;
}

const ButtonComponent: React.FunctionComponent<IButtonProps> = (props: IButtonProps) => {
    const { onClick, disabled, content, variant, color, title, className, autoFocus, role, dataTestId } = props;
    const { classes } = useStyles();
    return (
        <Button
            classes={{ root: classes.root }}
            onClick={onClick}
            disabled={disabled}
            variant={disabled ? 'outlined' : variant}
            color={color}
            className={className}
            role={role}
            title={title}
            autoFocus={autoFocus}
            data-testid={dataTestId}
        >
            {content}
        </Button>
    );
};

export default ButtonComponent;
