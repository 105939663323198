export const documentList = [
    {
        fileName: 'Document 1',
        fileLink: 'http://something.com',
        createdDate: '24/09/2020',
    },
    {
        fileName: 'Document 2',
        fileLink: 'http://something.com',
        createdDate: '25/09/2020',
    },
    {
        fileName: 'Document 3',
        fileLink: 'http://something.com',
        createdDate: '24/09/2020',
    },
    {
        fileName: 'Document 4',
        fileLink: 'http://something.com',
        createdDate: '24/09/2020',
    },
    {
        fileName: 'Document 5',
        fileLink: 'http://something.com',
        createdDate: '24/09/2020',
    },
    {
        fileName: 'Document 6',
        fileLink: 'http://something.com',
        createdDate: '24/09/2020',
    },
];
