import { call, put } from 'redux-saga/effects';
import { getPoliciesListAPI, viewPolicyAPI } from '../end-points/policies';
import { ISuccessResponse } from '../interface/common';
import {
    getPoliciesAction,
    getPoliciesSuccessAction,
    getViewPolicyDetailsAction,
    getViewPolicyDetailsSuccessAction,
} from '../modules/policies';

export function* getPoliciesSaga(action: ReturnType<typeof getPoliciesAction>) {
    try {
        const quotesResponse: ISuccessResponse<any> = yield call(getPoliciesListAPI, action.value);
        yield put(getPoliciesSuccessAction(quotesResponse.data.compositeResponse[0].body.records));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
export function* viewPolicySaga(action: ReturnType<typeof getViewPolicyDetailsAction>) {
    try {
        const response: ISuccessResponse<any> = yield call(viewPolicyAPI, action.value);
        yield put(getViewPolicyDetailsSuccessAction(response.data.records[0]));
        // eslint-disable-next-line no-empty
    } catch (error) {}
}
