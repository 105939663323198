// import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: any) => ({
        root: {
            textAlign: 'center',
            top: '50%',
            left: '50%',
            position: 'fixed',
            zIndex: 8000,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);
