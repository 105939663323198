import { makeStyles } from 'tss-react/mui';

export const styles = makeStyles()((theme: any) => ({
    contactText: {
        color: theme.palette.primary.main,
        marginTop: '0px',
        fontWeight: 'bold',
        fontSize: '14px !important'
    },
    contactContainer: {
        cursor: 'pointer',
        '@media (min-width: 1025px)': {
            marginLeft: '-16px !important',
            marginTop: '-14px'
        },
    },
    inputField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        border: 'solid',
        height: '40px',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        padding: '10px 0px 10px 10px',
        fontSize: '15px',
    },
    textAreaField: {
        margin: '10px 0px 10px 0px',
        width: '100%',
        borderWidth: 'thin',
        borderRadius: '2px',
        borderColor: '#B1B1B1',
        fontSize: '15px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
        },
        '& .MuiInputBase-input': {
            height: '100px',
        },
    },
    cancelButton: {
        textTransform: 'none',
    },
    sendButton: {
        textTransform: 'none',
    },
    images: {
        width: '28px',
        height: '27px',
        padding: '7px',
        marginTop: '-3px',
    },
    emailCloseButton: {
        width: '20px',
        height: '17px',
        padding: '3px',
        marginTop: '1px',
        cursor: 'pointer',
    },
    addButton: {
        color: theme.palette.primary.main,
        height: '38px',
    },
    emailContainer: {
        backgroundColor: theme.palette.background.paper,
        marginLeft: '0px',
        width: '100%',
        fontSize: '14px',
        marginTop: '0px !important',
    },
    emailItem: {
        backgroundColor: theme.palette.background.default,
        padding: '6px',
        borderRadius: '15px',
        marginBottom: '15px'
    },
}));
